import { useQuery } from '@tanstack/react-query';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useCallback, useContext, useState } from 'react';

import { getReferredUsersBets } from '@/common/api/requests';
import { PaginationMeta, ReferredUserBet } from '@/common/entities';
import { UserStoreContext } from '@/common/stores';
import { getFormattedCurrency } from '@/common/utils';

export type ReferredBetType = ReferredUserBet & {
  key: string;
};

export const usePartnerEventsActivitySection = () => {
  const userStore = useContext(UserStoreContext);
  const referrerId = userStore.user?.referrerUser?.id;
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isPending, isError, isFetching } = useQuery<
    [ReferredUserBet[], PaginationMeta]
  >({
    queryKey: ['referredUsersBets', referrerId, currentPage],
    queryFn: () => getReferredUsersBets(referrerId || '', currentPage),
    enabled: Boolean(referrerId),
    refetchInterval: 30 * 1000,
    refetchIntervalInBackground: false,
    staleTime: 10 * 1000,
  });

  const referredBets = data?.[0] || [];
  const paginationMeta = data?.[1] || {
    page: 1,
    perPage: 10,
    total: 0,
  };

  const bets: ReferredBetType[] = referredBets.map((bet) => ({
    ...bet,
    key: bet.id,
  }));

  const columns: ColumnsType<ReferredBetType> = [
    {
      title: 'Пользователь',
      key: 'user',
      render: (_text: string, record: ReferredBetType) =>
        `${record.user.firstName} ${record.user.lastName}`,
    },
    {
      title: 'Лот',
      key: 'facility',
      render: (_text: string, record: ReferredBetType) => record.facility.name,
      ellipsis: false,
      width: 300,
    },
    {
      title: 'Сумма ставки',
      key: 'amount',
      render: (_text: string, record: ReferredBetType) =>
        getFormattedCurrency(record.amount, true),
    },
    {
      title: 'Время и дата (МСК)',
      key: 'createdAt',
      render: (_text: string, record: ReferredBetType) =>
        `${dayjs(record.createdAt).format('HH:mm')} (${dayjs(record.createdAt).format('DD.MM.YYYY')})`,
    },
  ];

  const handleTableChange = useCallback((pagination: TablePaginationConfig) => {
    if (pagination.current) {
      setCurrentPage(pagination.current);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, []);

  return {
    bets,
    columns,
    loading: isPending,
    isError,
    isFetching,
    paginationMeta,
    handleTableChange,
  };
};
