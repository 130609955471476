import { Facility } from '@/common/entities';

import { mapFacilityResponseToFacility } from '../../mappers';
import { request } from '../../request';
import { FacilityResponse } from '../../responses';

export const getOneFacility = async (facilityId: string): Promise<Facility> => {
  const response = await request.get<{ facility: FacilityResponse }>(
    `/v1/facilities/${facilityId}`,
  );

  return mapFacilityResponseToFacility(response.data.facility);
};
