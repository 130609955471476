import classNames from 'classnames';
import { IMaskInput } from 'react-imask';

import styles from './common-input.module.css';
import { CommonInputProps } from './common-input.types';

export const CommonInput = ({
  type,
  placeholder,
  value,
  className,
  onChange,
  step,
  min,
  mask,
  defaultValue,
  radix,
  mapToRadix,
  thousandsSeparator,
  scale,
  inputMode,
}: CommonInputProps) => {
  if (mask) {
    if (mask === Number) {
      return (
        <IMaskInput
          mask={Number}
          radix={radix}
          mapToRadix={mapToRadix}
          thousandsSeparator={thousandsSeparator}
          scale={scale}
          type={type}
          placeholder={placeholder}
          className={classNames(styles.commonInput, className)}
          value={value?.toString()}
          onAccept={(value: string) => onChange?.(value)}
          step={step}
          min={min}
          inputMode={inputMode}
        />
      );
    } else if (typeof mask === 'string') {
      return (
        <IMaskInput
          mask={mask}
          type={type}
          placeholder={placeholder}
          className={classNames(styles.commonInput, className)}
          value={value?.toString()}
          onAccept={(value: string) => onChange?.(value)}
          step={step}
          min={min}
          defaultValue={defaultValue}
        />
      );
    }
  }

  return (
    <input
      type={type}
      placeholder={placeholder}
      className={classNames(styles.commonInput, className)}
      value={value}
      onChange={(event) => onChange?.(event.target.value)}
      step={step}
      min={min}
      defaultValue={defaultValue}
    />
  );
};
