import { useMemo } from 'react';

import { useApi } from '@/common/api';
import { useMessage } from '@/common/hooks';

import { FacilityStore } from './facility-store';
import { FacilityStoreContext } from './facility-store.context';

export const FacilityStoreInjector = ({
  children,
}: {
  readonly children: React.ReactNode;
}) => {
  const api = useApi();
  const mesasge = useMessage();

  const facilityStore = useMemo(
    () => new FacilityStore(api, mesasge),
    [api, mesasge],
  );

  return (
    <FacilityStoreContext.Provider value={facilityStore}>
      {children}
    </FacilityStoreContext.Provider>
  );
};
