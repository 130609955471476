import { Link, useLocation } from 'react-router-dom';

import { CommonButton } from '@/common/components';
import { paths } from '@/common/constants';

import { ProfileSectionContainer } from '../profile-section-container';
import styles from './partner-program-section.module.css';

export const PartnerProgramSection = () => {
  const location = useLocation();

  return (
    <ProfileSectionContainer>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>Партнерская программа</h2>
        <p className={styles.text}>
          Делитесь с друзьями или клиентами ссылкой на аукцион и если они
          выиграют, вы заработаете
        </p>
        <div className={styles.buttons}>
          <Link
            to={paths.getPatnerProgramPath()}
            state={{ from: location.pathname }}
          >
            <CommonButton className={styles.infoButton}>
              Информация
            </CommonButton>
          </Link>
        </div>
      </div>
    </ProfileSectionContainer>
  );
};
