import { useEffect, useMemo } from 'react';

import { Facility } from '@/common/entities';

export const useFacilityMap = (facility: Facility) => {
  const mapWrapperId = useMemo(() => `map-${facility.id}`, [facility.id]);

  const initMap = async () => {
    if (!facility) {
      return;
    }

    await ymaps.ready;

    const map = new ymaps.Map(mapWrapperId, {
      center: [facility.latitude, facility.longitude],
      zoom: 16,
    });

    const facilityObjectPoint = new ymaps.GeoObject({
      geometry: {
        type: 'Point',
        coordinates: [facility.latitude, facility.longitude],
      },
    });

    map.geoObjects.add(facilityObjectPoint);

    map.controls
      .remove('geolocationControl')
      .remove('searchControl')
      .remove('routeButtonControl')
      .remove('trafficControl')
      .remove('rulerControl');
  };

  useEffect(() => {
    initMap();
  });

  return {
    mapWrapperId,
  };
};
