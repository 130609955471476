import { useMemo } from 'react';

import { useApi } from '@/common/api';
import { useMessage } from '@/common/hooks';

import { UserStore } from './user-store';
import { UserStoreContext } from './user-store.context';

export const UserStoreInjector = ({
  children,
}: {
  readonly children: React.ReactNode;
}) => {
  const api = useApi();
  const message = useMessage();
  const userStore = useMemo(() => new UserStore(api, message), [api, message]);

  return (
    <UserStoreContext.Provider value={userStore}>
      {children}
    </UserStoreContext.Provider>
  );
};
