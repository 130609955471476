export const formatPhoneNumber = (phoneNumber: string) => {
  const cleaned = phoneNumber.replace(/\D/gu, '');

  if (cleaned.length !== 11) {
    return phoneNumber;
  }

  const match = cleaned.match(/^(\d{1,1})(\d{1,3})(\d{1,3})?(\d{1,4})?/u);

  if (match) {
    return `+${[match[1], match[2], match[3], match[4]].filter(Boolean).join(' ')}`;
  }

  return '';
};
