/* eslint-disable camelcase */
import { request } from '../../request';

export const requestSignUpCode = async (
  phoneNumber: string,
  referralCode: string,
) => {
  await request.post('/v1/users/me/sign-up/request-code', {
    phone_number: phoneNumber,
    referral_code: referralCode.toLowerCase(),
  });
};
