import classNames from 'classnames';

import gavel from '@/assets/icons/auction-gavel.svg';
import { CommonButtonLoader } from '@/common/components/common-button';

import styles from './facility-card-button.module.css';
import { FacilityCardButtonProps } from './facility-card-button.types';

export const FacilityCardButton = ({
  children,
  type,
  isLoading = false,
  className,
  onClick,
}: FacilityCardButtonProps) => {
  return (
    <button
      type={type}
      className={classNames(styles.facilityCardButton, className)}
      onClick={onClick}
    >
      <img src={gavel} alt="gavel" />
      <span>{isLoading ? <CommonButtonLoader /> : children}</span>
    </button>
  );
};
