import classNames from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { CommonHeaderLogo } from '@/common/components/common-header/components';
import { paths } from '@/common/constants';

import styles from './landing-header.module.css';

interface LandingHeaderProps {
  readonly isReferralCodeExists: boolean;
  readonly isLoading: boolean;
}

export const LandingHeader: FC<LandingHeaderProps> = ({
  isReferralCodeExists = false,
  isLoading = false,
}) => (
  <header>
    <div className={styles.header}>
      <ul className={styles.list}>
        <li>
          <a href="#steps">Процесс</a>
        </li>
        <li>
          <a href="#faq">Частые вопросы</a>
        </li>
      </ul>
      <div className={styles.logo}>
        <CommonHeaderLogo />
      </div>
      <div className={styles.buttons}>
        <button
          className={classNames(styles.authButton, {
            [styles.authBoxButton]: !isReferralCodeExists && !isLoading,
          })}
        >
          <Link to={paths.getSignInPath()}>Войти</Link>
        </button>
        {isReferralCodeExists ? (
          <button className={styles.authBoxButton}>
            <Link to={paths.getSignUpPath()}>Регистрация</Link>
          </button>
        ) : null}
      </div>
    </div>
  </header>
);
