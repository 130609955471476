import classNames from 'classnames';

import styles from './facility-section-wrapper.module.css';
import { FacilitySectionWrapperProps } from './facility-section-wrapper.types';

export const FacilitySectionWrapper = ({
  children,
  className,
}: FacilitySectionWrapperProps) => {
  return (
    <div className={classNames(styles.facilitySectionWrapper, className)}>
      <div className={styles.facilitySectionWrapperContent}>{children}</div>
    </div>
  );
};
