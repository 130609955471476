import classNames from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { SupportContact, paths } from '@/common/constants';

import styles from './contact-us-section.module.css';

interface ContactUsSectionProps {
  readonly isReferralCodeExists: boolean;
}

export const ContactUsSection: FC<ContactUsSectionProps> = ({
  isReferralCodeExists = false,
}) => (
  <div className={styles.contactUs}>
    <div className={styles.contactUsWrapper}>
      <h2>
        Остались еще вопросы? <br /> Напишите нам!
      </h2>
      <a
        href={SupportContact.Telegram}
        target="_blank"
        rel="noreferrer"
        className={styles.supportCard}
      >
        <button className={styles.sendMessage}>Отправить сообщение</button>
      </a>
    </div>
    <div className={styles.contactUsWrapper}>
      <h3>Посмотреть объекты прямо сейчас</h3>
      <div className={styles.authButtons}>
        <button className={styles.authBoxButton}>
          <Link to={paths.getSignInPath()}>Войти</Link>
        </button>
        {isReferralCodeExists ? (
          <button
            className={classNames(
              styles.authBoxButton,
              styles.authBoxButtonPrimary,
            )}
          >
            <Link to={paths.getSignUpPath()}>Регистрация</Link>
          </button>
        ) : null}
      </div>
    </div>
  </div>
);
