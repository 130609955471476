import { Outlet } from 'react-router-dom';

import { CommonFooter } from '@/common/components';

import { DocumentsHeader } from '../../components';
import styles from './documents-navigation-layout.module.css';

export const DocumentsNavigationLayout = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <DocumentsHeader />
      </div>
      <div className={styles.content}>
        <Outlet />
        <CommonFooter />
      </div>
    </div>
  );
};
