import { CommonButton, CommonIcon, CommonIconType } from '@/common/components';

import { PartnerProgramSectionWrapper } from '../partner-program-section-wrapper';
import styles from './partner-program-description-section.module.css';
import { PartnerProgramDescriptionSectionProps } from './partner-program-description-section.types';

export const PartnerProgramDescriptionSection = ({
  onButtonClick,
  isButtonVisible = true,
}: PartnerProgramDescriptionSectionProps) => {
  return (
    <PartnerProgramSectionWrapper>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>
          Партнерская программа MinoTower для получения вознаграждения
        </h2>
        <div className={styles.questionAnswerSection}>
          <p className={styles.question}>Что нужно будет делать?</p>
          <div className={styles.answerSection}>
            <div className={styles.answer}>
              <p className={styles.answerTitle}>Приглашать</p>
              <p className={styles.answerText}>
                Приглашать друзей или клиентов кому интересно учавствовать в
                аукционе
              </p>
            </div>
            <div className={styles.iconWrapper}>
              <CommonIcon
                type={CommonIconType.UserVoice}
                className={styles.icon}
              />
            </div>
          </div>
          <div className={styles.answerSection}>
            <div className={styles.answer}>
              <p className={styles.answerTitle}>Делиться</p>
              <p className={styles.answerText}>
                Делиться с ними своей реферальной ссылкой для входа
              </p>
            </div>
            <div className={styles.iconWrapper}>
              <CommonIcon
                type={CommonIconType.UserPlus}
                className={styles.icon}
              />
            </div>
          </div>
        </div>
        <div className={styles.questionAnswerSection}>
          <p className={styles.question}>Какое вознаграждение?</p>
          <div className={styles.answerSection}>
            <div className={styles.answer}>
              <p className={styles.answerTitle}>50% от комиссии</p>
              <p className={styles.answerText}>
                Если ваш друг или клиент выиграет лот на аукционе и совершит
                покупку, вы получите 50% от вознаграждения застройщика
              </p>
            </div>
            <div className={styles.iconWrapper}>
              <CommonIcon
                type={CommonIconType.CoinWhite}
                className={styles.icon}
              />
            </div>
          </div>
          <div className={styles.answerSection}>
            <div className={styles.answer}>
              <p className={styles.answerTitle}>Пожизненно</p>
              <p className={styles.answerText}>
                Вы получаете пожизненную возможность получать вознаграждение,
                если друг или клиент купит когда-либо позже
              </p>
            </div>
            <div className={styles.iconWrapper}>
              <CommonIcon
                type={CommonIconType.Infinite}
                className={styles.icon}
              />
            </div>
          </div>
        </div>
        {isButtonVisible ? (
          <CommonButton className={styles.button} onClick={onButtonClick}>
            Стать партнером
          </CommonButton>
        ) : null}
      </div>
    </PartnerProgramSectionWrapper>
  );
};
