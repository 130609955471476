import { User } from '@/common/entities';

export const usePartnerProgramReferredUsersSection = () => {
  const showUserName = (user: User) => {
    if (!user.firstName && !user.lastName) {
      return 'Неизвестный пользователь';
    }

    return `${user.firstName} ${user.lastName}`;
  };

  const showUserRole = (user: User) => {
    if (user.referrerUser) {
      return 'Партнер';
    }

    return 'Участник';
  };

  const showRoleColor = (user: User) => {
    if (user.referrerUser) {
      return 'error';
    }

    return 'success';
  };

  const showCreatedAt = (user: User) => {
    if (!user.createdAt) {
      return '';
    }

    const date = new Date(user.createdAt);
    const year = date.getFullYear();
    const day = String(date.getDate());

    const currentYear = new Date().getFullYear();

    const months = [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря',
    ];

    if (year === currentYear) {
      return `Присоединился ${day} ${months[date.getMonth()]}`;
    }

    return `Присоединился ${day} ${months[date.getMonth()]} ${year}`;
  };

  return {
    showUserName,
    showUserRole,
    showRoleColor,
    showCreatedAt,
  };
};
