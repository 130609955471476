import classNames from 'classnames';
import { memo, useState } from 'react';

import styles from '../round-hero-card.module.css';

interface SchemeImageProps {
  readonly scheme: {
    readonly path?: string;
    readonly area: string;
  };
}

const SchemeImageComponent = ({ scheme }: SchemeImageProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  if (!scheme.path) {
    return (
      <div className={styles.schemeContainer}>
        <div className={styles.scheme}>
          <div className={styles.schemeWrapper}>
            <div className={styles.schemeSkeleton} />
          </div>
        </div>
        <span className={styles.schemeArea}>{scheme.area}</span>
      </div>
    );
  }

  return (
    <div className={styles.schemeContainer}>
      <div className={styles.scheme}>
        <div
          className={classNames(styles.schemeWrapper, {
            [styles.schemeLoaded]: isLoaded,
          })}
        >
          <img
            src={scheme.path}
            loading="lazy"
            alt="Схема объекта"
            onLoad={() => setIsLoaded(true)}
          />
          {!isLoaded && <div className={styles.schemeSkeleton} />}
        </div>
      </div>
      <span className={styles.schemeArea}>{scheme.area}</span>
    </div>
  );
};

SchemeImageComponent.displayName = 'SchemeImage';

export const SchemeImage = memo(SchemeImageComponent);
