import { FacilitiesStoreInjector } from '@/modules/facilities/stores/facilities-store';
import { FacilityStoreInjector } from '@/modules/facilities/stores/facility-store';
import { RoundsStoreInjector } from '@/modules/facilities/stores/rounds-store';

import { UserStoreInjector } from './user-store';

export const GlobalInjector = ({
  children,
}: {
  readonly children: React.ReactNode;
}) => (
  <UserStoreInjector>
    <FacilitiesStoreInjector>
      <FacilityStoreInjector>
        <RoundsStoreInjector>{children}</RoundsStoreInjector>
      </FacilityStoreInjector>
    </FacilitiesStoreInjector>
  </UserStoreInjector>
);
