import { Bet } from '@/common/entities/bet';

export interface BetResponse {
  id: string;
  user_id: string;
  facility_id: string;
  amount: number;
}

export const mapBetResponseToBet = (response: BetResponse): Bet => ({
  id: response.id,
  userId: response.user_id,
  facilityId: response.facility_id,
  amount: response.amount,
});
