import { Link } from 'react-router-dom';

import { paths } from '@/common/constants';

import styles from './documents-header.module.css';

export const DocumentsHeader = () => {
  return (
    <header className={styles.wrapper}>
      <div className={styles.content}>
        <Link to={paths.getLandingPath()} className={styles.logo}>
          MinoTower
        </Link>
      </div>
    </header>
  );
};
