import { Tag } from 'antd';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  CommonButton,
  CommonIcon,
  CommonIconType,
  CommonPagination,
} from '@/common/components';
import { paths } from '@/common/constants';
import { UserStoreContext } from '@/common/stores';
import { formatPhoneNumber } from '@/common/utils';

import { PartnerProgramSectionWrapper } from '../partner-program-section-wrapper';
import { usePartnerProgramReferredUsersSection } from './partner-program-referred-users-section.domain';
import styles from './partner-program-referred-users-section.module.css';
import { PartnerProgramReferredUsersSectionProps } from './partner-program-referred-users-section.types';

export const PartnerProgramReferredUsersSection = ({
  referredUsers,
}: PartnerProgramReferredUsersSectionProps) => {
  const navigate = useNavigate();

  const { showUserName, showUserRole, showRoleColor, showCreatedAt } =
    usePartnerProgramReferredUsersSection();

  const userStore = useContext(UserStoreContext);

  const handlePaginationChange = useCallback(
    async (page: number) => {
      userStore.changeReferredUsersPage(page);
      window.scrollTo(0, 1380);

      const referrerId = userStore.user?.referrerUser?.id;
      if (!referrerId) return;

      await userStore.loadReferredUsers(referrerId, page);
    },
    [userStore],
  );

  const handleReferToLink = () => {
    navigate(paths.getPartnerEventsPath());
  };

  return (
    <PartnerProgramSectionWrapper>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>
          Ваши подключенные пользователи (
          {userStore.referredUsersPaginationMeta?.total ?? 0})
        </h2>
        <div className={styles.divider} />
        <CommonButton className={styles.linkButton} onClick={handleReferToLink}>
          <CommonIcon
            type={CommonIconType.Cabinet}
            className={styles.linkButtonIcon}
          />
          <span className={styles.linkButtonText}>События</span>
        </CommonButton>
        <div className={styles.referredUsersList}>
          {referredUsers.map((referredUser) => (
            <div className={styles.referredUserRow} key={referredUser.id}>
              <div className={styles.referredUser}>
                <div className={styles.referredUserAvatar}>
                  <CommonIcon
                    type={CommonIconType.User}
                    className={styles.referredUserAvatarIcon}
                  />
                </div>
                <div className={styles.referredUserDetails}>
                  <div className={styles.referredUserData}>
                    <p className={styles.referredUserName}>
                      {showUserName(referredUser)}{' '}
                    </p>
                    <Tag
                      bordered={false}
                      color={showRoleColor(referredUser)}
                      className={styles.referredUserRole}
                    >
                      {showUserRole(referredUser)}
                    </Tag>
                  </div>
                  <div className={styles.userDetails}>
                    <div className={styles.referredUserPhoneNumberWrapper}>
                      <CommonIcon
                        type={CommonIconType.Phone}
                        className={styles.referredUserPhoneNumberIcon}
                      />
                      <p className={styles.referredUserPhoneNumber}>
                        {formatPhoneNumber(referredUser.phoneNumber)}
                      </p>
                    </div>
                    <div className={styles.createdAtWrapper}>
                      <div className={styles.dot} />
                      <div className={styles.createdAt}>
                        {showCreatedAt(referredUser)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.paginationWrapper}>
        <CommonPagination
          currentPage={userStore.referredUsersPaginationMeta?.page ?? 1}
          total={userStore.referredUsersPaginationMeta?.total ?? 0}
          perPage={userStore.referredUsersPaginationMeta?.perPage}
          onChange={handlePaginationChange}
        />
      </div>
    </PartnerProgramSectionWrapper>
  );
};
