import choiceIcon from '@/assets/icons/choice.svg';
import gavelIcon from '@/assets/icons/gavel-primary.svg';
import keyIcon from '@/assets/icons/key.svg';
import walletIcon from '@/assets/icons/wallet.svg';
import { CommonButton } from '@/common/components/common-button';
import { CommonModal } from '@/common/components/common-modal';

import styles from './common-header-guide-modal.module.css';
import { CommonHeaderGuideModalProps } from './common-header-guide-modal.types';
import { GuideCard } from './components';

export const CommonHeaderGuideModal = ({
  isOpen,
  onClose,
}: CommonHeaderGuideModalProps) => {
  return (
    <CommonModal
      modalClassName={styles.modalCustom}
      title="Как проходит аукцион?"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={styles.content}>
        <GuideCard
          image={choiceIcon}
          title="Выберите лот"
          content="После регистрации, можно увидеть доступные и предстоящие аукционы, узнать подробнее о лотах"
        />
        <GuideCard
          image={walletIcon}
          title="Внесите возвратный депозит"
          content="Вы поставите ставку, с депозита спишется 1000₽, если вас перебьют, 1000₽ вернется на депозит и можно снова ставить ставку. Депозит можно вывести в любой момент через менеджера"
        />
        <GuideCard
          image={gavelIcon}
          title="Сделайте ставку и выйграйте"
          content="Участник делает ставку, пока не будет предложена более высокая цена. После аукциона, участник с максимально высокой ставкой получит уведомление о выигрыше"
        />
        <GuideCard
          image={keyIcon}
          title="Заберите ключи"
          content="Наша компания сопроводит на каждом этапе покупки объекта. Полное юр-сопровождение и помощь в одобрении ипотеки"
        />
      </div>
      <div className={styles.buttonWrapper}>
        <div className={styles.info}>
          Если участник делает ставку менее, чем за 5 минут до завершения торгов
          по лоту, они продлевается на 5 минут с момента подачи этой ставки
        </div>
        <CommonButton className={styles.button} onClick={onClose}>
          Понятно
        </CommonButton>
      </div>
    </CommonModal>
  );
};
