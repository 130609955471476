import { useQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';

import { getUserFacilities } from '@/common/api/requests';
import { Facility, PaginationMeta } from '@/common/entities';

export const useUserFacilitiesSection = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isPending, isError, isFetching } = useQuery<
    [Facility[], PaginationMeta]
  >({
    queryKey: ['userFacilities', currentPage],
    queryFn: () => getUserFacilities(currentPage),
    refetchInterval: 30 * 1000,
    refetchIntervalInBackground: false,
  }) as {
    data?: [Facility[], PaginationMeta];
    isPending: boolean;
    isError: boolean;
    isFetching: boolean;
  };

  const facilities = data?.[0] || [];
  const paginationMeta = data?.[1];

  const facilitiesAreEmpty = !isPending && !isError && facilities.length === 0;
  const facilitiesHaveError = isError && !isPending;
  const facilitiesAreLoading = isPending;
  const facilitiesAreFetching = isFetching;

  const handlePaginationChange = useCallback((page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  }, []);

  return {
    facilities,
    paginationMeta,
    facilitiesAreEmpty,
    facilitiesHaveError,
    facilitiesAreLoading,
    facilitiesAreFetching,
    handlePaginationChange,
  };
};
