import { useMemo } from 'react';

import {
  AgreementSumStatus,
  FacilityContractType,
  PaymentMethodType,
} from '@/common/constants';
import { ExtraPayment, Facility } from '@/common/entities';
import { getFormattedCurrency } from '@/common/utils';

export const useFacilityTransactionTermsSection = (facility: Facility) => {
  const contractType = useMemo(() => {
    const contractTypeNames = {
      [FacilityContractType.PurchaseAndSaleAgreement]: 'ДКП',
      [FacilityContractType.SharedParticipationAgreement]: 'ДДУ',
      [FacilityContractType.Miscellaneous]: 'Другое',
    };

    if (facility && facility.contractType !== null) {
      const contractTypeName = contractTypeNames[facility.contractType];
      return contractTypeName || 'Неизвестно';
    }

    return 'Неизвестно';
  }, [facility]);

  const agreementSum = useMemo(() => {
    const agreementSumNames = {
      [AgreementSumStatus.Complete]: 'Полная',
      [AgreementSumStatus.Incomplete]: 'Неполная',
    };

    const value =
      facility?.agreementSum !== null
        ? agreementSumNames[facility.agreementSum] || 'Неизвестно'
        : 'Неизвестно';

    return value;
  }, [facility]);

  const paymentMethods = useMemo(() => {
    const paymentMethodNames = {
      [PaymentMethodType.Mortgage]: 'Ипотека',
      [PaymentMethodType.Installment]: 'Рассрочка',
      [PaymentMethodType.MilitaryMortgage]: 'Военная ипотека',
      [PaymentMethodType.MaternityCapital]: 'Материнский капитал',
      [PaymentMethodType.MortgageWithoutDownPayment]: 'Ипотека без ПВ',
      [PaymentMethodType.FamilyMortgage]: 'Семейная ипотека',
      [PaymentMethodType.PaymentWithVAT]: 'Возврат НДС',
      [PaymentMethodType.ITMortgage]: 'IT ипотека',
      [PaymentMethodType.TrenchMortgage]: 'Траншевая ипотека',
      [PaymentMethodType.PreferentialMortgage]: 'Льготная ипотека',
      [PaymentMethodType.SubsidizedMortgage]: 'Субсидированная ипотека',
      [PaymentMethodType.MortgageWithStateSupport]: 'Ипотека с господдержкой',
    };

    const value = (facility?.paymentMethods || [])
      .map((method) => paymentMethodNames[method] || '')
      .filter(Boolean)
      .join(', ');

    return value || null;
  }, [facility]);

  const extraPayments = useMemo(() => {
    const value = (facility?.extraPayments || [])
      .map((payment: ExtraPayment) => {
        return `${payment.extraPaymentName} - ${getFormattedCurrency(payment.extraPaymentPrice)}`;
      })
      .filter(Boolean)
      .join(', ');

    return value || null;
  }, [facility]);

  return {
    contractType,
    agreementSum,
    paymentMethods,
    extraPayments,
  };
};
