import { useContext, useMemo } from 'react';

import { FacilityStatus, FacilityType } from '@/common/constants';
import { Facility } from '@/common/entities';
import { UserStoreContext } from '@/common/stores';
import { getFormattedCurrency } from '@/common/utils';

export const useFacilityCard = (facility: Facility) => {
  const userStore = useContext(UserStoreContext);
  const userId = userStore.user?.id;

  const showFacilityType = useMemo(() => {
    const facilityTypeNames = {
      [FacilityType.Flat]: 'Квартира',
      [FacilityType.Apartment]: 'Апартамент',
      [FacilityType.Residential]: 'Жилое помещение',
      [FacilityType.NonResidential]: 'Нежилое помещение',
      [FacilityType.House]: 'Дом',
      [FacilityType.Townhouse]: 'Таунхаус',
      [FacilityType.Plot]: 'Участок',
      [FacilityType.Hotel]: 'Гостиница',
      [FacilityType.Office]: 'Офисное помещение',
      [FacilityType.CateringPremises]: 'Помещение общественного питания',
      [FacilityType.GeneralPurpose]: 'Помещение свободного назначения',
      [FacilityType.IndustrialPremises]: 'Производственное помещение',
      [FacilityType.Warehouse]: 'Складское помещение',
      [FacilityType.RetailPremises]: 'Торговое помещение',
      [FacilityType.CarService]: 'Автосервис',
      [FacilityType.Building]: 'Здание',
      [FacilityType.Parking]: 'Парковка',
    };

    if (facility && facility.type !== null) {
      const facilityTypeName = facilityTypeNames[facility.type];
      return facilityTypeName || 'Неизвестный тип';
    }
    return 'Неизвестный тип';
  }, [facility]);

  const locationPropertyContent = useMemo(() => {
    return [facility.city?.name, facility.name].filter(Boolean).join(', ');
  }, [facility]);

  const areaM2PropertyContent = useMemo(
    () => `${facility.areaM2} м²`,
    [facility],
  );

  const showMaxBetPropertyContent = useMemo(() => {
    const bet =
      facility?.bet !== undefined && facility.bet !== 0
        ? getFormattedCurrency(facility.bet, true)
        : getFormattedCurrency(facility.minBet ?? 0, true);

    return bet;
  }, [facility]);

  const showFacilityImage = useMemo(() => {
    const facilityImage =
      facility.photos?.find((photo) => photo.order === 1)?.photo.path ||
      facility.photos?.find((photo) => !photo.isSchema)?.photo.path;

    return facilityImage || '/images/no-image.webp';
  }, [facility]);

  const showFacilitySchema = useMemo(() => {
    const facilitySchema =
      facility.photos
        ?.filter((photo) => photo.isSchema)
        ?.sort((photoA, photoB) => (photoA.order || 0) - (photoB.order || 0))[0]
        ?.photo.path ||
      facility.photos?.find((photo) => photo.isSchema)?.photo.path;

    return facilitySchema || null;
  }, [facility]);

  const showBetTitle = useMemo(() => {
    if (facility.status === FacilityStatus.Draft) {
      return 'Начальная ставка';
    }

    return 'Текущая ставка';
  }, [facility]);

  const myBet = useMemo(() => {
    if (!facility?.bets?.length) return null;

    const sorted = [...facility.bets].sort(
      (betA, betB) => (betB.amount ?? 0) - (betA.amount ?? 0),
    );

    return sorted.find((bet) => bet.userId === userId) || null;
  }, [facility, userId]);

  const showMyBet = useMemo(() => {
    if (!myBet) return null;

    return getFormattedCurrency(myBet.amount, true);
  }, [myBet]);

  const isMyBetTheHighest = useMemo(() => {
    if (!myBet) return false;

    if (facility?.bet && myBet.amount === facility.bet) {
      return true;
    }

    return false;
  }, [facility, myBet]);

  return {
    locationPropertyContent,
    showFacilityType,
    areaM2PropertyContent,
    showMaxBetPropertyContent,
    showFacilityImage,
    showFacilitySchema,
    showBetTitle,
    showMyBet,
    isMyBetTheHighest,
  };
};
