import { Link } from 'react-router-dom';

import { paths } from '@/common/constants';

import styles from './common-footer-logo.module.css';

export const CommonFooterLogo = () => (
  <div className={styles.wrapper}>
    <Link to={paths.getHomePath()} className={styles.logo}>
      MinoTower
    </Link>

    <p className={styles.description}>
      ООО “ЮГрупп”,
      <br />
      ОГРН 1142366013910,
      <br />
      ИНН 2320225543
    </p>
  </div>
);
