import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { Fragment } from 'react/jsx-runtime';

import arrowBackDarkIcon from '@/assets/icons/arrow-back-dark-icon.svg';

import styles from './breadcrumbs.module.css';
import { useBreadcrumbs } from './use-breadcrumbs';

export interface Breadcrumb {
  label: string;
  path: string;
}

interface Props {
  readonly loading?: boolean;
  readonly breadcrumbs: Breadcrumb[];
  readonly className?: string;
}

export const Breadcrumbs = ({
  loading = false,
  breadcrumbs,
  className = '',
}: Props) => {
  const {
    handleBackButtonClick,
    checkIfBreadcrumbActive,
    handleBreadcrumbClick,
  } = useBreadcrumbs(breadcrumbs);

  return (
    <div className={classNames(styles.breadcrumbs, className)}>
      <button className={styles.backButton} onClick={handleBackButtonClick}>
        <img src={arrowBackDarkIcon} className={styles.backIcon} />
      </button>
      {loading ? (
        <Skeleton className={styles.skeleton} />
      ) : (
        breadcrumbs.map((breadcrumb, index) => (
          <Fragment key={breadcrumb.path}>
            <div
              className={classNames(
                styles.breadcrumb,
                checkIfBreadcrumbActive(breadcrumb.path) &&
                  styles.breadcrumbActive,
              )}
              onClick={() => handleBreadcrumbClick(breadcrumb.path)}
            >
              {`${breadcrumb.label}`}
            </div>
            <span>{index !== breadcrumbs.length - 1 ? '/' : ''}</span>
          </Fragment>
        ))
      )}
    </div>
  );
};
