import { Link } from 'react-router-dom';

import { paths } from '@/common/constants';

import styles from './common-header-logo.module.css';

export const CommonHeaderLogo = () => (
  <Link to={paths.getHomePath()} className={styles.logo}>
    MinoTower
  </Link>
);
