import { CommonIconType } from '@/common/components';

import { StepsCard } from '../steps-card/steps-card';
import styles from './steps.module.css';

export const Steps = () => (
  <div id="steps">
    <div className={styles.stepsText}>
      <h1>
        Как проходит аукцион<span> с помощью MinoTower</span>
      </h1>
    </div>

    <div className={styles.gridContainer}>
      <div className={styles.gridItem}>
        <StepsCard
          order="1"
          icon={CommonIconType.UserRectangle}
          hasButton={false}
          title="Авторизуйтесь в личном кабинете"
          description="После регистрации, можно увидеть доступные и предстоящие аукционы, узнать подробнее о лотах "
        />
      </div>

      <div className={styles.gridItem} />
      <div className={styles.gridItem} />

      <div className={styles.gridItem}>
        <StepsCard
          order="2"
          icon={CommonIconType.Coin}
          hasButton={false}
          title="Внесите депозит перед инвестицией"
          description="В случае выигрыша платеж входит в стоимость объекта, при других вариантах - возвращается полностью"
        />
      </div>

      <div className={styles.gridItem}>
        <StepsCard
          order="3"
          icon={CommonIconType.Building}
          hasButton={false}
          title="Покупайте по лучшей цене"
          description="Участник делает ставку, пока не будет предложена более высокая цена. После аукциона, участник с максимально высокой ставкой получит уведомление о выигрыше"
        />
      </div>

      <div className={styles.gridItem} />
      <div className={styles.gridItem} />

      <div className={styles.gridItem}>
        <StepsCard
          order="4"
          icon={CommonIconType.Offer}
          hasButton={false}
          title="Заберите ключи"
          description="Наша компания сопроводит на каждом этапе покупки объекта. Полное юр-сопровождение и помощь в одобрении ипотеки."
        />
      </div>
    </div>
  </div>
);
