import { useState } from 'react';

export const useBecomePartnerModal = () => {
  const [isBecomePartnerModalOpen, setIsBecomePartnerModalOpen] =
    useState(false);

  const handleBecomePartnerModalClose = () => {
    setIsBecomePartnerModalOpen(false);
  };

  const handleBecomePartnerModalOpen = () => {
    setIsBecomePartnerModalOpen(true);
  };

  return {
    isBecomePartnerModalOpen,
    handleBecomePartnerModalClose,
    handleBecomePartnerModalOpen,
  };
};
