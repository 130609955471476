import classNames from 'classnames';

import { CommonIcon, CommonIconType } from '@/common/components';
import { SupportContact } from '@/common/constants';
import { formatPhoneNumber } from '@/common/utils';

import styles from './sales-department-button.module.css';

export const SalesDepartmentButton = ({
  showContactOptions,
  setShowContactOptions,
}: {
  readonly showContactOptions: boolean;
  readonly setShowContactOptions: (value: boolean) => void;
}) => {
  return (
    <div
      className={styles.salesDepartmentButtonWrapper}
      onMouseEnter={() => setShowContactOptions(true)}
      onMouseLeave={() => setShowContactOptions(false)}
    >
      <div className={styles.salesDepartmentButton}>
        <button onClick={() => setShowContactOptions(!showContactOptions)}>
          <CommonIcon
            type={CommonIconType.Phone}
            className={styles.phoneIcon}
          />
          <span className={styles.text}>Отдел продаж</span>
        </button>
      </div>

      {showContactOptions ? <div className={styles.invisibleBridge} /> : null}

      <div
        className={classNames(styles.contactOptions, {
          [styles.contactOptionsShow]: showContactOptions,
        })}
      >
        <a
          href={`tel:${SupportContact.Phone}`}
          className={styles.callButton}
          onClick={() => setShowContactOptions(false)}
        >
          <div className={styles.option}>
            <CommonIcon
              type={CommonIconType.Phone}
              className={styles.optionIcon}
            />
            <span className={styles.optionText}>
              Отдел продаж ({formatPhoneNumber(SupportContact.Phone)})
            </span>
            <div className={styles.linkButton}>
              <CommonIcon
                type={CommonIconType.ArrowTop}
                className={styles.arrowIcon}
              />
            </div>
          </div>
        </a>

        <a
          href={SupportContact.WhatsApp}
          target="_blank"
          rel="noreferrer"
          onClick={() => setShowContactOptions(false)}
        >
          <div className={styles.option}>
            <CommonIcon
              type={CommonIconType.WhatsApp}
              className={styles.optionIcon}
            />
            <span className={styles.optionText}>WhatsApp</span>
            <div className={styles.linkButton}>
              <CommonIcon
                type={CommonIconType.ArrowTop}
                className={styles.arrowIcon}
              />
            </div>
          </div>
        </a>

        <a
          href={SupportContact.Telegram}
          target="_blank"
          rel="noreferrer"
          onClick={() => setShowContactOptions(false)}
        >
          <div className={styles.option}>
            <CommonIcon
              type={CommonIconType.Telegram}
              className={styles.optionIcon}
            />
            <span className={styles.optionText}>Telegram</span>
            <div className={styles.linkButton}>
              <CommonIcon
                type={CommonIconType.ArrowTop}
                className={styles.arrowIcon}
              />
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};
