import { User } from '@/common/entities';

import { UserResponse } from '../responses';
import { mapReferrerResponseToReferrer } from './referrer.mapper';

export const mapUserResponseToUser = (response: UserResponse): User => ({
  id: response.id,
  phoneNumber: response.phone_number,
  firstName: response.first_name,
  lastName: response.last_name,
  email: response.email,
  balance: response.balance,
  isOnboarded: response.is_onboarded,
  referrerUser: response.referrer_user
    ? mapReferrerResponseToReferrer(response.referrer_user)
    : null,
  createdAt: response.created_at,
});
