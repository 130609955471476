import { makeAutoObservable, runInAction } from 'mobx';

import { Api } from '@/common/api';
import { Round } from '@/common/entities';

export class RoundsStore {
  api: Api | null = null;

  rounds: Round[] = [];
  isLoading = false;
  hasError = false;

  round: Round | null = null;

  constructor(api: Api | null) {
    makeAutoObservable(this);

    this.api = api;
  }

  async loadRounds() {
    if (!this.api) {
      return;
    }

    runInAction(() => {
      this.isLoading = true;
      this.hasError = false;
    });

    try {
      const rounds = await this.api.getRounds();

      runInAction(() => {
        this.rounds = rounds;
      });
    } catch {
      runInAction(() => {
        this.hasError = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  clearRound() {
    runInAction(() => {
      this.round = null;
    });
  }

  setRound(round: Round) {
    runInAction(() => {
      this.round = round;
    });
  }
}
