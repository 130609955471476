import classNames from 'classnames';

import styles from './common-button.module.css';
import { CommonButtonProps } from './common-button.types';
import { CommonButtonLoader } from './components';

export const CommonButton = ({
  children,
  type,
  isLoading = false,
  className,
  onClick,
}: CommonButtonProps) => {
  return (
    <button
      type={type}
      className={classNames(styles.commonButton, className)}
      onClick={onClick}
    >
      {isLoading ? <CommonButtonLoader /> : children}
    </button>
  );
};
