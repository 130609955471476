import { FC } from 'react';
import { Link } from 'react-router-dom';

import { CommonIcon, CommonIconType } from '@/common/components';
import { paths } from '@/common/constants';

import styles from './steps-card.module.css';

interface StepsCardProps {
  readonly order: string;
  readonly icon: CommonIconType;
  readonly title: string;
  readonly description: string;
  readonly hasButton: boolean;
}

export const StepsCard: FC<StepsCardProps> = ({
  order,
  icon,
  title,
  description,
  hasButton,
}) => (
  <div className={styles.card}>
    <div className={styles.header}>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.logos}>
        <div className={styles.orderContainer}>
          <p className={styles.order}>{order}</p>
        </div>
        <div className={styles.iconContainer}>
          <CommonIcon type={icon} className={styles.icon} />
        </div>
      </div>
    </div>

    <p className={styles.description}>{description}</p>
    {hasButton ? (
      <Link to={paths.getSignUpPath()}>
        <button className={styles.loginButton}>Зарегистрироваться</button>
      </Link>
    ) : (
      ''
    )}
  </div>
);
