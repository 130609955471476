import { FacilityPhoto } from '@/common/entities';

import { StaticObjectResponse } from '../responses';

export interface FacilityPhotoResponse {
  id: string;
  is_schema: boolean;
  photo: StaticObjectResponse;
  order: number;
}

export const mapFacilityPhotoResponseToFacilityPhoto = (
  response: FacilityPhotoResponse,
): FacilityPhoto => {
  return {
    id: response.id,
    isSchema: response.is_schema,
    photo: response.photo,
    order: response.order,
  };
};
