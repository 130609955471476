import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { CommonButton, CommonEmptyPlaceholder } from '@/common/components';
import { Breadcrumbs } from '@/common/components/breadcrumbs';
import {
  CommonHeaderRefillRequestModal,
  useCommonHeaderRefillRequestModal,
} from '@/common/components/common-header/components/common-header-refill-request-modal';
import { EventCode, FacilityStatus } from '@/common/constants';
import { useUser } from '@/common/hooks/use-user';
import { UserStoreContext } from '@/common/stores';

import {
  BetOnFacilityModal,
  FacilityImagesSection,
  FacilityLinksSection,
  FacilityMainInfoSection,
  FacilityParamsSection,
  FacilityTransactionTermsSection,
  SalesDepartmentButton,
} from '../../components';
import { useBetOnFacilityModal } from '../../components/bet-on-facility-modal';
import '../../components/facility-links-section';
import { FacilityStoreContext } from '../../stores/facility-store/facility-store.context';
import { useDetailFacilityView } from './detail-facility-view.domain';
import styles from './detail-facility-view.module.css';

export const DetailFacilityView = observer(() => {
  const params = useParams<{ facilityId: string }>();
  const facilityStore = useContext(FacilityStoreContext);
  const userStore = useContext(UserStoreContext);
  const { refetch: refetchUser } = useUser();

  const {
    facility,
    facilityIsLoading,
    isMyBetTheHighest,
    minNewBet,
    breadcrumbs,
    showContactOptions,
    handleCloseContactOptions,
    showOutbidButton,
    setShowContactOptions,
    refetch,
  } = useDetailFacilityView(params.facilityId || '');

  const {
    isBetOnFacilityModalOpen,
    openBetOnFacilityModal,
    closeBetOnFacilityModal,
    betOnFacilityModalContent,
    changeBetOnFacilityModalContentToSuccess,
  } = useBetOnFacilityModal();

  const {
    isCommonHeaderRefillRequestModalOpen,
    handleCommonHeaderRefillRequestModalClose,
    handleCommonHeaderRefillRequestModalOpen,
    commonHeaderRefillRequestModalContent,
    changeCommonHeaderRefillRequestModalContentToForm,
    changeCommonHeaderRefillRequestModalContentToSuccess,
  } = useCommonHeaderRefillRequestModal();

  useEffect(() => {
    facilityStore.createFacilityViewEvent({
      facilityId: params?.facilityId ?? '',
      code: EventCode.FacilityView,
      id: uuidv4(),
    });
  }, [facilityStore, params.facilityId]);

  const handleBetOnFacilityButtonClick = useCallback(() => {
    if (userStore.user?.balance === 0 && !isMyBetTheHighest) {
      handleCommonHeaderRefillRequestModalOpen();
    } else {
      openBetOnFacilityModal();
    }
  }, [
    userStore.user,
    isMyBetTheHighest,
    handleCommonHeaderRefillRequestModalOpen,
    openBetOnFacilityModal,
  ]);

  const handleRefillRequest = useCallback(
    async (values: { amount: number }) => {
      if (userStore.user?.id) {
        await userStore.requestRefill(
          { amount: values.amount },
          changeCommonHeaderRefillRequestModalContentToSuccess,
        );
      }
    },
    [userStore, changeCommonHeaderRefillRequestModalContentToSuccess],
  );

  const handleBetOnFacility = useCallback(
    async (values: { amount: number }) => {
      if (facility?.id) {
        await facilityStore.betOnFacility(
          facility.id,
          { amount: values.amount },
          changeBetOnFacilityModalContentToSuccess,
        );
        await refetch();
        await refetchUser();
      }
    },
    [
      facility,
      facilityStore,
      changeBetOnFacilityModalContentToSuccess,
      refetch,
      refetchUser,
    ],
  );

  if (facilityIsLoading) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <Skeleton className={styles.skeletonBreadcrumbs} />
          <Skeleton className={styles.skeletonSection} />
          <Skeleton className={styles.skeletonSection} />
        </div>
      </div>
    );
  }

  if (!facility) {
    return (
      <div className={styles.placeholderWrapper}>
        <CommonEmptyPlaceholder />
      </div>
    );
  }

  return (
    <div>
      <BetOnFacilityModal
        isOpen={isBetOnFacilityModalOpen}
        onClose={closeBetOnFacilityModal}
        facility={facility}
        onSubmit={handleBetOnFacility}
        isSubmitLoading={facilityStore.isBetLoading}
        modalContent={betOnFacilityModalContent}
      />
      <CommonHeaderRefillRequestModal
        isOpen={isCommonHeaderRefillRequestModalOpen}
        onClose={handleCommonHeaderRefillRequestModalClose}
        onSubmit={handleRefillRequest}
        modalContent={commonHeaderRefillRequestModalContent}
        onRequestForm={changeCommonHeaderRefillRequestModalContentToForm}
      />
      <div className={styles.wrapper}>
        <div className={styles.content} onClick={handleCloseContactOptions}>
          <Breadcrumbs
            breadcrumbs={breadcrumbs}
            className={styles.breadcrumbs}
          />
          <div className={styles.imageSection}>
            <FacilityImagesSection facility={facility} />
          </div>
          <FacilityMainInfoSection facility={facility} />
          <FacilityParamsSection facility={facility} />
          <FacilityTransactionTermsSection facility={facility} />
          {(facility.links?.length ?? 0) > 0 && (
            <FacilityLinksSection facility={facility} />
          )}
        </div>
      </div>
      <div className={styles.footer}>
        {facility.status === FacilityStatus.Active && (
          <CommonButton
            className={
              showOutbidButton ? styles.outbidButton : styles.betButton
            }
            onClick={handleBetOnFacilityButtonClick}
          >
            <span className={styles.betButtonText}>
              {showOutbidButton ? 'Перебить ставку' : 'Сделать ставку'}
            </span>
            <span className={styles.betButtonMinBet}>от {minNewBet}</span>
          </CommonButton>
        )}
      </div>
      <SalesDepartmentButton
        showContactOptions={showContactOptions}
        setShowContactOptions={setShowContactOptions}
      />
    </div>
  );
});
