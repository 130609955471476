import { MessageInstance } from 'antd/es/message/interface';
import { makeAutoObservable, runInAction } from 'mobx';

import { Api } from '@/common/api';
import { CreateEventDto } from '@/common/api/requests/events/dto';
import { Facility } from '@/common/entities';

export class FacilityStore {
  api: Api | null = null;
  message: MessageInstance | null = null;

  facility: Facility | null = null;
  isLoading = false;
  hasError = false;
  isBetLoading = false;

  constructor(api: Api | null, message: MessageInstance | null) {
    makeAutoObservable(this);

    this.api = api;
    this.message = message;
  }

  async loadFacility(facilityId: string, showLoader = true) {
    if (!this.api) {
      return;
    }

    runInAction(() => {
      if (showLoader) {
        this.isLoading = true;
      }
      this.hasError = false;
    });

    try {
      const facility = await this.api.getOneFacility(facilityId);

      runInAction(() => {
        this.facility = facility;
      });
    } catch {
      runInAction(() => {
        this.hasError = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async createFacilityViewEvent(payload: CreateEventDto) {
    if (!this.api) {
      return;
    }

    await this.api.createEvent(payload);
  }

  async betOnFacility(
    facilityId: string,
    payload: { amount: number },
    onSucces?: () => void,
  ) {
    if (!this.api) {
      return;
    }

    runInAction(() => {
      this.isBetLoading = true;
    });

    try {
      await this.api.betOnFacility({
        facilityId: facilityId.toString(),
        ...payload,
      });

      if (onSucces) {
        onSucces();
      }

      if (this.message) {
        this.message.success('Ставка успешно совершена');
      }
    } catch (error) {
      if (this.message) {
        this.message.error('Пополните депозит');
      }
    } finally {
      runInAction(() => {
        this.isBetLoading = false;
      });
    }
  }

  clearFacility() {
    runInAction(() => {
      this.facility = null;
      this.hasError = false;
      this.isLoading = false;
    });
  }

  setFacility(facility: Facility) {
    runInAction(() => {
      this.facility = facility;
    });
  }
}
