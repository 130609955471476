import { useContext } from 'react';

import { CommonIcon, CommonIconType } from '@/common/components';
import {
  CommonHeaderRefillRequestModal,
  useCommonHeaderRefillRequestModal,
} from '@/common/components/common-header/components/common-header-refill-request-modal';
import { UserStoreContext } from '@/common/stores';
import { getFormattedCurrency } from '@/common/utils';

import { ProfileSectionContainer } from '../profile-section-container';
import styles from './balance-section.module.css';
import { BalanceSectionProps } from './balance-section.types';

export const BalanceSection = ({ balance }: BalanceSectionProps) => {
  const userStore = useContext(UserStoreContext);

  const {
    isCommonHeaderRefillRequestModalOpen,
    handleCommonHeaderRefillRequestModalClose,
    handleCommonHeaderRefillRequestModalOpen,
    commonHeaderRefillRequestModalContent,
    changeCommonHeaderRefillRequestModalContentToForm,
    changeCommonHeaderRefillRequestModalContentToSuccess,
  } = useCommonHeaderRefillRequestModal();

  const handleRefillRequest = async (values: { amount: number }) => {
    if (userStore.user?.id) {
      await userStore.requestRefill(
        {
          amount: values.amount,
        },
        changeCommonHeaderRefillRequestModalContentToSuccess,
      );
    }
  };

  return (
    <ProfileSectionContainer>
      <CommonHeaderRefillRequestModal
        isOpen={isCommonHeaderRefillRequestModalOpen}
        onClose={handleCommonHeaderRefillRequestModalClose}
        onSubmit={handleRefillRequest}
        modalContent={commonHeaderRefillRequestModalContent}
        onRequestForm={changeCommonHeaderRefillRequestModalContentToForm}
      />
      <div className={styles.balanceWrapper}>
        <div className={styles.balanceMain}>
          <div className={styles.balanceParam}>
            <span className={styles.balanceLabel}>Баланс</span>
            <span className={styles.balanceValue}>
              {getFormattedCurrency(balance || 0, true)}
            </span>
          </div>
          <button
            className={styles.addBalanceButton}
            onClick={handleCommonHeaderRefillRequestModalOpen}
          >
            <CommonIcon
              type={CommonIconType.Plus}
              className={styles.addBalanceIcon}
            />
          </button>
        </div>
        <p className={styles.balanceCaption}>
          Баланс используется как депозит при постановке ставки на лот
        </p>
      </div>
    </ProfileSectionContainer>
  );
};
