import { FacilityLink } from '@/common/entities';

import { FacilityLinkResponse } from '../responses';

export const mapFacilityLinkResponseToFacilityLink = (
  response: FacilityLinkResponse,
): FacilityLink => {
  return {
    id: response.id,
    contentType: response.content_type,
    label: response.label,
    url: response.url,
    description: response.description,
  };
};
