import { useContext, useMemo } from 'react';

import { Facility } from '@/common/entities';
import { UserStoreContext } from '@/common/stores';
import { getFormattedCurrency } from '@/common/utils';

export const useFacilityMyBetSection = (facility: Facility) => {
  const userStore = useContext(UserStoreContext);
  const userId = userStore.user?.id;

  const myBet = useMemo(() => {
    if (!facility?.bets?.length) return null;

    const sorted = [...facility.bets].sort(
      (betA, betB) => (betB.amount ?? 0) - (betA.amount ?? 0),
    );

    return sorted.find((bet) => bet.userId === userId) || null;
  }, [facility, userId]);

  const showMyBet = useMemo(() => {
    if (!myBet) return null;

    return getFormattedCurrency(myBet.amount, true);
  }, [myBet]);

  const isMyBetTheHighest = useMemo(() => {
    if (!myBet) return false;

    if (facility?.bet && myBet.amount === facility.bet) {
      return true;
    }

    return false;
  }, [facility, myBet]);

  const showPricePerMeter = useMemo(() => {
    if (!myBet || !facility.areaM2) {
      return null;
    }

    const price = myBet.amount;

    const pricePerMeter = Math.round(price / facility.areaM2 / 1000) * 1000;

    const formattedPricePerMeter = `${getFormattedCurrency(pricePerMeter)}/м²`;

    return formattedPricePerMeter;
  }, [facility.areaM2, myBet]);

  return {
    showMyBet,
    isMyBetTheHighest,
    showPricePerMeter,
  };
};
