export enum CommonIconType {
  Menu = 'bx bx-menu',
  Plus = 'bx bx-plus',
  Minus = 'bx bx-minus',
  User = 'bx bx-user',
  InfoCirlce = 'bx bx-info-circle',
  Box = 'bx bx-box',
  Error = 'bx bx-error',
  Cross = 'bx bx-x',
  WhatsApp = 'bx bxl-whatsapp',
  WhatsAppSquare = 'bx bxl-whatsapp-square',
  Telegram = 'bx bxl-telegram',
  Map = 'bx bx-map',
  File = 'bx bx-file',
  CoinStack = 'bx bx-coin-stack',
  LogoutCircle = 'bx bx-log-out-circle',
  Coin = 'bx bxs-coin',
  UserRectangle = 'bx bxs-user-pin',
  Building = 'bx bxs-building',
  Offer = 'bx bxs-offer',
  Edit = 'bx bx-edit',
  ChevronLeft = 'bx bxs-chevron-left',
  ChevronRight = 'bx bxs-chevron-right',
  ChevronDown = 'bx bx-chevron-down',
  Time = 'bx bx-time',
  Xcircle = 'bx bxs-x-circle',
  AlarmExclamation = 'bx bxs-alarm-exclamation',
  Videos = 'bx bxs-videos',
  Show = 'bx bx-show-alt',
  ArrowTop = 'bx bx-up-arrow-alt',
  Table = 'bx bx-table',
  WindowOpen = 'bx bx-window-open',
  Buildings = 'bx bx-buildings',
  Document = 'bx bxs-file-doc',
  CoinWhite = 'bx bx-coin',
  Check = 'bx bx-check',
  CheckCircle = 'bx bxs-check-circle',
  UserVoice = 'bx bx-user-voice',
  UserPlus = 'bx bx-user-plus',
  Infinite = 'bx bx-infinite',
  Copy = 'bx bx-copy-alt',
  Phone = 'bx bx-phone',
  PhoneFilled = 'bx bxs-phone',
  Envelope = 'bx bxs-envelope',
  Cabinet = 'bx bxs-cabinet',
}
