import styles from './facility-transaction-card.module.css';
import { FacilityTransactionCardProps } from './facility-transaction-card.types';

export const FacilityTransactionCard = ({
  label,
  value,
}: FacilityTransactionCardProps) => (
  <div className={styles.card}>
    <span className={styles.paramLabel}>{label}</span>
    <span className={styles.paramValue}>{value}</span>
  </div>
);
