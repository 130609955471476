import { useContext } from 'react';

import { UserStoreContext } from '../stores';
import { request } from './request';
import {
  applySignInCode,
  applySignUpCode,
  betOnFacility,
  createEvent,
  getFacilities,
  getMe,
  getOneFacility,
  getReferredUsers,
  getReferredUsersBets,
  getRounds,
  onboardUser,
  requestRefill,
  requestSignInCode,
  requestSignUpCode,
  updateUser,
} from './requests';
import { getUserFacilities } from './requests/users/get-user-facilities';

export interface Api {
  getFacilities: typeof getFacilities;
  getOneFacility: typeof getOneFacility;
  requestSignInCode: typeof requestSignInCode;
  applySignInCode: typeof applySignInCode;
  requestSignUpCode: typeof requestSignUpCode;
  applySignUpCode: typeof applySignUpCode;
  getMe: typeof getMe;
  betOnFacility: typeof betOnFacility;
  requestRefill: typeof requestRefill;
  updateUser: typeof updateUser;
  onboardUser: typeof onboardUser;
  getUserFacilities: typeof getUserFacilities;
  createEvent: typeof createEvent;
  getReferredUsers: typeof getReferredUsers;
  getRounds: typeof getRounds;
  getReferredUsersBets: typeof getReferredUsersBets;
}

export const useApi = (): Api => {
  const userStore = useContext(UserStoreContext);

  request.interceptors.request.clear();

  request.interceptors.request.use((config) => {
    const accessToken = userStore.getAccessToken();

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  });

  request.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        userStore.logout();

        return request(error.config);
      }

      return Promise.reject(error);
    },
  );

  return {
    getFacilities,
    getOneFacility,
    requestSignInCode,
    applySignInCode,
    requestSignUpCode,
    applySignUpCode,
    getMe,
    betOnFacility,
    requestRefill,
    updateUser,
    onboardUser,
    getUserFacilities,
    createEvent,
    getReferredUsers,
    getRounds,
    getReferredUsersBets,
  };
};
