import classNames from 'classnames';
import { useState } from 'react';

import {
  CommonFacilityTags,
  CommonIcon,
  CommonIconType,
} from '@/common/components';
import { FacilityStatus } from '@/common/constants';

import { FacilityMap } from '../facility-map';
import { FacilitySectionWrapper } from '../facility-section-wrapper';
import { FacilityMyBetSection } from './components';
import { useFacilityMainInfoSection } from './facility-main-info-section.domain';
import styles from './facility-main-info-section.module.css';
import { FacilityMainInfoSectionProps } from './facility-main-info-section.types';

export const FacilityMainInfoSection = ({
  facility,
}: FacilityMainInfoSectionProps) => {
  const {
    title,
    showMaxBetPropertyContent,
    showMinBetStep,
    showPricePerMeter,
    handleShowMap,
    handleHideMap,
    showMap,
  } = useFacilityMainInfoSection(facility);

  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  const toggleDescription = () => {
    setIsDescriptionExpanded(!isDescriptionExpanded);
  };

  if (showMap && facility) {
    return <FacilityMap facility={facility} onClose={handleHideMap} />;
  }

  return (
    <FacilitySectionWrapper>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.statistics}>
            {facility.viewsCount ? (
              <div
                className={styles.statisticsItem}
                title={`Количество просмотров: ${facility.viewsCount}`}
              >
                <CommonIcon
                  type={CommonIconType.Show}
                  className={styles.showIcon}
                />
                <span className={styles.statisticsItemText}>
                  {facility.viewsCount}
                </span>
              </div>
            ) : null}
          </div>
          <div className={styles.tags}>
            <CommonFacilityTags facility={facility} />
          </div>
          <div className={styles.addressSection}>
            <h2 className={styles.title}>{title}</h2>
            <div className={styles.addressContainer}>
              {facility.address ? (
                <p className={styles.address}>{facility.address}</p>
              ) : null}
              {facility.latitude && facility.longitude ? (
                <button
                  className={styles.showMapButton}
                  onClick={handleShowMap}
                >
                  Показать на карте
                </button>
              ) : null}
            </div>
          </div>
        </div>

        <FacilityMyBetSection facility={facility} />

        <div className={styles.priceInfo}>
          {facility.status === FacilityStatus.Completed ? (
            <div className={styles.completedLabel}>Торги завершены</div>
          ) : (
            <>
              <div className={styles.priceSection}>
                <div className={styles.priceLabel}>
                  <CommonIcon
                    type={CommonIconType.CoinWhite}
                    className={styles.coinIcon}
                  />
                  {facility.status === FacilityStatus.Draft ? (
                    <span>Начальная ставка</span>
                  ) : (
                    <span>Текущая ставка</span>
                  )}
                </div>
                <p className={styles.price}>{showMaxBetPropertyContent}</p>
                <p className={styles.pricePerMeter}>
                  За метр -- {showPricePerMeter}
                </p>
              </div>
              <div className={styles.additionalInfo}>
                <div className={styles.onboardedUsersCount}>
                  {facility.onboardedUsersCount
                    ? `Количество участников: ${facility.onboardedUsersCount}`
                    : null}
                </div>
                <div className={styles.betStep}>
                  {facility.betStep ? (
                    <span>Минимальный шаг ставки: {showMinBetStep}</span>
                  ) : null}
                </div>
              </div>
            </>
          )}
        </div>

        {facility.description ? (
          <div className={styles.description}>
            <div className={styles.descriptionTitle}>Описание</div>
            <p
              className={classNames(styles.descriptionText, {
                [styles.descriptionTextExpanded]: isDescriptionExpanded,
              })}
            >
              {facility.description}
            </p>
            <button
              onClick={toggleDescription}
              className={styles.descriptionButton}
            >
              {facility.description.length > 350 && (
                <>
                  <span>
                    {!isDescriptionExpanded ? 'Читать полностью' : 'Скрыть'}
                  </span>
                  <CommonIcon
                    type={CommonIconType.ChevronDown}
                    className={classNames(styles.chevronDownIcon, {
                      [styles.chevronDownIconTurned]: isDescriptionExpanded,
                    })}
                  />
                </>
              )}
            </button>
          </div>
        ) : null}
      </div>
    </FacilitySectionWrapper>
  );
};
