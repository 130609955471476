import { observer } from 'mobx-react-lite';

import { CommonErrorPlaceholder, CommonPagination } from '@/common/components';

import { ActiveFacilitiesListView } from './components/active-facilty-list-view';
import { CompletedFacilitiesListView } from './components/completed-facility-list-view';
import styles from './user-facilites-section.module.css';
import { useUserFacilitiesSection } from './user-facilities-section.domain';

export const UserFacilitiesSection = observer(() => {
  const {
    facilities,
    paginationMeta,
    facilitiesAreEmpty,
    facilitiesHaveError,
    facilitiesAreLoading,
    facilitiesAreFetching,
    handlePaginationChange,
  } = useUserFacilitiesSection();

  return (
    <div className={styles.wrapper}>
      {facilitiesHaveError ? (
        <div className={styles.placeholderWrapper}>
          <CommonErrorPlaceholder />
        </div>
      ) : null}
      <div>
        <ActiveFacilitiesListView
          facilities={facilities}
          facilitiesAreFetching={facilitiesAreFetching}
          facilitiesAreLoading={facilitiesAreLoading}
          facilitiesHaveError={facilitiesHaveError}
        />
        <CompletedFacilitiesListView
          facilities={facilities}
          facilitiesAreLoading={facilitiesAreLoading}
          facilitiesHaveError={facilitiesHaveError}
        />
      </div>
      {!facilitiesAreEmpty ? (
        <div className={styles.paginationWrapper}>
          <CommonPagination
            currentPage={paginationMeta?.page ?? 1}
            total={paginationMeta?.total ?? 0}
            perPage={paginationMeta?.perPage}
            onChange={handlePaginationChange}
          />
        </div>
      ) : null}
    </div>
  );
});
