import { useEffect, useRef, useState } from 'react';

export const useCommonModal = (isOpen: boolean, onClose: () => void) => {
  const [isVisible, setIsVisible] = useState(isOpen);
  const isOpenRef = useRef(isOpen);

  const handleClose = () => {
    if (isOpenRef.current) {
      onClose();
      setIsVisible(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      document.body.classList.add('no-scroll');
    } else {
      setIsVisible(false);
      document.body.classList.remove('no-scroll');
    }

    isOpenRef.current = isOpen;

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isOpen]);

  return {
    isVisible,
    handleClose,
  };
};
