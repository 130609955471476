import { CommonIcon, CommonIconType } from '@/common/components/common-icon';

import {
  CommonHeaderGuideModal,
  useCommonHeaderGuideModal,
} from '../common-header-guide-modal';
import styles from './common-header-guide.module.css';

export const CommonHeaderGuide = () => {
  const {
    isCommonHeaderGuideModalOpen,
    handleCommonHeaderGuideModalOpen,
    handleCommonHeaderGuideModalClose,
  } = useCommonHeaderGuideModal();

  return (
    <>
      <button
        className={styles.guideWrapper}
        onClick={handleCommonHeaderGuideModalOpen}
      >
        <div className={styles.iconWrapper}>
          <CommonIcon
            type={CommonIconType.InfoCirlce}
            className={styles.icon}
          />
        </div>
        <span>Инструкция</span>
      </button>
      <CommonHeaderGuideModal
        isOpen={isCommonHeaderGuideModalOpen}
        onClose={handleCommonHeaderGuideModalClose}
      />
    </>
  );
};
