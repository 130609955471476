import { DocumentSectionParagraph, DocumentSectionTitle } from './components';
import styles from './document-section.module.css';
import { DocumentSectionProps } from './document-section.types';

export const DocumentSection = ({ children }: DocumentSectionProps) => {
  return <div className={styles.documentSection}>{children}</div>;
};

DocumentSection.Title = DocumentSectionTitle;
DocumentSection.Paragraph = DocumentSectionParagraph;
