import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';

import { getMe } from '@/common/api/requests';
import { User } from '@/common/entities';
import { UserStoreContext } from '@/common/stores';

export const useUser = () => {
  const userStore = useContext(UserStoreContext);

  const {
    data: user,
    isLoading,
    isError,
    refetch,
  } = useQuery<User>({
    queryKey: ['user'],
    queryFn: () => getMe(),
    enabled: Boolean(userStore.isAuthenticated),
  });

  useEffect(() => {
    if (user) {
      userStore.setUser(user);
    }
  }, [user, userStore]);

  return {
    user: userStore.user,
    isLoading,
    isError,
    refetch,
  };
};
