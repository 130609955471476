import { observer } from 'mobx-react-lite';

import { Breadcrumbs } from '@/common/components/breadcrumbs/breadcrumbs';

import { PartnerEventsActivitySection } from '../../components';
import { usePartnerEventsView } from './partner-events-view.domain';
import styles from './partner-events-view.module.css';

export const PartnerEventsView = observer(() => {
  const { breadcrumbs } = usePartnerEventsView();

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Breadcrumbs
          loading={false}
          breadcrumbs={breadcrumbs}
          className={styles.breadcrumbs}
        />
        <PartnerEventsActivitySection />
      </div>
    </div>
  );
});
