import Skeleton from 'react-loading-skeleton';

import styles from './facility-card-skeleton.module.css';

export const FacilityCardSkeleton = () => {
  return (
    <div className={styles.facilityCardSkeleton}>
      <Skeleton className={styles.skeleton} />
    </div>
  );
};
