import styles from './common-footer.module.css';
import {
  CommonFooterContacts,
  CommonFooterCopyright,
  CommonFooterLinks,
  CommonFooterLogo,
} from './components';

/**
 * Component for universal footer
 * @returns JSX.Element
 */
export const CommonFooter = () => (
  <footer className={styles.wrapper}>
    <div className={styles.content}>
      <div className={styles.row}>
        <CommonFooterLogo />
        <CommonFooterLinks />
        <CommonFooterContacts />
      </div>
      <CommonFooterCopyright />
    </div>
  </footer>
);
