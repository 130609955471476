import {
  CommonIcon,
  CommonIconType,
  CommonSupportCardProps,
} from '@/common/components';
import { SupportContact } from '@/common/constants';

import styles from './common-support-card.module.css';

export const CommonSupportCard = ({
  type,
  onClick,
}: CommonSupportCardProps) => {
  const iconType =
    type === 'telegram' ? CommonIconType.Telegram : CommonIconType.WhatsApp;

  const text =
    type === 'telegram' ? 'Написать в Telegram' : 'Написать на WhatsApp';

  const link =
    type === 'telegram' ? SupportContact.Telegram : SupportContact.WhatsApp;

  return (
    <div>
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        className={styles.supportCard}
        onClick={onClick}
      >
        <CommonIcon type={iconType} className={styles.supportCardIcon} />
        <span className={styles.supportCardText}>{text}</span>
      </a>
    </div>
  );
};
