import { useMemo } from 'react';

import {
  FacilityClass,
  FacilityDecorationType,
  FacilityLayoutType,
  FacilityTerritoryType,
  FacilityType,
  FacilityView,
  FacilityWaterSupplyType,
} from '@/common/constants';
import { Facility } from '@/common/entities';

import { FacilityParamCardProps } from '../facility-param-card/facility-param-card.types';

export const useFacilityParamsSection = (facility: Facility) => {
  const showFacilityType = useMemo((): FacilityParamCardProps => {
    const facilityTypeNames = {
      [FacilityType.Flat]: 'Квартира',
      [FacilityType.Apartment]: 'Апартамент',
      [FacilityType.Residential]: 'Жилое помещение',
      [FacilityType.NonResidential]: 'Нежилое помещение',
      [FacilityType.House]: 'Дом',
      [FacilityType.Townhouse]: 'Таунхаус',
      [FacilityType.Plot]: 'Участок',
      [FacilityType.Hotel]: 'Гостиница',
      [FacilityType.Office]: 'Офисное помещение',
      [FacilityType.CateringPremises]: 'Помещение общественного питания',
      [FacilityType.GeneralPurpose]: 'Помещение свободного назначения',
      [FacilityType.IndustrialPremises]: 'Производственное помещение',
      [FacilityType.Warehouse]: 'Складское помещение',
      [FacilityType.RetailPremises]: 'Торговое помещение',
      [FacilityType.CarService]: 'Автосервис',
      [FacilityType.Building]: 'Здание',
      [FacilityType.Parking]: 'Парковка',
    };

    const value =
      facility?.type !== null
        ? facilityTypeNames[facility.type] || 'Неизвестный тип'
        : 'Неизвестный тип';

    return { label: 'Тип недвижимости', value };
  }, [facility]);

  const showFacilityClass = useMemo((): FacilityParamCardProps => {
    const facilityClassNames = {
      [FacilityClass.Economy]: 'Эконом',
      [FacilityClass.Business]: 'Бизнес',
      [FacilityClass.Elite]: 'Элит',
      [FacilityClass.Comfort]: 'Комфорт',
    };

    const value =
      facility?.class !== null
        ? facilityClassNames[facility.class] || 'Неизвестный класс'
        : 'Неизвестный класс';

    return { label: 'Класс дома', value };
  }, [facility]);

  const showFacilityFloor = useMemo((): FacilityParamCardProps => {
    const value =
      facility.floor && facility.totalFloors
        ? `${facility.floor} из ${facility.totalFloors}`
        : 'Неизвестно';

    return { label: 'Этаж', value };
  }, [facility]);

  const showFacilityTerritoryType = useMemo((): FacilityParamCardProps => {
    const FacilityTerritoryTypeNames = {
      [FacilityTerritoryType.Closed]: 'Закрытая',
      [FacilityTerritoryType.Open]: 'Открытая',
    };

    const value =
      facility?.territoryType !== null
        ? FacilityTerritoryTypeNames[facility.territoryType] || 'Неизвестно'
        : 'Неизвестно';

    return { label: 'Тип территории', value };
  }, [facility]);

  const hasParking = useMemo((): FacilityParamCardProps => {
    const value = facility?.hasParking ? 'Есть' : 'Нет';
    return { label: 'Паркинг', value };
  }, [facility]);

  const hasElectricity = useMemo((): FacilityParamCardProps => {
    const value = facility?.hasElectricity ? 'Подключено' : 'Нет';
    return { label: 'Электричество', value };
  }, [facility]);

  const hasGas = useMemo((): FacilityParamCardProps => {
    const value = facility?.hasGas ? 'Есть' : 'Нет';
    return { label: 'Газ', value };
  }, [facility]);

  const hasSwimmingPool = useMemo((): FacilityParamCardProps => {
    const value = facility?.hasSwimmingPool ? 'Есть' : 'Нет';
    return { label: 'Бассейн', value };
  }, [facility]);

  const hasBalcony = useMemo((): FacilityParamCardProps => {
    const value = facility?.hasBalcony ? 'Есть' : 'Нет';
    return { label: 'Балкон', value };
  }, [facility]);

  const showWaterSupplyType = useMemo((): FacilityParamCardProps => {
    const FacilityWaterSupplyTypeNames = {
      [FacilityWaterSupplyType.Central]: 'Центральное',
      [FacilityWaterSupplyType.Borehole]: 'Скважина',
    };

    const value =
      facility?.waterSupplyType !== null
        ? FacilityWaterSupplyTypeNames[facility.waterSupplyType] || 'Неизвестно'
        : 'Неизвестно';

    return { label: 'Водоснабжение', value };
  }, [facility]);

  const schemas = useMemo(() => {
    const facilitySchemas =
      facility.photos?.filter((photo) => photo.isSchema) || [];

    return facilitySchemas;
  }, [facility]);

  const showFacilityLayoutType = useMemo((): FacilityParamCardProps => {
    const facilityLayoutTypeNames = {
      [FacilityLayoutType.OpenPlan]: 'Свободная',
      [FacilityLayoutType.Studio]: 'Студия',
      [FacilityLayoutType.OneRoom]: '1 комната',
      [FacilityLayoutType.TwoRooms]: '2 комнаты',
      [FacilityLayoutType.ThreeRooms]: '3 комнаты',
      [FacilityLayoutType.FourAndMore]: '4 и более комнат',
    };

    const value =
      facility?.layoutType !== null
        ? facilityLayoutTypeNames[facility.layoutType] || 'Неизвестно'
        : 'Неизвестно';

    return { label: 'Тип планировки', value };
  }, [facility]);

  const showFacilityView = useMemo((): FacilityParamCardProps => {
    const facilityViewNames = {
      [FacilityView.City]: 'На город',
      [FacilityView.Mountain]: 'На горы',
      [FacilityView.Sea]: 'На море',
      [FacilityView.Park]: 'На парк',
      [FacilityView.Yard]: 'На двор',
    };

    const value =
      facility?.view !== null
        ? facilityViewNames[facility.view] || 'Неизвестно'
        : 'Неизвестно';

    return { label: 'Вид', value };
  }, [facility]);

  const showFacilityEntrance = useMemo((): FacilityParamCardProps => {
    const value = facility?.entrance || 'Неизвестно';
    return { label: 'Корпус', value };
  }, [facility]);

  const showFacilityDecorationType = useMemo((): FacilityParamCardProps => {
    const facilityDecorationTypeNames = {
      [FacilityDecorationType.CompleteEquipment]: 'Ремонт',
      [FacilityDecorationType.FinalFinished]: 'Чистовая',
      [FacilityDecorationType.RoughFinished]: 'Черновая',
      [FacilityDecorationType.WhiteBox]: 'WhiteBox',
      [FacilityDecorationType.PreFinished]: 'Предчистовая',
      [FacilityDecorationType.TurnkeyRenovation]: 'Ремонт под ключ',
    };

    const value =
      facility?.decorationType !== null
        ? facilityDecorationTypeNames[facility.decorationType] || 'Неизвестно'
        : 'Неизвестно';

    return { label: 'Отделка', value };
  }, [facility]);

  const facilityParams = [
    showFacilityType,
    showFacilityLayoutType,
    showFacilityFloor,
    showFacilityView,
    showFacilityDecorationType,
    showFacilityEntrance,
    hasBalcony,
  ];

  const complexParams = [
    showFacilityClass,
    showFacilityTerritoryType,
    hasParking,
    hasElectricity,
    showWaterSupplyType,
    hasGas,
    hasSwimmingPool,
  ];

  return {
    facilityParams,
    complexParams,
    schemas,
  };
};
