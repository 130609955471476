import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { OnboardUserProps } from '@/common/api/requests';
import { paths } from '@/common/constants';
import { UserStoreContext } from '@/common/stores';

import { OnboardingForm } from '../../components';
import styles from './onboarding-view.module.css';

export const OnboardingView = observer(() => {
  const navigate = useNavigate();
  const userStore = useContext(UserStoreContext);

  const user = userStore.user;

  const handleUserOnboard = async (values: OnboardUserProps) => {
    if (user?.id) {
      await userStore.onboardUser({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        role: values.role,
      });
    }

    navigate(paths.getHomePath());
  };

  return (
    <div className={styles.onboardingView}>
      <OnboardingForm
        onSubmit={handleUserOnboard}
        isSubmitLoading={userStore.isUserOnboardLoading}
      />
    </div>
  );
});
