export const paths = {
  getSignInPath: () => '/auth/sign-in',
  getSignUpPath: () => '/auth/sign-up',
  getOnboardingPath: () => '/auth/onboarding',
  getHomePath: () => '/home',
  getFacilityPath: (facilityId: string) => `/facilities/${facilityId}`,
  getProfilePath: () => '/profile',
  getLandingPath: () => '/',
  getUserAgreementPath: () => '/documents/user-agreement',
  getPatnerProgramPath: () => '/partner-program',
  getPartnerEventsPath: () => '/partner-events',
};
