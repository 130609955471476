import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { paths } from '@/common/constants';
import { useReferralCode } from '@/common/hooks';
import { UserStoreContext } from '@/common/stores';

import {
  AuthCodeForm,
  AuthPhoneForm,
  AuthPhoneFormType,
} from '../../components';
import styles from './sign-up-view.module.css';

export const SignUpView = observer(() => {
  const navigate = useNavigate();
  const userStore = useContext(UserStoreContext);

  useEffect(() => {
    userStore.resetAuthFlags();
  }, [userStore]);

  const {
    isReferralCodeExists,
    isLoading,
    removeReferralCode,
    getReferralCode,
  } = useReferralCode();

  if (!isReferralCodeExists && !isLoading && !userStore.accessToken) {
    navigate(paths.getLandingPath());
  }

  const referralCode: string = getReferralCode();

  const handleRequestCodeFormSubmit = (phoneNumber: string) => {
    userStore.requestSignUpCode(phoneNumber, referralCode);
  };

  const handleApplyCodeFormSubmit = async (code: string) => {
    await userStore.applySignUpCode(code, referralCode);

    if (userStore.accessToken) {
      await navigate(paths.getOnboardingPath());
      removeReferralCode();
    }
  };

  return (
    <div className={styles.signUpView}>
      {!userStore.requestedPhoneNumber ? (
        <AuthPhoneForm
          title="Регистрация"
          formType={AuthPhoneFormType.SignUp}
          isSubmitLoading={userStore.isAuthPending}
          onSubmit={handleRequestCodeFormSubmit}
        />
      ) : (
        <AuthCodeForm
          requestedPhoneNumber={userStore.requestedPhoneNumber}
          isSubmitLoading={userStore.isAuthPending}
          submitButtonText="Подтвердить"
          onSubmit={handleApplyCodeFormSubmit}
        />
      )}
    </div>
  );
});
