import { useEffect, useState } from 'react';

export const useReferralCode = () => {
  const [isReferralCodeExists, setIsReferralCodeExists] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkLocalStorageForReferralCode = () => {
    try {
      return localStorage.getItem('r') !== null;
    } catch (error) {
      return false;
    }
  };

  const removeReferralCode = () => {
    localStorage.removeItem('r');
    setIsReferralCodeExists(false);
  };

  const getReferralCode = (): string => {
    try {
      return localStorage.getItem('r') || '';
    } catch (error) {
      return '';
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const referralCodeFromLocalStorage = checkLocalStorageForReferralCode();

    if (referralCodeFromLocalStorage) {
      setIsReferralCodeExists(true);
      setIsLoading(false);
    } else if (params.has('r')) {
      const referralCode = params.get('r');
      if (referralCode) {
        localStorage.setItem('r', referralCode);
        setIsReferralCodeExists(true);
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, []);

  return {
    isReferralCodeExists,
    isLoading,
    removeReferralCode,
    getReferralCode,
  };
};
