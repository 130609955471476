import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-loading-skeleton/dist/skeleton.css';
import { RouterProvider } from 'react-router-dom';

import '@/common/styles/variables.css';

import MessageLayout from './common/layouts/message-layout/message-layout';
import { GlobalInjector } from './common/stores';
import './common/styles/base.css';
import './common/styles/normalize.css';
import { getSentryConfig } from './config';
import { router } from './router';

const queryClient = new QueryClient();

const sentryConfig = getSentryConfig();

Sentry.init({
  dsn: sentryConfig.dsn || undefined,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    'https://minotower.com/',
    'https://dev-ygroup-auction-client-shtpp.ondigitalocean.app/',
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 8,
          colorPrimary: '#181818',
          controlOutline: '#555454',
          controlOutlineWidth: 0.5,
        },
      }}
    >
      <MessageLayout>
        <GlobalInjector>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
          </QueryClientProvider>
        </GlobalInjector>
      </MessageLayout>
    </ConfigProvider>
  </React.StrictMode>,
);
