import { CommonIcon, CommonIconType } from '@/common/components';
import { Facility } from '@/common/entities';

import styles from './facility-map.module.css';
import { useFacilityMap } from './use-facility-map';

interface Props {
  readonly facility: Facility;
  readonly onClose: () => void;
}

export const FacilityMap = ({ facility, onClose }: Props) => {
  const { mapWrapperId } = useFacilityMap(facility);

  return (
    <div className={styles.wrapper}>
      <button className={styles.closeButton} onClick={onClose}>
        <CommonIcon type={CommonIconType.Cross} className={styles.icon} />
        <span className={styles.label}>Закрыть карту</span>
      </button>
      <div id={mapWrapperId} className={styles.map} />
    </div>
  );
};
