import { FacilitySectionWrapper } from '../facility-section-wrapper';
import { FacilityTransactionCard } from '../facility-transaction-card';
import { useFacilityTransactionTermsSection } from './facility-transaction-terms-section.domain';
import styles from './facility-transaction-terms-section.module.css';
import { FacilityTransactionTermsSectionProps } from './facility-transaction-terms-section.types';

export const FacilityTransactionTermsSection = ({
  facility,
}: FacilityTransactionTermsSectionProps) => {
  const { contractType, agreementSum, paymentMethods, extraPayments } =
    useFacilityTransactionTermsSection(facility);

  return (
    <FacilitySectionWrapper>
      <div className={styles.content}>
        <h3 className={styles.title}>Возможности оформления и оплаты</h3>
        <div className={styles.transactionCardContainer}>
          <FacilityTransactionCard label="Оформление" value={contractType} />
          <FacilityTransactionCard
            label="Сумма договора"
            value={agreementSum}
          />
        </div>
        {paymentMethods ? (
          <FacilityTransactionCard label="Тип оплаты" value={paymentMethods} />
        ) : null}
        {extraPayments ? (
          <FacilityTransactionCard label="Доплаты" value={extraPayments} />
        ) : null}
      </div>
    </FacilitySectionWrapper>
  );
};
