import { RefillRequest } from '@/common/entities';

import {
  RefillRequestResponce,
  mapRefillRequestResponceToRefilRequest,
} from '../../mappers/refill-request.mapper';
import { request } from '../../request';

export const requestRefill = async (payload: {
  amount: number;
}): Promise<RefillRequest> => {
  const response = await request.post<{
    refill_request: RefillRequestResponce;
  }>(`/v1/users/me/refill-requests`, {
    amount: payload.amount,
  });

  const refillResponse = response.data.refill_request;

  return mapRefillRequestResponceToRefilRequest(refillResponse);
};
