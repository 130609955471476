/* eslint-disable no-nested-ternary */
import { observer } from 'mobx-react-lite';
import { v4 as uuidv4 } from 'uuid';

import { CommonErrorPlaceholder } from '@/common/components';

import { FacilityCard, RoundHeroCard } from '..';
import { useDraftFacilityListView } from './draft-facility-list-view.domain';
import styles from './draft-facility-list-view.module.css';
import { DraftFacilityListViewProps } from './draft-facility-list-view.types';

export const DraftFacilitiesListView = observer(
  ({
    facilities,
    facilitiesAreLoading,
    facilitiesHaveError,
  }: DraftFacilityListViewProps) => {
    const { rounds, roundsAreEmpty, roundsHaveError, roundsAreLoading } =
      useDraftFacilityListView({
        facilities,
        facilitiesAreLoading,
        facilitiesHaveError,
      });

    if (roundsAreEmpty) {
      return null;
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.header}>
            <h1 className={styles.title}>Предстоящие объекты</h1>
          </div>
          <div>
            {roundsAreLoading ? (
              <div className={styles.placeholderWrapper}>
                <div className={styles.facilitiesList}>
                  {Array.from({ length: 6 }).map(() => (
                    <FacilityCard.Skeleton key={uuidv4()} />
                  ))}
                </div>
              </div>
            ) : roundsHaveError ? (
              <div className={styles.placeholderWrapper}>
                <CommonErrorPlaceholder />
              </div>
            ) : (
              !roundsAreLoading &&
              !roundsHaveError && (
                <div>
                  <div className={styles.heroList}>
                    {rounds.map((round) => (
                      <RoundHeroCard round={round} key={round.id} />
                    ))}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    );
  },
);
