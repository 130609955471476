import { CommonButton, CommonModal } from '@/common/components';

import styles from './become-partner-modal.module.css';
import { BecomePartnerModalProps } from './become-partner-modal.types';

export const BecomePartnerModal = ({
  isOpen,
  onClose,
}: BecomePartnerModalProps) => {
  return (
    <CommonModal
      modalClassName={styles.modalCustom}
      title="Станьте партнером"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.step}>
            <div className={styles.stepDetails}>
              <p className={styles.stepTitle}>Напишите менеджеру</p>
              <p className={styles.stepText}>
                Партнером может стать не каждый, отправьте заяку менеджеру и
                решение поступит в течении 24 часов
              </p>
            </div>
            <div className={styles.stepNumber}>1</div>
          </div>
          <div className={styles.step}>
            <div className={styles.stepDetails}>
              <p className={styles.stepTitle}>Получите подтверждение</p>
              <p className={styles.stepText}>
                После одобрения, на странице партнерской программы появиться
                ваша реферальная ссылка и подключенные вами пользователи
              </p>
            </div>
            <div className={styles.stepNumber}>2</div>
          </div>
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <a
          href="https://wa.me/79992334461?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%2C%20%D1%85%D0%BE%D1%87%D1%83%20%D1%81%D1%82%D0%B0%D1%82%D1%8C%20%D0%BF%D0%B0%D1%80%D1%82%D0%BD%D0%B5%D1%80%D0%BE%D0%BC%20%D0%B0%D1%83%D0%BA%D1%86%D0%B8%D0%BE%D0%BD%D0%B0%20MinoTower%2C%20%D0%B1%D1%83%D0%B4%D1%83%20%D0%BF%D1%80%D0%B8%D0%B3%D0%BB%D0%B0%D1%88%D0%B0%D1%82%D1%8C%20%D0%BA%D0%BB%D0%B8%D0%B5%D0%BD%D1%82%D0%BE%D0%B2%20%D0%B8%20%D0%B4%D1%80%D1%83%D0%B7%D0%B5%D0%B9"
          target="_blank"
          rel="noreferrer"
        >
          <CommonButton className={styles.button} onClick={onClose}>
            Написать менеджеру
          </CommonButton>
        </a>
      </div>
    </CommonModal>
  );
};
