import { useQuery } from '@tanstack/react-query';
import { useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getOneFacility } from '@/common/api/requests';
import { Breadcrumb } from '@/common/components/breadcrumbs';
import { paths } from '@/common/constants';
import { Facility } from '@/common/entities';
import { UserStoreContext } from '@/common/stores';
import { getFormattedCurrency } from '@/common/utils';

export const useDetailFacilityView = (facilityId: string) => {
  const userStore = useContext(UserStoreContext);
  const location = useLocation();

  const {
    data: facility,
    isPending,
    isError,
    isFetching,
    refetch,
  } = useQuery<Facility>({
    queryKey: ['facility', facilityId],
    queryFn: () => getOneFacility(facilityId),
    refetchInterval: 30 * 1000,
    enabled: Boolean(facilityId),
    refetchIntervalInBackground: false,
  });

  const facilityHasError = isError && !isPending;
  const facilityIsLoading = isPending;
  const facilityIsFetching = isFetching;

  const isMyBetTheHighest = useMemo(() => {
    if (facility?.bets?.length === 0) return false;
    return facility?.bets?.[0].userId === userStore.user?.id;
  }, [facility, userStore.user]);

  const betStep = facility?.betStep ?? 0;
  const minBet = facility?.minBet ?? 0;

  const minNewBet = useMemo(() => {
    if (facility?.bet !== undefined && facility.bet !== 0) {
      return getFormattedCurrency(facility.bet + betStep, true);
    }
    return getFormattedCurrency(minBet + betStep, true);
  }, [facility?.bet, betStep, minBet]);

  const showOutbidButton = useMemo(() => {
    if (!facility?.bets?.length) return false;

    const sorted = [...facility.bets].sort(
      (firstBet, secondBet) => (secondBet.amount ?? 0) - (firstBet.amount ?? 0),
    );

    const myBet = sorted.find((bet) => bet.userId === userStore.user?.id);

    return Boolean(myBet && facility?.bet && myBet.amount < facility.bet);
  }, [facility, userStore.user]);

  const from = location.state?.from || paths.getHomePath();

  const breadcrumbs = useMemo<Breadcrumb[]>(() => {
    let baseBreadcrumb: Breadcrumb = {
      label: 'Главная',
      path: paths.getHomePath(),
    };

    if (from === paths.getProfilePath())
      baseBreadcrumb = { label: 'Профиль', path: paths.getProfilePath() };

    if (from.includes('/rounds'))
      baseBreadcrumb = { label: 'Жилой комплекс', path: from };

    return [
      baseBreadcrumb,
      {
        label: `Лот ${facility?.name}`,
        path: location.pathname,
      },
    ];
  }, [from, facility?.name, location.pathname]);

  const [showContactOptions, setShowContactOptions] = useState(false);
  const handleCloseContactOptions = () => setShowContactOptions(false);

  return {
    facility,
    facilityHasError,
    facilityIsLoading,
    facilityIsFetching,
    isMyBetTheHighest,
    minNewBet,
    breadcrumbs,
    showContactOptions,
    handleCloseContactOptions,
    showOutbidButton,
    setShowContactOptions,
    refetch,
  };
};
