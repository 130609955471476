import { useMemo } from 'react';

import { Round } from '@/common/entities';
import { getFormattedCurrency } from '@/common/utils';

export const useRoundHeroCard = (round: Round) => {
  const facilities = useMemo(() => round.facilities ?? [], [round.facilities]);
  const firstFacility = useMemo(() => facilities[0], [facilities]);

  const mainImage = useMemo(
    () => round.staticObject?.path ?? '',
    [round.staticObject?.path],
  );

  const title = useMemo(() => round.name, [round.name]);

  const districtName = useMemo(
    () => round.district?.name ?? '',
    [round.district?.name],
  );

  const description = useMemo(
    () => round.description ?? '',
    [round.description],
  );

  const betsStartsFrom = useMemo(() => {
    if (!facilities.length) return '';
    return getFormattedCurrency(
      facilities.reduce(
        (minBet, item) => Math.min(minBet, item.minBet ?? Infinity),
        Infinity,
      ),
      true,
    );
  }, [facilities]);

  const facilitySchemes = useMemo(() => {
    if (!facilities.length) return [];

    return facilities.slice(0, 5).map((facility) => {
      const schemas = (facility.photos ?? [])
        .filter((photo) => photo.isSchema)
        .sort((photoA, photoB) => (photoA.order ?? 0) - (photoB.order ?? 0));

      const schemaPath =
        schemas[0]?.photo.path ||
        facility.photos?.find((photo) => photo.isSchema)?.photo.path;

      return {
        id: facility.id,
        path: schemaPath || undefined,
        area: `${facility.areaM2} м²`,
      };
    });
  }, [facilities]);

  const startsAt = useMemo(() => {
    if (!facilities.length) return '';

    const earliest = facilities.reduce((minTime, item) => {
      const time = new Date(item.startsAt).getTime();
      return time < minTime ? time : minTime;
    }, Infinity);

    if (earliest === Infinity) return '';

    const date = new Date(earliest);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${day}.${month}.${year}`;
  }, [facilities]);

  const numberOfFacilities = useMemo(() => {
    if (!facilities.length) return '0 лотов';
    if (facilities.length >= 5) return `${facilities.length} лотов`;
    if (facilities.length === 1) return '1 лот';
    return `${facilities.length} лота`;
  }, [facilities]);

  const roundLink = `/rounds/${round.id}`;

  return {
    mainImage,
    title,
    betsStartsFrom,
    roundLink,
    firstFacility,
    districtName,
    facilitySchemes,
    startsAt,
    numberOfFacilities,
    description,
  };
};
