import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Breadcrumb, Breadcrumbs } from '@/common/components/breadcrumbs';
import { paths } from '@/common/constants';
import { useUser } from '@/common/hooks/use-user';
import { UserStoreContext } from '@/common/stores';

import {
  BecomePartnerModal,
  PartnerProgramDescriptionSection,
  PartnerProgramNewsChannel,
  PartnerProgramReferralLinkSection,
  PartnerProgramReferredUsersSection,
} from '../../components';
import { useBecomePartnerModal } from '../../components/become-partner-modal/become-partner-modal.domain';
import styles from './partner-program-view.module.css';

export const PartnerProgramView = observer(() => {
  const location = useLocation();
  const userStore = useContext(UserStoreContext);
  const { refetch: refetchUser } = useUser();

  const isAuthenticated = userStore.isAuthenticated;

  useEffect(() => {
    const fetchData = async () => {
      await refetchUser();

      if (userStore.user?.referrerUser) {
        userStore.loadReferredUsers(userStore.user.referrerUser.id);
      }
    };

    if (isAuthenticated) fetchData();
  }, [isAuthenticated, userStore, refetchUser]);

  const referredUsersAreEmpty =
    userStore.referredUsers.length === 0 &&
    !userStore.isReferredUsersLoading &&
    !userStore.hasReferredUsersError;

  const from = location.state?.from || paths.getHomePath();

  let baseBreadcrumb: Breadcrumb = {
    label: 'Главная',
    path: paths.getHomePath(),
  };

  if (from === paths.getProfilePath())
    baseBreadcrumb = { label: 'Профиль', path: paths.getProfilePath() };

  if (from === paths.getLandingPath())
    baseBreadcrumb = { label: 'Главная', path: paths.getLandingPath() };

  const breadcrumbs: Breadcrumb[] = [
    baseBreadcrumb,
    {
      label: `Партнерская программа`,
      path: paths.getPatnerProgramPath(),
    },
  ];

  const isUserReferrer = Boolean(userStore.user?.referrerUser);

  const {
    isBecomePartnerModalOpen,
    handleBecomePartnerModalClose,
    handleBecomePartnerModalOpen,
  } = useBecomePartnerModal();

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <BecomePartnerModal
          isOpen={isBecomePartnerModalOpen}
          onClose={handleBecomePartnerModalClose}
        />
        <Breadcrumbs
          loading={false}
          breadcrumbs={breadcrumbs}
          className={styles.breadcrumbs}
        />
        <PartnerProgramDescriptionSection
          isButtonVisible={!isUserReferrer || !isAuthenticated}
          onButtonClick={handleBecomePartnerModalOpen}
        />
        {isAuthenticated && isUserReferrer ? (
          <PartnerProgramReferralLinkSection user={userStore.user} />
        ) : null}
        {isAuthenticated && isUserReferrer ? (
          <PartnerProgramNewsChannel />
        ) : null}
        {isAuthenticated && isUserReferrer && !referredUsersAreEmpty ? (
          <PartnerProgramReferredUsersSection
            referredUsers={userStore.referredUsers}
          />
        ) : null}
      </div>
    </div>
  );
});
