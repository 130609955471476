import { FacilityLinkCard } from '../facility-link-card';
import { FacilitySectionWrapper } from '../facility-section-wrapper';
import styles from './facility-links-section.module.css';
import { FacilityLinksSectionProps } from './facility-links-section.types';

export const FacilityLinksSection = ({
  facility,
}: FacilityLinksSectionProps) => {
  return (
    <FacilitySectionWrapper>
      <div className={styles.content}>
        <h3 className={styles.title}>Сравнительный анализ</h3>
        <div className={styles.linkContainer}>
          {facility.links
            ? facility.links.map((link) => (
                <FacilityLinkCard link={link} key={link.id} />
              ))
            : null}
        </div>
      </div>
    </FacilitySectionWrapper>
  );
};
