import { PaginationMeta } from '@/common/entities';

export interface PaginationMetaResponse {
  page: number;
  per_page: number;
  total: number;
}

export const mapPaginationMetaResponseToPaginationMeta = (
  payload: PaginationMetaResponse,
): PaginationMeta => ({
  page: payload.page,
  perPage: payload.per_page,
  total: payload.total,
});
