import { useMemo } from 'react';

import { CommonIconType } from '@/common/components';
import { FacilityLinkContentType } from '@/common/constants';
import { FacilityLink } from '@/common/entities';

export const useFacilityLinkCard = (link: FacilityLink) => {
  const showIconType = useMemo(() => {
    const linkIconTypeNames: Record<FacilityLinkContentType, CommonIconType> = {
      [FacilityLinkContentType.OtherLots]: CommonIconType.Table,
      [FacilityLinkContentType.Resales]: CommonIconType.WindowOpen,
      [FacilityLinkContentType.Competitors]: CommonIconType.Buildings,
      [FacilityLinkContentType.Documents]: CommonIconType.Document,
    };

    if (link && link.contentType !== null) {
      const iconType =
        linkIconTypeNames[link.contentType as FacilityLinkContentType];

      return iconType || CommonIconType.Table;
    }
    return CommonIconType.Table;
  }, [link]);

  return {
    showIconType,
  };
};
