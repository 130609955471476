export const getFormattedCurrency = (value: number, displayFull = false) => {
  if (value / 1_000_000_000_000 >= 1 && !displayFull) {
    return `${new Intl.NumberFormat('ru-RU').format(value / 1000000000000)} трлн ₽`;
  }

  if (value / 1_000_000_000 >= 1 && !displayFull) {
    return `${new Intl.NumberFormat('ru-RU').format(value / 1000000000)} млрд ₽`;
  }

  if (value / 1_000_000_000 >= 1 && !displayFull) {
    return `${new Intl.NumberFormat('ru-RU').format(value / 1000000000)} млрд ₽`;
  }

  if (value / 1_000_000 >= 1 && !displayFull) {
    return `${new Intl.NumberFormat('ru-RU').format(value / 1000000)} млн ₽`;
  }

  return `${new Intl.NumberFormat('ru-RU').format(value)} ₽`;
};
