/* eslint-disable camelcase */
import { request } from '../../request';
import { CreateEventDto } from './dto';

export const createEvent = async (payload: CreateEventDto): Promise<void> => {
  await request.post('/v1/events', {
    id: payload.id,
    code: payload.code,
    facility_id: payload.facilityId,
  });
};
