import { useState } from 'react';

export enum ModalContent {
  Explanation = 'explanation',
  Form = 'form',
  Success = 'success',
}

export const useCommonHeaderRefillRequestModal = () => {
  const [
    isCommonHeaderRefillRequestModalOpen,
    setIsCommonHeaderRefillRequestModalOpen,
  ] = useState(false);

  const [
    commonHeaderRefillRequestModalContent,
    setCommonHeaderRefillRequestModalContent,
  ] = useState<ModalContent>(ModalContent.Explanation);

  const handleCommonHeaderRefillRequestModalClose = () => {
    setIsCommonHeaderRefillRequestModalOpen(false);
  };

  const handleCommonHeaderRefillRequestModalOpen = () => {
    setCommonHeaderRefillRequestModalContent(ModalContent.Explanation);
    setIsCommonHeaderRefillRequestModalOpen(true);
  };

  const changeCommonHeaderRefillRequestModalContentToForm = () => {
    setCommonHeaderRefillRequestModalContent(ModalContent.Form);
  };

  const changeCommonHeaderRefillRequestModalContentToSuccess = () => {
    setCommonHeaderRefillRequestModalContent(ModalContent.Success);
  };

  return {
    isCommonHeaderRefillRequestModalOpen,
    handleCommonHeaderRefillRequestModalClose,
    handleCommonHeaderRefillRequestModalOpen,
    commonHeaderRefillRequestModalContent,
    changeCommonHeaderRefillRequestModalContentToForm,
    changeCommonHeaderRefillRequestModalContentToSuccess,
  };
};
