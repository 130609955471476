import { Round } from '@/common/entities';

import { RoundResponse } from '../responses';
import { mapFacilityResponseToFacility } from './facilities.mapper';

export const mapRoundResponseToRound = (round: RoundResponse): Round => {
  return {
    id: round.id,
    districtId: round.district_id,
    staticObjectId: round.static_object_id,
    name: round.name,
    description: round.description,
    class: round.class,
    isCommissioned: round.is_commissioned,
    commissioningYear: round.commissioning_year,
    commissioningQuarter: round.commissioning_quarter,
    contractType: round.contract_type,
    publishedAt: round.published_at,
    district: round.district,
    staticObject: round.static_object,
    facilities: round.facilities?.map((facility) =>
      mapFacilityResponseToFacility(facility),
    ),
    createdAt: round.created_at,
    updatedAt: round.updated_at,
    deletedAt: round.deleted_at,
  };
};
