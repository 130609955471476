import { PaginationMeta, User } from '@/common/entities';

import {
  PaginationMetaResponse,
  mapPaginationMetaResponseToPaginationMeta,
  mapUserResponseToUser,
} from '../../mappers';
import { request } from '../../request';
import { UserResponse } from '../../responses';

export const getReferredUsers = async (
  referrerId: string,
  page = 1,
): Promise<[User[], PaginationMeta]> => {
  const response = await request.get<{
    meta: PaginationMetaResponse;
    referred_users: UserResponse[];
  }>(`/v1/referrers/${referrerId}/users`, {
    params: {
      page,
    },
  });

  const paginationMeta = mapPaginationMetaResponseToPaginationMeta(
    response.data?.meta,
  );

  const referredUsers = response.data?.referred_users.map((user) =>
    mapUserResponseToUser(user),
  );

  return [referredUsers, paginationMeta];
};
