import { Alert, Pagination, Spin, Table } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { useEffect, useState } from 'react';

import { PartnerProgramSectionWrapper } from '../partner-program-section-wrapper';
import { CustomEmpty } from './components/custom-empty';
import { usePartnerEventsActivitySection } from './partner-events-activity-section.domain';
import styles from './partner-events-activity-section.module.css';

export const PartnerEventsActivitySection = () => {
  const {
    bets,
    columns,
    loading,
    isFetching,
    isError,
    paginationMeta,
    handleTableChange,
  } = usePartnerEventsActivitySection();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    checkIfMobile();

    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const handlePaginationChange = (page: number) => {
    const paginationConfig: TablePaginationConfig = { current: page };
    handleTableChange(paginationConfig);
  };

  const currentPage = paginationMeta?.page || 1;
  const pageSize = paginationMeta?.perPage || 10;
  const total = paginationMeta?.total || 0;

  return (
    <PartnerProgramSectionWrapper>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>События</h2>

        {Boolean(isError) && (
          <Alert
            message="Ошибка"
            description="Не удалось загрузить данные о ставках. Пожалуйста, попробуйте позже."
            type="error"
            showIcon
            style={{ marginBottom: 16 }}
          />
        )}

        <div className={styles.tableContainer}>
          <Spin spinning={Boolean(isFetching && !loading)}>
            <Table
              columns={columns}
              dataSource={bets}
              loading={loading}
              pagination={
                isMobile
                  ? false
                  : {
                      current: currentPage,
                      pageSize,
                      total,
                      showSizeChanger: false,
                      size: 'default',
                    }
              }
              onChange={handleTableChange}
              locale={{
                emptyText: loading ? (
                  <div style={{ height: 600 }} />
                ) : (
                  <CustomEmpty />
                ),
              }}
              size={isMobile ? 'small' : 'middle'}
            />
          </Spin>
        </div>

        {Boolean(isMobile) && (
          <div className={styles.paginationContainer}>
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={total}
              onChange={handlePaginationChange}
              size="small"
              showSizeChanger={false}
            />
          </div>
        )}
      </div>
    </PartnerProgramSectionWrapper>
  );
};
