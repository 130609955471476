import { Tag } from 'antd';
import { useMemo } from 'react';

import { FacilityClass, FacilityContractType } from '@/common/constants';

import styles from './common-facility-tags.module.css';
import { CommonFacilityTagsProps } from './common-facility-tags.types';

export const CommonFacilityTags = ({ facility }: CommonFacilityTagsProps) => {
  const comissionDate = useMemo(() => {
    if (facility.isCommissioned) {
      return 'Сдан';
    }

    const comissionDate = `Срок сдачи ${facility.commissioningQuarter} / ${facility.commissioningYear}`;

    if (facility.commissioningQuarter && facility.commissioningYear) {
      return comissionDate;
    }

    return null;
  }, [facility]);

  const contractType = useMemo(() => {
    const contractTypeNames = {
      [FacilityContractType.PurchaseAndSaleAgreement]: 'ДКП',
      [FacilityContractType.SharedParticipationAgreement]: 'ДДУ',
      [FacilityContractType.Miscellaneous]: 'Другое',
    };

    if (facility && facility.contractType !== null) {
      const contractTypeName = contractTypeNames[facility.contractType];
      return contractTypeName || null;
    }

    return null;
  }, [facility]);

  const facilityClass = useMemo(() => {
    const facilityClassNames = {
      [FacilityClass.Economy]: 'Эконом-класс',
      [FacilityClass.Business]: 'Бизнес-класс',
      [FacilityClass.Elite]: 'Элит-класс',
      [FacilityClass.Comfort]: 'Комфорт-класс',
    };

    if (facility && facility.class !== null) {
      const facilityClassName = facilityClassNames[facility.class];
      return facilityClassName;
    }

    return null;
  }, [facility]);

  return (
    <div>
      {facilityClass ? (
        <Tag color="cyan" className={styles.tag}>
          {facilityClass}
        </Tag>
      ) : null}
      {contractType ? (
        <Tag color="purple" className={styles.tag}>
          {contractType}
        </Tag>
      ) : null}
      {comissionDate ? (
        <Tag
          color={facility.isCommissioned ? 'green' : 'orange'}
          className={styles.tag}
        >
          {comissionDate}
        </Tag>
      ) : null}
    </div>
  );
};
