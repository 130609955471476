import { Form } from 'antd';
import FormItem from 'antd/es/form/FormItem';

import {
  CommonButton,
  CommonIcon,
  CommonIconType,
  CommonInput,
  CommonModal,
} from '@/common/components';
import { CommonSuccessModalContent } from '@/common/components/common-success-modal-content/common-success-modal-content';
import { getFormattedCurrency } from '@/common/utils';

import { BetOnFacilityModalContent } from './bet-on-facility-modal.domain';
import styles from './bet-on-facility-modal.module.css';
import { BetOnFacilityModalProps } from './bet-on-facility-modal.types';

export const BetOnFacilityModal = ({
  isOpen = false,
  onClose,
  onSubmit,
  isSubmitLoading = false,
  facility,
  modalContent = BetOnFacilityModalContent.Form,
}: BetOnFacilityModalProps) => {
  const handleSubmit = (values: { amount: string }) => {
    const rawAmount = Number(values.amount.replace(/\s/gu, ''));
    if (onSubmit) {
      onSubmit({
        ...values,
        amount: rawAmount,
      });
    }
  };

  return (
    <CommonModal title="Совершение ставки" onClose={onClose} isOpen={isOpen}>
      {modalContent === BetOnFacilityModalContent.Form && (
        <div className={styles.container}>
          <div className={styles.detailContainer}>
            <div className={styles.currentBetSection}>
              <div className={styles.currentBetLabel}>
                <CommonIcon
                  type={CommonIconType.CoinStack}
                  className={styles.icon}
                />
                <span>Текущая ставка</span>
              </div>
              <div className={styles.currentBetValue}>
                <span>
                  {getFormattedCurrency(
                    facility.bet === 0
                      ? (facility.minBet ?? 0)
                      : (facility.bet ?? 0),
                    true,
                  )}
                </span>
                <span className={styles.betStep}>
                  Шаг ставки: {getFormattedCurrency(facility.betStep, true)}
                </span>
              </div>
            </div>
          </div>
          <Form
            layout="vertical"
            className={styles.form}
            onFinish={handleSubmit}
            noValidate
          >
            <FormItem
              label="Ваша ставка"
              name="amount"
              rules={[
                { required: true, message: 'Введите сумму ставки' },
                () => ({
                  validator(__, value) {
                    const parsedValue = Number(value.replace(/\s/gu, ''));
                    const minBet =
                      facility.bet && facility.bet > 0
                        ? facility.bet
                        : (facility.minBet ?? 0);
                    const betStep = facility.betStep || 1;

                    if (parsedValue < minBet + betStep) {
                      return Promise.reject(
                        new Error(
                          `Ставка не может быть меньше ${getFormattedCurrency(minBet + betStep, true)}`,
                        ),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <CommonInput
                className={styles.input}
                placeholder="Введите сумму ставки"
                type="text"
                mask={Number}
                radix=","
                mapToRadix={['.']}
                thousandsSeparator=" "
                scale={0}
                inputMode="numeric"
              />
            </FormItem>
            <CommonButton
              className={styles.submitButton}
              type="submit"
              isLoading={isSubmitLoading}
            >
              Сделать ставку
            </CommonButton>
          </Form>
        </div>
      )}

      {modalContent === BetOnFacilityModalContent.Success && (
        <div className={styles.successContainer}>
          <CommonSuccessModalContent
            title="Вы успешно сделали ставку!"
            text="Персональный менеджер оповестит вас в случае обновления ставки, а текущий статус отображается в личном кабинете"
            onClose={onClose}
          />
        </div>
      )}
    </CommonModal>
  );
};
