import { useCallback, useContext, useRef } from 'react';

import { FacilitiesStoreContext } from '../../stores';

export const useFacilityTimer = (facilitiesAreFetching: boolean) => {
  const facilitiesStore = useContext(FacilitiesStoreContext);
  const updateTimeoutRef = useRef<NodeJS.Timeout>();

  const handleFacilityStatusUpdate = useCallback(() => {
    if (updateTimeoutRef.current) {
      clearTimeout(updateTimeoutRef.current);
    }

    if (!facilitiesAreFetching) {
      updateTimeoutRef.current = setTimeout(() => {
        facilitiesStore.loadFacilities();
      }, 1000);
    }
  }, [facilitiesAreFetching, facilitiesStore]);

  return {
    handleFacilityStatusUpdate,
  };
};
