import classNames from 'classnames';
import { memo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link, useLocation } from 'react-router-dom';

import {
  CommonButton,
  CommonFacilityTags,
  CommonIcon,
  CommonIconType,
  CommonTimer,
} from '@/common/components';
import { FacilityStatus } from '@/common/constants';

import { FacilityCardButton, FacilityCardSkeleton } from './components';
import styles from './facility-card.module.css';
import { useFacilityCard } from './use-facility-card-properties';
import { useFacilityTimer } from './use-facility-timer';
import { FacilityCardProps } from './use-facility.types';

const FacilityCardComponent = ({
  facility,
  facilitiesAreFetching = false,
}: FacilityCardProps) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [schemaLoaded, setSchemaLoaded] = useState(false);

  const {
    locationPropertyContent,
    showFacilityType,
    areaM2PropertyContent,
    showMaxBetPropertyContent,
    showFacilityImage,
    showFacilitySchema,
    showBetTitle,
    showMyBet,
    isMyBetTheHighest,
  } = useFacilityCard(facility);

  const { handleFacilityStatusUpdate } = useFacilityTimer(
    facilitiesAreFetching,
  );

  const location = useLocation();
  const facilityLink = `/facilities/${facility.id}`;

  return (
    <Link to={facilityLink} state={{ from: location.pathname }}>
      <div className={styles.card}>
        <div className={styles.cardImage}>
          <div
            className={classNames(styles.imageWrapper, {
              [styles.imageLoaded]: imageLoaded,
            })}
          >
            <img
              src={showFacilityImage}
              loading="lazy"
              onLoad={() => setImageLoaded(true)}
              alt={showFacilityType}
            />
            {!imageLoaded && <Skeleton className={styles.imageSkeleton} />}
          </div>
          <CommonTimer
            updateFacilityStatus={handleFacilityStatusUpdate}
            facility={facility}
            timerClassName={styles.timer}
          />
          <div className={styles.location}>
            <CommonIcon type={CommonIconType.Map} className={styles.icon} />{' '}
            <span className={styles.locationText}>
              {locationPropertyContent}
            </span>
          </div>
        </div>
        <div className={styles.cardContent}>
          <div className={styles.details}>
            {showFacilitySchema ? (
              <div className={styles.scheme}>
                <div
                  className={classNames(styles.schemaWrapper, {
                    [styles.schemaLoaded]: schemaLoaded,
                  })}
                >
                  <img
                    src={showFacilitySchema}
                    loading="lazy"
                    className={styles.schemeImage}
                    onLoad={() => setSchemaLoaded(true)}
                    alt="Facility schema"
                  />
                  {!schemaLoaded && (
                    <Skeleton className={styles.schemaSkeleton} />
                  )}
                </div>
              </div>
            ) : null}
            <div className={styles.textDetails}>
              <h2
                className={styles.title}
              >{`${showFacilityType}, ${areaM2PropertyContent}`}</h2>
              <div className={styles.badges}>
                <CommonFacilityTags facility={facility} />
              </div>
            </div>
          </div>
          {facility.status !== FacilityStatus.Completed ? (
            <>
              <div className={styles.myBetSection}>
                {facilitiesAreFetching ? (
                  <div className={styles.myBetSkeletonWrapper}>
                    <Skeleton className={styles.myBetSkeleton} />
                  </div>
                ) : null}
                {showMyBet && !facilitiesAreFetching ? (
                  <div className={styles.myBet}>
                    <span className={styles.myBetTitle}>Ваша ставка</span>
                    <span
                      className={classNames(
                        styles.myBetValue,
                        isMyBetTheHighest && styles.myBetValueHighest,
                      )}
                    >
                      {showMyBet}
                    </span>
                  </div>
                ) : null}
              </div>
              <div className={styles.priceSection}>
                <span className={styles.priceLabel}>{showBetTitle}</span>
                {facilitiesAreFetching ? (
                  <div className={styles.betSkeletonWrapper}>
                    <Skeleton className={styles.betSkeleton} />
                  </div>
                ) : (
                  <span className={styles.price}>
                    {showMaxBetPropertyContent}
                  </span>
                )}
              </div>
              {!showMyBet || isMyBetTheHighest ? (
                <CommonButton className={styles.moreButton}>
                  Узнать подробнее
                </CommonButton>
              ) : (
                <FacilityCardButton className={styles.moreButton}>
                  Перебить
                </FacilityCardButton>
              )}
            </>
          ) : (
            <div className={styles.completedText}>Торги завершены</div>
          )}
        </div>
      </div>
    </Link>
  );
};

FacilityCardComponent.displayName = 'FacilityCard';

const MemoizedFacilityCard = memo(FacilityCardComponent);
MemoizedFacilityCard.displayName = 'FacilityCard';

export const FacilityCard = Object.assign(MemoizedFacilityCard, {
  Skeleton: FacilityCardSkeleton,
});
