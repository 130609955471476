/* eslint-disable camelcase */
import { User } from '@/common/entities';

import { mapUserResponseToUser } from '../../mappers';
import { request } from '../../request';
import { UserResponse } from '../../responses';

export interface UserGeneralInfoProps {
  firstName: string;
  lastName: string;
  email: string | null;
}

export const updateUser = async (
  payload: UserGeneralInfoProps,
): Promise<User> => {
  const response = await request.patch<{
    user: UserResponse;
  }>(`/v1/users/me`, {
    first_name: payload.firstName,
    last_name: payload.lastName,
    email: payload.email,
  });

  const userResponce = response.data.user;

  return mapUserResponseToUser(userResponce);
};
