/* eslint-disable react/no-unescaped-entities */
import { DocumentSection } from '../../components';
import styles from './user-agreement-view.module.css';

export const UserAgreementView = () => {
  return (
    <div className={styles.userAgreementView}>
      <h1 className={styles.title}>Пользовательское соглашение</h1>
      <DocumentSection>
        <DocumentSection.Title>
          ПРЕДЛОЖЕНИЕ О ЗАКЛЮЧЕНИИ ДОГОВОРА НА САЙТЕ MINOTOWER.RU
        </DocumentSection.Title>
        <DocumentSection.Paragraph>
          г. Сочи, Российская Федерация 25 Ноября 2024 г.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          Настоящий документ представляет собой предложение Общества с
          ограниченной ответственностью «ЮГрупп» ОГРН: 1142366013910, ИНН/КПП:
          2320225543/236701001, заключить Договор на предоставление услуг по
          участию в аукционе по приобретению объектов недвижимости и
          приобретение объектов недвижимости в случае победы на аукционе и
          предоставлению доступа к электронному сервису, на сайте которого
          организуется АУКЦИОН.
        </DocumentSection.Paragraph>
      </DocumentSection>
      <DocumentSection>
        <DocumentSection.Title>1. ОПРЕДЕЛЕНИЯ И ТЕРМИНЫ</DocumentSection.Title>
        <DocumentSection.Paragraph>
          1.1. Для целей настоящего документа приведенные ниже «MinoTower»,
          содержащая сведения, которые УЧАСТНИК сообщает о себе компьютерной
          системе. АККРЕДИТАЦИЯ УЧАСТНИКА – организация доступа к участию в
          АУКЦИОНЕ. АКЦЕПТ – полное и безоговорочное принятие ОФЕРТЫ, в смысле
          статьи 438 Гражданского кодекса Российской Федерации, путем
          осуществления УЧАСТНИКОМ действий, указанных в разделе 5 ОФЕРТЫ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          АУКЦИОН – торги ЛОТАМИ на повышение цены продаваемых ЛОТОВ, проводимые
          в электронной форме на ЭЛЕКТРОННОЙ ПЛОЩАДКЕ в сети Интернет.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          БАЛАНС ЛИЦЕВОГО СЧЕТА – состояние лицевого счета, которое
          характеризуется как разница между суммой, внесенной УЧАСТНИКОМ на
          ЛИЦЕВОЙ СЧЕТ, и суммой, списанной ОРГАНИЗАТОРОМ в соответствии с
          условиями по оказанным услугам, переданному товару.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          УЧАСТНИК самостоятельно отслеживает баланс Лицевого счета.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ДЕПОЗИТ – обеспечительный платеж, дающий право УЧАСТНИКУ на
          приобретение ЛОТОВ, а также являющийся способом обеспечения выполнения
          обязательств УЧАСТНИКА, предусмотренных ДОГОВОРОМ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ДОГОВОР – АКЦЕПТ ОФЕРТЫ в смысле пункта 3 статьи 438 Гражданского
          кодекса РФ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ДЛИТЕЛЬНЫЙ АУКЦИОН - один из видов АУКЦИОНА, на котором представлены
          ЛОТЫ, находящиеся в собственности ОРГАНИЗАТОРА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ЛОТ – объект недвижимости, находящееся в собственности ОРГАНИЗАТОРА,
          либо в собственности физических и/или юридических лиц, представивших
          свое письменное согласие на размещение информации о принадлежащих им
          объектов недвижимости на ЭЛЕКТРОННОЙ ПЛОЩАДКЕ c целью их продажи,
          выставляемое на АУКЦИОНЕ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          АВТОМАТИЧЕСКОЕ СПИСАНИЕ — автоматическое списание денежных средств с
          Банковской карты УЧАСТНИКА АУКЦИОНА в пользу ОРГАНИЗАТОРА АУКЦИОНА за
          предоставляемый Продавцом доступ к АУКЦИОНУ по подписке исходя из
          выбранного тарифа.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ЛИЦЕВОЙ СЧЕТ (ЛС) – счет в личном кабинете, предназначенный для
          отражения операций по поступлению и списанию оплаты предоставленных
          УЧАСТНИКУ услуг, выполненных работ и иное. Лицевой счет имеет
          уникальный номер.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ЛИЦЕНЗИОННЫЙ ПЛАТЕЖ – ежемесячный обязательный платеж для доступа к
          АУКЦИОНУ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ОРГАНИЗАТОР – Общество с ограниченной ответственностью «ЮГрупп» (ОГРН
          1142366013910, ИНН 2320225543), которое предоставляет услуги по
          участию в АУКЦИОНЕ по приобретению объектов недвижимости и
          приобретение объектов недвижимости в случае победы на АУКЦИОНЕ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ОСНОВАНИЕ ДЛЯ НАЧИСЛЕНИЯ «MinoTowerCoin» – перечень
          операций/действий/событий, названных в Оферте в качестве оснований для
          увеличения количества «MinoTowerCoin» в электронном кошельке
          УЧАСТНИКА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ОСНОВАНИЕ ДЛЯ СПИСАНИЯ «MinoTowerCoin» – перечень
          операций/действий/событий, названных в Оферте в качестве оснований для
          уменьшения количества «MinoTowerCoin» в электронном кошельке
          УЧАСТНИКА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ОФЕРТА – настоящее предложение о заключении договора на сайте на
          предоставление услуг по участию в АУКЦИОНЕ по приобретению объектов
          недвижимости и приобретение объектов недвижимости в случае победы на
          АУКЦИОНЕ, размещенное в сети Интернет по адресу
          https://minotower.ru/privacy и являющееся офертой в смысле статьи 435
          Гражданского Кодекса Российской Федерации.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ПЕРИОД ПРОВЕДЕНИЯ АУКЦИОНА – назначаемый ОРГАНИЗАТОРОМ период времени
          с момента начала до момента окончания АУКЦИОНА и определения
          победителя АУКЦИОНА по определенному ЛОТУ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ – обязательный для исполнения документ,
          устанавливающий порядок работы с конфиденциальной информацией.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ПОБЕДИТЕЛЬ АУКЦИОНА – УЧАСТНИК АУКЦИОНА, предложивший максимальную
          цену за ЛОТ на момент окончания ПЕРИОДА ПРОВЕДЕНИЯ АУКЦИОНА и
          получивший право приобретения ЛОТА в собственность.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ПОДПИСКА - возможность доступа к АУКЦИОНУ в зависимости от выбранного
          тарифа в течение срока подписки.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ПРАВИЛА РАССМОТРЕНИЯ ПРЕТЕНЗИЙ – документ, определяющий порядок
          рассмотрения претензий, размещенный в сети Интернет по адресу
          https://minotower.ru/documents/user-agreement
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ПРЕДСТАВИТЕЛЬ ОРГАНИЗАТОРА – уполномоченное физическое лицо,
          представляющее ОРГАНИЗАТОРА по любым вопросам, связанным с проведением
          АУКЦИОНА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          СРОК ПОДПИСКИ – период, начинающийся с момента оплаты стоимости
          Подписки УЧАСТНИКОМ АУКЦИОНА до момента ее отмены самим УЧАСТНИКОМ
          АУКЦИОНА в порядке, предусмотренном п.4.9.1.2.2 настоящего Соглашения,
          или до момента исключения ОРГАНИЗАТОРОМ АУКЦИОНА соответствующего
          раздела из ОФЕРТЫ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ЭЛЕКТРОННЫЙ КОШЕЛЕК - программа, расположенная в личном аккаунте
          УЧАСТНИКА, и позволяющая УЧАСТНИКУ хранить денежные средства и
          «MinoTowerCoin», а также производить с их помощью расчеты.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          MinoTowerCoin (МинотаврКоин) (crc) – условная вымышленная валюта,
          которая начисляется ее УЧАСТНИКУ за участие в Программах и Акциях,
          проводимых ОРГАНИЗАТОРОМ и дающая возможность ее использования в
          качестве скидки на услуги/товары ОРГАНИЗАТОРА на условиях и в размере,
          указанных в соответствующих ПРАВИЛАХ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          Курс MinoTowerCoin: 1crc = 1 рубль.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          В отношении предоставления доступа к АУКЦИОНУ способом Подписка
          применяются положения ст. 429.4. Гражданского кодекса РФ,
          квалифицирующие положения настоящего соглашения о Подписке как договор
          с исполнением по требованию (абонентский договор).
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          Неиспользование Подписки УЧАСТНИКОМ АУКЦИОНА, в том числе отсутствие
          ставок/просмотров при проведении АУКЦИОНА в рамках Подписки, не
          означает неоказание соответствующей Услуги ОРГАНИЗАТОРОМ АУКЦИОНА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          СТАВКА – ценовое предложение УЧАСТНИКА АУКЦИОНА по покупке ЛОТА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          СТАТУС АККАУНТА – автоматически присваиваемый УЧАСТНИКУ тип статуса,
          определяемый в зависимости от условий взаимодействия.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          УЧАСТНИК – физическое или юридическое лицо, прошедшее аккредитацию
          УЧАСТНИКА и которому присвоен личный АККАУНТ, тем самым получившее
          возможность участия в АУКЦИОНЕ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ХОЛДИРОВАНИЕ – удержание/блокировка денежных средств при проведении
          безналичной оплаты какого-либо товара или услуги.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ЦЕНА ЛОТА – стоимость ЛОТА, которая определяется в российских рублях,
          с учетом налогов, по максимальной СТАВКЕ, предложенной УЧАСТНИКОМ,
          которая подлежит оплате ПОБЕДИТЕЛЕМ АУКЦИОНА за покупку ЛОТА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ШТРАФ – способ обеспечения обязательств, не исполненных УЧАСТНИКОМ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ЭЛЕКТРОННАЯ ПЛОЩАДКА – сайт ОРГАНИЗАТОРА, расположенный в сети
          Интернет по адресу https://minotower.ru и на котором проводится
          АУКЦИОН.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ЭЛЕКТРОННЫЙ КОШЕЛЕК – программа, расположенная в личном аккаунте
          УЧАСТНИКА, и позволяющая УЧАСТНИКУ хранить денежные средства и
          производить с их помощью безналичные расчеты, в т.ч., но не
          ограничиваясь, оплату объектов недвижимости, услуг.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          1.2. В ОФЕРТЕ обозначения и термины используются в тех определениях и
          в том смысле, которые установлены ОФЕРТОЙ, ПРАВИЛАМИ РАССМОТРЕНИЯ
          ПРЕТЕНЗИЙ, ПОЛИТИКОЙ КОНФИДЕНЦИАЛЬНОСТИ и иными размещенными на
          ЭЛЕКТРОННОЙ ПЛОЩАДКЕ документами, регламентирующими порядок и условия
          проведения АУКЦИОНА по продаже ЛОТОВ на ЭЛЕКТРОННОЙ ПЛОЩАДКЕ, а при их
          отсутствии – действующим законодательством Российской Федерации.
        </DocumentSection.Paragraph>
      </DocumentSection>
      <DocumentSection>
        <DocumentSection.Title>2. ПРЕДМЕТ ДОГОВОРА</DocumentSection.Title>
        <DocumentSection.Paragraph>
          2.1. ОРГАНИЗАТОР обязуется осуществить организацию и проведение
          АУКЦИОНА с предоставлением УЧАСТНИКУ технической возможности участия в
          АУКЦИОНЕ и допуском к участию в АУКЦИОНЕ УЧАСТНИКА при выполнении им
          условий ОФЕРТЫ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          2.2. УЧАСТНИК обязуется выполнить действия, необходимые для его
          допуска к участию в АУКЦИОНЕ, проводимых ОРГАНИЗАТОРОМ, при участии в
          АУКЦИОНЕ действовать добросовестно по отношению к ОРГАНИЗАТОРУ и
          другим УЧАСТНИКАМ АУКЦИОНА, не нарушать ПРАВИЛА и положения ДОГОВОРА,
          а также своевременно вносить плату за допуск к АУКЦИОНУ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          2.3. ОРГАНИЗАТОР обязуется проводить АУКЦИОН в соответствии с порядком
          проведения АУКЦИОНА, изложенном в п.6 настоящей ОФЕРТЫ.
        </DocumentSection.Paragraph>
      </DocumentSection>
      <DocumentSection>
        <DocumentSection.Title>
          3. ПРАВА И ОБЯЗАННОСТИ УЧАСТНИКА И ОРГАНИЗАТОРА АУКЦИОНА
        </DocumentSection.Title>
        <DocumentSection.Paragraph>
          3.1. Права и обязанности УЧАСТНИКА АУКЦИОНА:
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          3.1.1. УЧАСТНИКОМ может стать лицо, которое ознакомилось и полностью
          приняло ДОГОВОР, прошло АККРЕДИТАЦИЮ УЧАСТНИКА и получило право
          доступа к участию в АУКЦИОНЕ на основании полученного от ОРГАНИЗАТОРА
          личного АККАУНТА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          3.1.2. Участие в АУКЦИОНЕ предоставляет УЧАСТНИКУ АУКЦИОНА возможность
          получения права на приобретение в собственность реализуемых на
          АУКЦИОНЕ ЛОТОВ либо реализацию объектов недвижимости, в том числе
          собственных, через АУКЦИОН ОРГАНИЗАТОРА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          3.1.3. УЧАСТНИК обязуется хранить в тайне и не раскрывать третьим
          лицам данные своего АККАУНТА (логин и пароль), а также информацию о
          других УЧАСТНИКАХ, ставшую ему известной посредством корреспонденции,
          связанной с деятельностью ЭЛЕКТРОННОЙ ПЛОЩАДКИ, либо в результате
          сделки, заключенной посредством ЭЛЕКТРОННОЙ ПЛОЩАДКИ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          3.1.4. Для предварительного определения цены ЛОТА, УЧАСТНИК АУКЦИОНА
          имеет право воспользоваться услугой ОРГАНИЗАТОРА АУКЦИОНА по выездному
          осмотру объекта недвижимости.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          3.1.5. Стороны согласовали, что стоимость услуги:
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - по осмотру, подготовке заключения по результатам осуществленного
          выезда за 1 (один) объект недвижимости и запуск АУКЦИОНА ОРГАНИЗАТОРОМ
          на данный объект недвижимости, составляет 1 000 (одна тысяча) рублей
          00 копеек, в т.ч. НДС.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          3.1.6. УЧАСТНИК АУКЦИОНА обязуется оплатить выбранную им услугу
          посредством онлайн-оплаты ОРГАНИЗАТОРУ АУКЦИОНА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          3.1.7. УЧАСТНИК самостоятельно несет ответственность по защите данных
          своего АККАУНТА (логина и пароля; присвоенного ID) и всех действий,
          происходящих в его АККАУНТЕ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          3.1.8. УЧАСТНИК обязуется поддерживать положительный БАЛАНС ЛИЦЕВОГО
          СЧЕТА, оплачивая Услуги ОРГАНИЗАТОРА в порядке, предусмотренном
          настоящей ОФЕРТОЙ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          3.1.9. УЧАСТНИК обязуется осуществлять каждое обновление версии сайт
          «https://minotower.ru» в течение 48 часов с даты официального выпуска
          ОРГАНИЗАТОРОМ такой обновленной версии.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          3.1.10. В случае необновления УЧАСТНИКОМ сайта «https://minotower.ru»
          до последней действующей версии в течение 48 часов с даты ее
          официального запуска:
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - ОРГАНИЗАТОРА не гарантирует УЧАСТНИКУ технической возможности
          участия в АУКЦИОНЕ;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - При наличии спорной ситуации Стороны руководствуются исключительно
          той версией сайта «https://minotower.ru», которая выпущена с последним
          обновлением (на последнюю дату), вне зависимости от того, какая версия
          сайта «https://minotower.ru» установлена у УЧАСТНИКА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          3.1.11. УЧАСТНИК АУКЦИОНА имеет право на осуществление платежей по
          фиксации и бронировании ЛОТА, победителем которого он стал по итогам
          проведенного АУКЦИОНА, как посредством оплаты через партнерские
          сервисы ОРГАНИЗАТОРА АУКЦИОНА, так и посредством оплаты Лота из своего
          электронного кошелька.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          3.1.12. УЧАСТНИК обязуется, используя сайт «https://minotower.ru» на
          стационарном компьютере/ноутбуке, использовать кнопку «Геолокация» со
          статусом активно/включено. В случае отключения УЧАСТНИКОМ кнопки
          «Геолокация» либо в случае отключения «Геолокации» Приложения по
          другим причинам, ОРГАНИЗАТОР АУКЦИОНА вправе отказать УЧАСТНИКУ в
          предоставлении доступа к сайту и, как следствие, отсутствие
          возможности использования сервиса «MinoTower» как для участия в
          аукционе, так и для пользования иным функционалом, предусмотренным
          данным сайтом.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          3.2. Права и обязанности ОРГАНИЗАТОРА АУКЦИОНА:
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          3.2.1. ОРГАНИЗАТОР обязуется обеспечить УЧАСТНИКА информацией о
          комплектации и техническом состоянии реализуемых ЛОТОВ, путем
          размещения информации о ЛОТАХ на сайте ЭЛЕКТРОННОЙ ПЛОЩАДКИ.
          Информация, размещенная о ЛОТАХ на сайте ЭЛЕКТРОННОЙ ПЛОЩАДКИ и взятая
          ОРГАНИЗАТОРОМ АУКЦИОНА из открытых источников (сайт РОСРЕЕСТР
          https://rosreestr.gov.ru), носит справочно-информационный характер, за
          достоверность которой ОРГАНИЗАТОР АУКЦИОНА не несут ответственности, а
          также предоставленная информация в понимании ст. 431.2 ГК РФ не
          является заверением об обстоятельствах.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          3.2.2. ОРГАНИЗАТОР обязуется проверять наличие документов ЛОТА
          (Выписка из ЕГРН и т.п.), проверять состояние реализуемого ЛОТА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          3.2.3. ОРГАНИЗАТОР обязуется обеспечить конфиденциальность сведений,
          предоставленных УЧАСТНИКАМИ при прохождении АККРЕДИТАЦИИ УЧАСТНИКА
          согласно ПОЛИТИКЕ КОНФИДЕНЦИАЛЬНОСТИ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          3.2.4. ОРГАНИЗАТОР имеет право размещать, уничтожать или редактировать
          материалы на ЭЛЕКТРОННОЙ ПЛОЩАДКЕ в любое время, по своему
          собственному усмотрению без предоставления какого-либо уведомления.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          3.2.5. ОРГАНИЗАТОР осуществляет ведение реестра УЧАСТНИКОВ, получивших
          АККРЕДИТАЦИЮ УЧАСТНИКА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          3.2.6. ОРГАНИЗАТОР вправе в одностороннем порядке приостановить доступ
          УЧАСТНИКА к ЭЛЕКТРОННОЙ ПЛОЩАДКЕ в случае несоблюдения УЧАСТНИКОМ
          ДОГОВОРА, ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ, а также в иных случаях,
          влекущих ущемление/нарушение прав третьих лиц.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          3.2.7. В случае письменного обращения ПОБЕДИТЕЛЯ АУКЦИОНА с претензией
          в отношении ЛОТА и, как следствие, отказ от данного ЛОТА, и признании
          ОРГАНИЗАТОРОМ АУКЦИОНА такой претензии обоснованной (при условии, что
          бронирование ЛОТА оплачено, но по нему не подписан договор
          купли-продажи), ОРГАНИЗАТОР АУКЦИОНА вправе предоставить ПОБЕДИТЕЛЮ
          АУКЦИОНА возможность осуществить перезачет ранее оплаченных денежных
          средств за ЛОТ в счет бронирование за новый ЛОТ, ПОБЕДИТЕЛЕМ АУКЦИОНА
          по которому УЧАСТНИК уже является или станет им в будущем.
        </DocumentSection.Paragraph>
      </DocumentSection>
      <DocumentSection>
        <DocumentSection.Title>
          4. ПОРЯДОК ПРОХОЖДЕНИЯ АККРЕДИТАЦИИ УЧАСТНИКА
        </DocumentSection.Title>
        <DocumentSection.Paragraph>
          4.1. УЧАСТНИКОМ может стать любое лицо, прошедшее АККРЕДИТАЦИЮ
          УЧАСТНИКА и получившее доступ к участию в АУКЦИОНЕ, а также
          соблюдающее ДОГОВОР и ПОЛИТИКУ КОНФИДЕНЦИАЛЬНОСТИ и иные размещенные
          на ЭЛЕКТРОННОЙ ПЛОЩАДКЕ документы, регламентирующие порядок и условия
          проведения АУКЦИОНА на ЭЛЕКТРОННОЙ ПЛОЩАДКЕ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.2. Для АККРЕДИТАЦИИ УЧАСТНИКА необходимо зарегистрироваться на
          ЭЛЕКТРОННОЙ ПЛОЩАДКЕ и предоставить следующую информацию и документы:
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.2.1. Для УЧАСТНИКОВ - юридических лиц:
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - полное наименование, копию Свидетельства о присвоении ИНН, копию
          Свидетельства о присвоении ОГРН;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - адрес в пределах места нахождения с указанием индекса;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - ФИО уполномоченного представителя юридического лица с приложением
          сканированной копии доверенности, копии паспорта, контактного телефона
          и адреса электронной почты;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - копию паспорта Генерального директора
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.2.2. Для УЧАСТНИКОВ – физических лиц:
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - ФИО (полностью), копию паспорта с адресом регистрации;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - контактный телефон и адрес электронной почты.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.2.3 Для УЧАСТНИКОВ - индивидуальных предпринимателей (далее – «ИП»):
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - ФИО (полностью), копию паспорта с адресом регистрации;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - телефон и адрес электронной почты;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - свидетельство о регистрации в качестве ИП, свидетельство о
          присвоении ИНН.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          Предоставляя упомянутые в настоящем пункте персональные данные
          физических лиц, УЧАСТНИК гарантирует ОРГАНИЗАТОРУ, что предоставил
          предварительно каждому лицу необходимое в соответствии с действующим
          законодательством РФ письменное согласие на обработку его персональных
          данных, в том числе на их обработку, хранение и передачу. Настоящим
          УЧАСТНИК дает свое согласие на получение от ОРГАНИЗАТОРА и иных
          третьих лиц, рекламных материалов и информации об услугах и акциях,
          иных информационных материалов по любым каналам связи, в частности, но
          не ограничиваясь, через СМС - оповещение, почтовое письмо, телеграмму,
          голосовое сообщение, сообщение по электронной почте (при этом для
          данных информационных рассылок УЧАСТНИК разрешает ОРГАНИЗАТОРУ
          использовать любую контактную информацию, переданную УЧАСТНИКОМ в
          адрес ОРГАНИЗАТОРА).
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.2.4. Обработка персональных данных Пользователя осуществляется в
          целях надлежащего предоставления функциональных возможностей
          ЭЛЕКТРОННОЙ ПЛОЩАДКИ, информирования о новостях, обновлениях сервисов,
          формирования и передачи ответов УЧАСТНИКУ, разрешения возможных
          претензий, улучшения безопасности использования ЭЛЕКТРОННОЙ ПЛОЩАДКИ,
          восстановления утраченного доступа к профилю в аккаунте, анализа и
          мониторинга эффективности работы ЭЛЕКТРОННОЙ ПЛОЩАДКИ на основании
          собранных статистических данных.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.2.5. УЧАСТНИК подтверждает свое согласие с передачей предоставленных
          им персональных данных третьим лицам, Поставщикам услуг (в том числе,
          но не ограничиваясь - банкам, кредитным и страховым учреждениям) и их
          обработку третьими лицами в целях исполнения настоящей ОФЕРТЫ и
          реализации функций АУКЦИОНА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.2.6. УЧАСТНИК соглашается, что персональные данные могут
          обрабатываться в течение срока деятельности ОРГАНИЗАТОРА. Хранение
          персональных данных осуществляется согласно действующему
          законодательству РФ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.2.7. УЧАСТНИК несет полную ответственность за достоверность
          предоставляемых персональных данных и наступившие в связи с этим
          последствия перед третьими лицами.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.2.8. Согласие на обработку персональных данных может быть отозвано
          УЧАСТНИКОМ путем направления письменного заявления ОРГАНИЗАТОРУ
          АУКЦИОНА по адресу, указанному в реквизитах настоящей ОФЕРТЫ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.2.9. ОРГАНИЗАТОР АУКЦИОНА вправе информировать УЧАСТНИКА о порядке и
          способах использования ЭЛЕКТРОННОЙ ПЛОЩАДКИ, о проводимых
          ОРГАНИЗАТОРОМ АУКЦИОНА, его партнерами, Поставщиками услуг и иными
          третьими лицами маркетинговых, рекламных и иных мероприятиях, об
          условиях приобретения и потребления услуг третьих лиц с использованием
          ЭЛЕКТРОННОЙ ПЛОЩАДКИ, направлять УЧАСТНИКУ сообщения любого характера,
          в том числе сервисные и рекламные сообщения, на номер мобильного
          телефона или адрес электронной почты, указанные УЧАСТНИКОМ при
          регистрации. ОРГАНИЗАТОР АУКЦИОНА также вправе размещать
          соответствующую информацию на самой ЭЛЕКТРОННОЙ ПЛОЩАДКЕ. Акцепт
          условий настоящей ОФЕРТЫ означает безусловное согласие УЧАСТНИКА с
          указанным правом ОРГАНИЗАТОРА АУКЦИОНА и получением УЧАСТНИКОМ
          указанных сообщений.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.3. Указанные в пункте 4.2. сведения вносятся УЧАСТНИКОМ в
          регистрационную форму на ЭЛЕКТРОННОЙ ПЛОЩАДКЕ в соответствующем
          разделе. УЧАСТНИК обязуется предоставить ОРГАНИЗАТОРУ для обозрения
          подлинники или надлежащим образом удостоверенные копии указанных в
          настоящем пункте документов в срок не позднее 5 (пяти) рабочих дней с
          момента получения первого требования ОРГАНИЗАТОРА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.4. ОРГАНИЗАТОР, получивший полный пакет документов, проводит
          проверку предоставленных данных. По окончании проверки ОРГАНИЗАТОР
          лицу, желающему стать УЧАСТНИКОМ АУКЦИОНА, направляет электронное
          уведомление об АККРЕДИТАЦИИ УЧАСТНИКА или отказ в АККРЕДИТАЦИИ
          УЧАСТНИКА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.5. ОРГАНИЗАТОР вправе отказать в АККРЕДИТАЦИИ УЧАСТНИКУ по следующим
          основаниям:
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - непредоставление лицом, желающим стать УЧАСТНИКОМ, документов и
          сведений, указанных в п. 4.2. настоящего Договора;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - предоставление документов, не соответствующих требованиям,
          установленным настоящим ДОГОВОРОМ, а также законодательством
          Российской Федерации;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - предоставление лицом, желающим стать УЧАСТНИКОМ, недостоверных
          документов и сведений, указанных в п. 4.2. настоящего ДОГОВОРА;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - по иным обоснованным причинам.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.6. В случае возникновения сомнений в достоверности предоставленных
          УЧАСТНИКОМ сведений и документов, ОРГАНИЗАТОР вправе в течение всего
          срока прохождения АККРЕДИТАЦИИ УЧАСТНИКА запросить дополнительные
          сведения и (или) потребовать подтверждения предоставленных сведений. В
          случае не предоставления и (или) не подтверждения УЧАСТНИКОМ
          запрошенных ОРГАНИЗАТОРОМ сведений в течение 5 (Пяти) рабочих дней с
          момента направления запроса, ОРГАНИЗАТОР вправе отказать в
          АККРЕДИТАЦИИ УЧАСТНИКУ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.7. Для подтверждения успешного окончания регистрации УЧАСТНИКА на
          ЭЛЕКТРОННОЙ ПЛОЩАДКЕ ОРГАНИЗАТОР высылает УЧАСТНИКУ письмо на
          электронную почту, указанную при регистрации, с приглашением на
          активацию АККАУНТА УЧАСТНИКА на ЭЛЕКТРОННОЙ ПЛОЩАДКЕ и необходимыми
          техническими сведениями (Интернет-адресами, паролями для доступа в
          раздел «Личный кабинет» ЭЛЕКТРОННОЙ ПЛОЩАДКИ, ссылками, ID УЧАСТНИКА и
          т.п.).
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.8. УЧАСТНИК самостоятельно обеспечивает конфиденциальность
          полученных сведений, указанных в п.4.7. ДОГОВОРА, а также несет полную
          ответственность за все действия, либо нарушения, связанные с их
          использованием лично либо третьими лицами.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.9. Для получения возможности принимать участие в АУКЦИОНЕ и делать
          СТАВКИ в процессе АУКЦИОНА, УЧАСТНИК вносит ЛИЦЕНЗИОННУЮ ПЛАТУ путем
          перечисления ОРГАНИЗАТОРУ АУКЦИОНА денежных средств с ЛИЦЕВОГО СЧЕТА в
          ЭЛЕКТРОННОМ КОШЕЛЬКЕ на сумму, в зависимости от выбранного тарифа
          ЛИЦЕНЗИОННОЙ ПЛАТЫ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.9.1. ОРГАНИЗАТОР АУКЦИОНА предоставляет на выбор УЧАСТНИКУ АУКЦИОНА
          возможность выбора ЛИЦЕНЗИОННОЙ ПЛАТЫ по 2 (Двум) тарифам, имеющим
          различную стоимость и объем.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.9.1.1. ЛИЦЕНЗИОННАЯ ПЛАТА - Тариф «БАЗОВЫЙ»: Данным тарифом может
          воспользоваться любой УЧАСТНИК АУКЦИОНА, вне зависимости от типа
          депозита. Стоимость ЛИЦЕНЗИОННОЙ ПЛАТЫ за возможность принимать
          участие в АУКЦИОНЕ по тарифу «БАЗОВЫЙ» составляет 10 000 (Десять
          тысяч) рублей 00 копеек, включает в себя НДС по ставке, установленной
          законодательством Российской Федерации. При приобретении УЧАСТНИКОМ
          тарифа «БАЗОВЫЙ», в его стоимость входит:
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - предоставление ОРГАНИЗАТОРОМ доступа по подписке;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          УЧАСТНИК АУКЦИОНА, имеющий любой тип депозита, имеет право перехода на
          тариф с расширенным сервисом «КОМФОРТ». Условия перехода уточняйте у
          представителя/менеджера ОРГАНИЗАТОРА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.9.1.2. ЛИЦЕНЗИОННАЯ ПЛАТА - Тариф «КОМФОРТ»: Данным тарифом может
          воспользоваться любой УЧАСТНИК АУКЦИОНА, вне зависимости от типа
          депозита.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          Стоимость ЛИЦЕНЗИОННОЙ ПЛАТЫ за возможность принимать участие в
          АУКЦИОНЕ по тарифу «КОМФОРТ» составляет 50 000 (Пятьдесят тысяч)
          рублей 00 копеек, включает в себя НДС по ставке, установленной
          законодательством Российской Федерации.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          При приобретении УЧАСТНИКОМ тарифа «КОМФОРТ», в его стоимость входит:*
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - предоставление ОРГАНИЗАТОРОМ доступа по подписке;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - бесплатный доступ к базе будущих аукционов;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - услуги персонального менеджера.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          * Сопутствующие услуги в рамках тарифа «КОМФОРТ» оказывает ОРГАНИЗАТОР
          АУКЦИОНА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.9.1.3. ЛИЦЕНЗИОННАЯ ПЛАТА - Тариф «ПРЕМИУМ»:
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          Данным тарифом может воспользоваться любой УЧАСТНИК АУКЦИОНА, вне
          зависимости от типа депозита.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          Стоимость ЛИЦЕНЗИОННОЙ ПЛАТЫ за возможность принимать участие в
          АУКЦИОНЕ по тарифу «ПРЕМИУМ» составляет 100 000 (Сто тысяч рублей)
          рублей 00 копеек, включает в себя НДС по ставке, установленной
          законодательством Российской Федерации.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          При приобретении УЧАСТНИКОМ тарифа «ПРЕМИУМ», в его стоимость входит:*
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - предоставление ОРГАНИЗАТОРОМ доступа по подписке;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - бесплатный доступ к базе будущих аукционов;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - услуги персонального менеджера;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - безлимитный доступ к выпискам ЕГРН;;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          * Сопутствующие услуги в рамках тарифа «ПРЕМИУМ» оказывает ОРГАНИЗАТОР
          АУКЦИОНА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.9.1.2.1. При нажатии кнопки «Оплатить»/«Оформить подписку», УЧАСТНИК
          АУКЦИОНА безоговорочно соглашается со стоимостью/типом тарифа на
          получение доступа к АУКЦИОНУ. Оплата стоимости доступа к АУКЦИОНУ
          осуществляется путем уменьшения остатка денежных средств, отраженных
          на балансе Лицевого счета (соответствующего раздела в Учетной записи
          УЧАСТНИКА АУКЦИОНА), согласно выбранного тарифа (стоимости Подписки).
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ОРГАНИЗАТОР АУКЦИОНА предлагает УЧАСТНИКУ АУКЦИОНА следующие способы
          оплаты подписки при выборе тарифа:
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - посредством банковских карт международных платежных систем Visa,
          Mastercard и МИР;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - с помощью электронных кошельков;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          При оплате подписки по доступу к АУКЦИОНУ УЧАСТНИК АУКЦИОНА обязуется
          использовать только банковскую карту, владельцем которой он является,
          и в отношении которой между банком и УЧАСТНИКОМ АУКЦИОНА заключен
          соответствующий договор. В случае, если УЧАСТНИК АУКЦИОНА намеренно
          использует банковскую карту иного лица, то он самостоятельно несет
          ответственность за ущерб, который был/может быть причинен владельцу
          указанной карты в результате вышеперечисленных действий УЧАСТНИКА
          АУКЦИОНА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.9.1.2.2. При совершении платежа/ «привязки карты» к учетной записи
          УЧАСТНИКУ АУКЦИОНА предлагается указать следующие данные:
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - Номер банковской карты
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - Дату окончания срока действия карты
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>- CVV код</DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          Для осуществления проверки подлинности данных, указанных УЧАСТНИКОМ
          АУКЦИОНА в настоящем пункте Соглашения, возможно резервирование
          банком-эмитентом денежных средств, находящихся на соответствующих
          банковских счетах УЧАСТНИКА АУКЦИОНА в банке, на сумму, не превышающую
          10 (десять) рублей. После успешного прохождения проверки подлинности
          данных карты банком-эмитентом банковская карта УЧАСТНИКА АУКЦИОНА
          считается привязанной, а функция «Автоматическое продление подписки» -
          подключенной. Разблокировка суммы, зарезервированной при проверке
          подлинности банковской карты, производится в сроки, определяемые
          банком-эмитентом и не зависит от ОРГАНИЗАТОРА АУКЦИОНА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          Введенные УЧАСТНИКОМ АУКЦИОНА данные банковской карты сохраняются в
          профиле УЧАСТНИКА АУКЦИОНА в системах сертифицированных операторов
          Интернет-платежей в соответствии с правилами Международных Платежных
          Систем и сертификации PCI-DSS, оказывающих ОРГАНИЗАТОРУ АУКЦИОНА
          услуги по обеспечению приема платежей УЧАСТНИКОВ АУКЦИОНА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ОРГАНИЗАТОР АУКЦИОНА не несет ответственности за актуальность и
          обновление соответствующих данных в системах сертифицированных
          операторов Интернет-платежей.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.9.1.2.2. Отключение функции «Автоматическое продление подписки»
          может быть осуществлено УЧАСТНИКОМ АУКЦИОНА в настройках Учетной
          записи в порядке. В случае утраты/замены банковской карты УЧАСТНИК
          АУКЦИОНА обязуется незамедлительно письменно известить об этом
          ОРГАНИЗАТОРА АУКЦИОНА с целью отключения функции «Автоматическое
          продление подписки», а также указания реквизитов новой банковской
          карты. Полученное ОРГАНИЗАТОРОМ АУКЦИОНА письменное заявление
          УЧАСТНИКА АУКЦИОНА об утрате банковской карты является основанием для
          приостановления операций по платежам в пользу Лицевого счета УЧАСТНИКА
          АУКЦИОНА по утраченной банковской карте. В случае отсутствия
          уведомления со стороны УЧАСТНИК АУКЦИОНА о смене банковской карты,
          ОРГАНИЗАТОР АУКЦИОНА осуществляет списание денежных средств за
          получение доступа к АУКЦИОНУ путем обращения запроса о таком списании
          с использованием данных, предоставленных УЧАСТНИКОМ АУКЦИОНА в
          соответствии с п.4.9.1.2.2. Соглашения.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.9.1.3. По истечении срока предоставления ОРГАНИЗАТОРОМ АУКЦИОНА
          УЧАСТНИКУ доступа к АУКЦИОНУ, АККАУНТ УЧАСТНИКА блокируется. В случае
          желания УЧАСТНИКА продолжить участвовать в АУКЦИОНЕ, УЧАСТНИКУ
          необходимо внести ЛИЦЕНЗИОННУЮ ПЛАТУ ОРГАНИЗАТОРУ АУКЦИОНА на сумму, в
          зависимости от выбранного тарифа ЛИЦЕНЗИОННОЙ ПЛАТЫ либо подключить
          функцию автоматического продления подписки.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.9.1.4. Оплата в рамках доступа к АУКЦИОНУ по подписке исходя из
          выбранного тарифа осуществляется путем списания денежных средств со
          счета банковской карты в последний день оплаченного периода. Списание
          денежных средств с банковской карты не производится в следующих
          случаях:
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - при отсутствии на счете банковской карты денежных средств в сумме
          достаточной для оплаты «Подписки»;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - банковская карта, указанная УЧАСТНИКОМ АУКЦИОНА при подключении
          «Подписки», заблокирована;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - операции по счету банковской карты приостановлены в случаях,
          установленных действующим законодательством РФ, или договором на
          выпуск и обслуживание банковской карты.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          УЧАСТНИК АУКЦИОНА обязуется не передавать третьим лицам данные
          банковской карты и номер Лицевого счета. Все риски, связанные с
          возможным умышленным использованием третьими лицами данных банковской
          карты и номера Лицевого счета УЧАСТНИКА АУКЦИОНА в случае их
          ненадлежащего хранения, УЧАСТНИК АУКЦИОНА несет самостоятельно.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.9.1.5. Оплата Подписки осуществляется на условиях внесения
          лицензионного платежа УЧАСТНИКОМ АУКЦИОНА. Размер лицензионного
          платежа за 30-дневный период пользования Подпиской определяется исходя
          из выбранного УЧАСТНИКОМ АУКЦИОНА тарифа. УЧАСТНИК АУКЦИОНА выражает
          свое согласие на списание лицензионного платежа за доступ к АУКЦИОНУ в
          полном размере за следующий 30-дневный период пользования Подпиской
          авансом в дату окончания предыдущего периода пользования Подпиской.
          УЧАСТНИК АУКЦИОНА вправе отказаться от дальнейшего использования
          Подписки в настройках своей Учетной записи в разделе «Подписки» путем
          отключения функции «Автоматическое продление». В случае отказа
          УЧАСТНИКА АУКЦИОНА от использования Подписки, остаток денежных
          средств, внесенных УЧАСТНИКОМ АУКЦИОНА в качестве лицензионной платы
          за доступ к АУКЦИОНУ исходя из выбранного им тарифа, возврату не
          подлежит.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.10. УЧАСТНИК вносит один из приемлемых для него обеспечительных
          платежей (далее – «ДЕПОЗИТ») в разделе «ДЕПОЗИТ» в личном кабинете
          УЧАСТНИКА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          Типы ДЕПОЗИТОВ (стандартная сетка):
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - ДЕПОЗИТ «Базовый» - 10 000 (Десять тысяч) рублей, НДС не облагается.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - ДЕПОЗИТ «Комфортный» - 25 000 (Двадцать пять тысяч) рублей, НДС не
          облагается.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          Внесение ДЕПОЗИТА «Комфортный» дает право доступа УЧАСТНИКУ ко всем
          АУКЦИОНАМ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          Сумма ДЕПОЗИТА является способом обеспечения выполнения обязательств
          УЧАСТНИКА, предусмотренных ДОГОВОРОМ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          УЧАСТНИК лишается права на участие в АУКЦИОНЕ в случаях отсутствия на
          дату проведения АУКЦИОНА оплаты им ДЕПОЗИТА и/или ЛИЦЕНЗИОННОГО
          ПЛАТЕЖА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          При первичном внесении ДЕПОЗИТА УЧАСТНИКОМ, ЛИЦЕНЗИОННЫЙ ПЛАТЕЖ за 1
          (первый) месяц вносится единым платежом с ДЕПОЗИТОМ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ОРГАНИЗАТОР оставляет за собой право удержания из ДЕПОЗИТА любых
          понесенных им издержек, убытков, налогов, штрафов и/или штрафных
          санкций, связанных с нарушением УЧАСТНИКОМ условий ДОГОВОРА в
          независимости от того, кем была осуществлена оплата ДЕПОЗИТА за
          УЧАСТНИКА (лично УЧАСТНИКОМ либо третьими лицами за него). УЧАСТНИК
          обязуется незамедлительно осуществить пополнение ДЕПОЗИТА в случае
          уменьшения размера ДЕПОЗИТА по тем или иным причинам. С момента
          уменьшения ДЕПОЗИТА и до внесения ДЕПОЗИТА в соответствующем размере
          УЧАСТНИК не может принимать участие в АУКЦИОНЕ, так как ОРГАНИЗАТОР
          блокирует работу УЧАСТНИКА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ОРГАНИЗАТОР обязуется предоставить УЧАСТНИКУ подтверждающие документы
          на сумму любых удержаний из ДЕПОЗИТА в соответствии с действующим
          законодательством РФ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.10.1. После объявления УЧАСТНИКА ПОБЕДИТЕЛЕМ АУКЦИОНА, ДЕПОЗИТ
          УЧАСТНИКА полностью или частично «холдируется» до полной оплаты ЛОТА
          ОРГАНИЗАТОРУ АУКЦИОНА в размере, определяемом в зависимости от
          стоимости ЛОТА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          При стоимости ЛОТА:
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - до 10 000 000 (Десяти миллионов) рублей включительно, ДЕПОЗИТ
          «холдируется» в размере 10 000 (Десяти тысяч) рублей;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - от 20 000 000 (Двадцати миллионов) рублей до 500 000 (Пятисот тысяч)
          рублей включительно, ДЕПОЗИТ «холдируется» в размере 25 000 (Двадцать
          пять тысяч) рублей;.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          В случае, если ДЕПОЗИТ УЧАСТНИКА «холдируется» не полностью, и сумма
          оставшейся части составляет не менее 10 000 (Десяти тысяч) рублей, эта
          часть даёт право доступа УЧАСТНИКУ ко всем АУКЦИОНАМ. При этом
          максимальная СТАВКА за ЛОТ на АУКЦИОНЕ определяется исходя из типа
          ДЕПОЗИТА, к которому эта часть относится.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.11. Право участия в АУКЦИОНЕ может быть аннулировано ОРГАНИЗАТОРОМ в
          одностороннем порядке с прекращением настоящего ДОГОВОРА и
          прекращением технической возможности участия УЧАСТНИКА в АУКЦИОНЕ в
          случае выявления недостоверности сведений, предоставленных УЧАСТНИКОМ
          при регистрации на ЭЛЕКТРОННОЙ ПЛОЩАДКЕ, а также иного нарушения
          УЧАСТНИКОМ как положений ДОГОВОРА, так и иных документов либо условий
          ОРГАНИЗАТОРА, на которые имеются ссылки в ДОГОВОРЕ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.12. УЧАСТНИК имеет право вывести/пополнить денежные средства (за
          исключением ЛИЦЕНЗИОННОЙ ПЛАТЫ, не подлежащей перерасчету), внесенные
          на ЛИЦЕВОЙ СЧЕТ в ЭЛЕКТРОННОМ КОШЕЛЬКЕ или в качестве ДЕПОЗИТА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.12.1. Для полного или частичного вывода ДЕПОЗИТА УЧАСТНИК должен
          уведомить ОРГАНИЗАТОРА и написать заявление на изменение условий
          ДОГОВОРА по форме, предоставляемой ОРГАНИЗАТОРОМ. При этом ОРГАНИЗАТОР
          имеет право отказать УЧАСТНИКУ в полном или частичном выводе ДЕПОЗИТА
          в случае, если УЧАСТНИК имеет перед ОРГАНИЗАТОРОМ неисполненные
          обязательства по оплате и/или получению ЛОТА/ЛОТОВ и/или услуг.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.12.2. УЧАСТНИК и ОРГАНИЗАТОР согласны с тем, что в случае изменения
          условий ДОГОВОРА необходимо будет либо возвратить УЧАСТНИКУ ранее
          перечисленный ДЕПОЗИТ (полностью или частично), либо УЧАСТНИК
          обязуется оплатить (полностью или частично) ДЕПОЗИТ согласно условиям
          ДОГОВОРА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.12.3. Для полного или частичного возврата денежных средств,
          внесенных на ЛИЦЕВОЙ СЧЁТ в ЭЛЕКТРОННОМ КОШЕЛЬКЕ, УЧАСТНИК должен
          уведомить ОРГАНИЗАТОРА и написать заявление по форме, предоставляемой
          ОРГАНИЗАТОРОМ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          4.12.4. УЧАСТНИК, ставший ПОБЕДИТЕЛЕМ АУКЦИОНА за ЛОТ, реализуемый
          Продавцами в виде коммерческих организаций, ознакомлен и согласен с
          тем, что не имеет право осуществлять вывод ДЕПОЗИТА до момента, пока
          данный ЛОТ находится в статусе "Ожидают решение" или "Согласовано" от
          Продавца.
        </DocumentSection.Paragraph>
      </DocumentSection>
      <DocumentSection>
        <DocumentSection.Title>
          5. АКЦЕПТ ОФЕРТЫ И ЗАКЛЮЧЕНИЕ ДОГОВОРА
        </DocumentSection.Title>
        <DocumentSection.Paragraph>
          5.1. АКЦЕПТОМ ОФЕРТЫ считается безоговорочное принятие ОФЕРТЫ
          потенциальным УЧАСТНИКОМ путем проставления галочки напротив «Я
          ознакомился с офертой и принимаю ее условия» на ЭЛЕКТРОННОЙ ПЛОЩАДКЕ.
        </DocumentSection.Paragraph>
      </DocumentSection>
      <DocumentSection>
        <DocumentSection.Title>
          6. ПОРЯДОК ПРОВЕДЕНИЯ АУКЦИОНА
        </DocumentSection.Title>
        <DocumentSection.Paragraph>
          6.1. Вся информация о ЛОТАХ, включая его фото, размещается на
          ЭЛЕКТРОННОЙ ПЛОЩАДКЕ в разделе, относящемся к данному ЛОТУ, и
          признается достаточной и окончательной. Во время проведения АУКЦИОНА
          УЧАСТНИК имеет право ознакомиться с описанием ЛОТА и его фотографиями
          в действующем состоянии, размещенном на ЭЛЕКТРОННОЙ ПЛОЩАДКЕ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          6.2. Извещение о проведении АУКЦИОНА производится путем опубликования
          ЛОТОВ ОРГАНИЗАТОРОМ на ЭЛЕКТРОННОЙ ПЛОЩАДКЕ, а также путем рассылки
          информации о сроках и условиях проведения АУКЦИОНА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          6.3. Чтобы принять участие в АУКЦИОНЕ, УЧАСТНИКУ необходимо войти на
          ЭЛЕКТРОННУЮ ПЛОЩАДКУ, используя свой логин и пароль или номер
          телефона, указанный при регистрации АККАУНТА, выбрать интересующий его
          ЛОТ, ознакомиться с представленной информацией по ЛОТУ и сделать
          СТАВКУ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          6.4. Изменение СТАВКИ производится УЧАСТНИКОМ на странице ЛОТА, по
          которому проводится АУКЦИОН. СТАВКА УЧАСТНИКА должна превышать
          «текущую ставку» на странице ЛОТА. Стартовый шаг АУКЦИОНА меняется в
          зависимости от лота.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          6.5. ОРГАНИЗАТОР имеет право отозвать ЛОТ с АУКЦИОНА до момента
          передачи права собственности ПОБЕДИТЕЛЮ АУКЦИОНА в следующих случаях:
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - ЛОТ является предметом судебного разбирательства;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - ЛОТ находится в залоге, розыске, состоит под арестом, обременен
          другими обязательствами и правами третьих лиц либо установлено иное
          ограничение в правах собственности у владельца ЛОТА;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - в иных случаях, которые могут служить основанием для ограничения
          прав будущего ПОБЕДИТЕЛЯ АУКЦИОНА и иных заинтересованных лиц;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - по инициативе ОРГАНИЗАТОРА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          6.6. В случае возникновения у УЧАСТНИКА вопросов, связанных с
          состоянием ЛОТА или с проведением АУКЦИОНА, УЧАСТНИК вправе по
          телефону обратиться за помощью или разъяснениями к ПРЕДСТАВИТЕЛЯМ
          ОРГАНИЗАТОРА, которые обязаны немедленно и бесплатно оказать УЧАСТНИКУ
          необходимую помощь и/или ответить на возникшие вопросы.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          6.7. ЛОТЫ, выставляемые на АУКЦИОН, продаются «как есть», в состоянии
          и комплектации, описанной на ЭЛЕКТРОННОЙ ПЛОЩАДКЕ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          6.8. ОРГАНИЗАТОР не гарантирует УЧАСТНИКУ достижение какой-то
          определенной ЦЕНЫ ЛОТА в ходе проведения АУКЦИОНА, либо приобретение
          какого-либо ЛОТА по итогам проводимого АУКЦИОНА, в том числе и
          УЧАСТНИКАМ, предложившим на АУКЦИОНЕ наибольшую ЦЕНУ ЛОТА. Участие в
          проводимом ОРГАНИЗАТОРЕ АУКЦИОНЕ является самостоятельным риском
          УЧАСТНИКА и проводится исключительно по его воле.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          6.9. ЛОТ, выставленный на АУКЦИОН, не может быть снят с АУКЦИОНА в
          течение всего периода его проведения, равно как не могут быть изменены
          какие-либо условия проведения текущего АУКЦИОНА, за исключением
          случаев, предусмотренных ДОГОВОРОМ. ОРГАНИЗАТОР имеет право снять ЛОТ
          с АУКЦИОНА до его начала или во время АУКЦИОНА в случаях, которые
          могут привести к ограничению или нарушению прав будущего ПОБЕДИТЕЛЯ
          АУКЦИОНА или иных заинтересованных лиц.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          6.10. ПЕРИОД ПРОВЕДЕНИЯ АУКЦИОНА по каждому ЛОТУ определяются
          ОРГАНИЗАТОРОМ и публикуется на ЭЛЕКТРОННОЙ ПЛОЩАДКЕ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          6.11. ОРГАНИЗАТОР вправе продлить ПЕРИОД ПРОВЕДЕНИЯ АУКЦИОНА.
          Уведомление о продлении сроков проведения АУКЦИОНА производится
          ОРГАНИЗАТОРОМ путем опубликования соответствующей информации на
          ЭЛЕКТРОННОЙ ПЛОЩАДКЕ на странице ЛОТА.
        </DocumentSection.Paragraph>
      </DocumentSection>
      <DocumentSection>
        <DocumentSection.Title>
          7. ОПРЕДЕЛЕНИЕ ПОБЕДИТЕЛЯ АУКЦИОНА, ОПЛАТА И ПОЛУЧЕНИЕ ЛОТА
        </DocumentSection.Title>
        <DocumentSection.Paragraph>
          7.1. При участии в АУКЦИОНЕ по конкретному ЛОТУ УЧАСТНИК вправе делать
          СТАВКУ на повышение цены продаваемого ЛОТА, через свой АККАУНТ на
          ЭЛЕКТРОННОЙ ПЛОЩАДКЕ, и, если на момент окончания АУКЦИОНА СТАВКА
          УЧАСТНИКА будет самой высокой по данному ЛОТУ, УЧАСТНИК признается
          ПОБЕДИТЕЛЕМ АУКЦИОНА, о чем ОРГАНИЗАТОР информирует ПОБЕДИТЕЛЯ
          АУКЦИОНА путем опубликования соответствующей информации на ЭЛЕКТРОННОЙ
          ПЛОЩАДКЕ в личном кабинете УЧАСТНИКА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          7.2. ЛОТ не передается УЧАСТНИКУ и договор купли-продажи не
          заключается в случае, если:
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - по результатам АУКЦИОНА не была достигнута минимальная ЦЕНА ЛОТА,
          устанавливаемая собственником ЛОТА;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - собственник ЛОТА отказался от продажи ЛОТА по цене, сформировавшейся
          по итогам АУКЦИОНА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          УЧАСТНИК уведомлен и согласен с тем, что окончательное решение о
          продаже ЛОТА по итогам АУКЦИОНА принимает собственник объекта
          недвижимости.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          7.3. О принятии собственником ЛОТА решения о заключении или не
          заключении договора купли-продажи ЛОТА ОРГАНИЗАТОР информирует
          ПОБЕДИТЕЛЯ АУКЦИОНА путем опубликования соответствующей информации на
          ЭЛЕКТРОННОЙ ПЛОЩАДКЕ в личном кабинете УЧАСТНИКА. ОРГАНИЗАТОР не несет
          ответственности перед УЧАСТНИКОМ за решения собственника ЛОТА,
          связанные с продажей ЛОТА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          7.3.1. В случае отказа собственника ЛОТА о его продаже по итогам
          проведенного АУКЦИОНА по предложенной УЧАСТНИКОМ ставке, ОРГАНИЗАТОР
          АУКЦИОН имеет право предложить собственнику ЛОТА провести повторный
          АУКЦИОН по данному ЛОТУ с целью повышения ставки по ЛОТУ в более
          сокращенные временные рамки, устанавливаемые ОРГАНИЗАТОРОМ АУКЦИОНА
          самостоятельно.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          7.3.2. В случае согласия собственника ЛОТА с проведением повторного
          АУКЦИОНА в более сокращенные временные рамки с целью получения более
          высокой ставки по его ЛОТУ, ОРГАНИЗАТОР АУКЦИОНА повторно запускает
          такой АУКЦИОН со стартовой ставкой в размере ставки ПОБЕДИТЕЛЯ
          АУКЦИОНА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          7.3.3. ОРГАНИЗАТОР АУКЦИОНА имеет право на любом из типов АУКЦИОНА,
          при получении отказа собственника ЛОТА о его продаже по итогам
          проведенного АУКЦИОНА по предложенной УЧАСТНИКОМ ставке, с помощью
          представителя/менеджера ОРГАНИЗАТОРА связаться с УЧАСТНИКОМ с целью
          повышения им ставки, которая приведет к согласию собственника ЛОТА по
          его продаже.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          7.4. Оплата ПОБЕДИТЕЛЕМ ЦЕНЫ ЛОТА, определенной по результатам
          АУКЦИОНА, и его непосредственное получение осуществляется в рамках
          отдельного договора купли-продажи ЛОТА, заключаемого ПОБЕДИТЕЛЕМ
          АУКУЦИОНА с ОРГАНИЗАТОРОМ и по его форме не позднее срока,
          установленного СТАТУСОМ АККАУНТА ПОБЕДИТЕЛЯ АУКЦИОНА с даты признания
          УЧАСТНИКА ПОБЕДИТЕЛЕМ АУКЦИОНА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          7.4.1. Оплата производится путем перечисления денежных средств на
          расчетный счет ОРГАНИЗАТОРА, который самостоятельно выплачивает
          собственнику ЛОТА денежные средства за приобретаемый ПОБЕДИТЕЛЕМ
          АУКЦИОНА ЛОТ и несёт ответственность за полную и своевременную оплату
          проданного ЛОТА перед его собственником и УЧАСТНИКОМ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ПОБЕДИТЕЛЬ АУКЦИОНА имеет право приобрести ЛОТ с использованием оплаты
          в адрес ОРГАНИЗАТОРА АУКЦИОНА третьим лицом.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          В данном случае ПОБЕДИТЕЛЬ АУКЦИОНА обязуется донести до лица,
          осуществляющего оплату за него, информацию о том, что назначение
          платежа должно содержать в себе следующую информацию:
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          «Оплата от гр._________ (от кого - ФИО) по счету №____ от «___»
          ________ 202__ года за гр. (за кого - ФИО) на основании Оферты ООО
          «ЮГрупп» (MinoTower)».
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          При этом в случае некорректно осуществленного платежа, ОРГАНИЗАТОР
          АУКЦИОНА имеет право в одностороннем порядке и без объяснения причин
          не принять такой платеж и отказать ПОБЕДИТЕЛЮ АУКЦИОНА в получении
          ЛОТа.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          7.4.2. С момента извещения ОРГАНИЗАТОРОМ АУКЦИОНА ПОБЕДИТЕЛЯ ЛОТА о
          готовности собственника ЛОТА к сделке, посредством размещения данной
          информации в личном кабинете ПОБЕДИТЕЛЯ АУКЦИОНА, ПОБЕДИТЕЛЬ АУКЦИОНА,
          через менеджера связывается с собственника ЛОТА для назначения даты
          сделки.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          7.4.3. В случае признания ОРГАНИЗАТОРОМ АУКЦИОНА претензии от
          ПОБЕДИТЕЛЯ АУКЦИОНА необоснованной и его отказом в 3-дневный срок
          получения данного ЛОТА, ОРГАНИЗАТОР АУКЦИОНА имеет право в
          одностороннем порядке удержать с ПОБЕДИТЕЛЯ АУКЦИОНА депозит в виде
          штрафных санкций за нарушение ПОБЕДИТЕЛЕМ АУКЦИОНА условий настоящей
          ОФЕРТЫ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          7.5. Риск случайной гибели и / или порчи приобретенного на АУКЦИОНЕ
          ЛОТА переходит к ПОБЕДИТЕЛЮ АУКЦИОНА с момента подписания акта
          приема-передачи ЛОТА в рамках отдельного договора купли-продажи ЛОТА,
          заключаемого УЧАСТНИКОМ с ОРГАНИЗАТОРОМ. ПОБЕДИТЕЛЬ АУКЦИОНА
          самостоятельно несет обязанности по уплате налога и оплачивает все
          расходы на содержание и эксплуатацию ЛОТА, с момента подписания акта
          приема-передачи к договору купли-продажи ЛОТА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          7.6. В случае нарушения ПОБЕДИТЕЛЕМ АУКЦИОНА п.7.4. ДОГОВОРА,
          ОРГАНИЗАТОР объявляет АУКЦИОН по данному ЛОТУ аннулированным и
          удерживает из ДЕПОЗИТА УЧАСТНИКА, признанного ПОБЕДИТЕЛЕМ АУКЦИОНА,
          штраф в следующих размерах:
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          7.6.1. УЧАСТНИК АУКЦИОНА, который внес любой тип ДЕПОЗИТА, оплачивает
          штраф в размере, определяемом в зависимости от стоимости ЛОТА и
          СТАТУСА АККАУНТА, при этом размер такого штрафа не может быть более
          размера ДЕПОЗИТА, внесенного УЧАСТНИКОМ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          7.6.2. Штраф, указанный выше, удерживаются ОРГАНИЗАТОРОМ из суммы
          ДЕПОЗИТА, внесенной УЧАСТНИКОМ лично либо третьим лицом за него.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          Положения данного пункта не применяются в случае отказа УЧАСТНИКА от
          заключения договора купли-продажи и приемки ЛОТА в связи с
          несоответствием фактического технического описания ЛОТА заявленному на
          ЭЛЕКТРОННОЙ ПЛОЩАДКЕ ОРГАНИЗАТОРА, и при условии, что отказ УЧАСТНИКА
          соответствует положениям ПРАВИЛ РАССМОТРЕНИЯ ПРЕТЕНЗИЙ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          7.7. УЧАСТНИК АУКЦИОНА согласен с тем, что ОРГАНИЗАТОР вправе в
          одностороннем порядке удержать из суммы ДЕПОЗИТА УЧАСТНИКА ШТРАФЫ,
          возникшие по причине ненадлежащего исполнения УЧАСТНИКОМ условий
          настоящей ОФЕРТЫ и договора купли-продажи, заключенных по итогам
          АУКЦИОНА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          7.8. УЧАСТНИКИ и ПОБЕДИТЕЛИ АУКЦИОНА имеют право на начисление
          «MinoTowerCoin», а также их дальнейшее списание из личного
          электронного кошелька, в рамках действующей Оферты в следующих
          случаях:
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          7.9.1. В случае признания претензии ОРГАНИЗАТОРОМ АУКЦИОНА
          обоснованной.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          7.9.2. В случае нарушения ОРГАНИЗАТОРОМ АУКЦИОНА условий настоящей
          Оферты. При этом размер начисления определяется ОРГАНИЗАТОРОМ АУКЦИОНА
          самостоятельно.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          7.9.3. В случае проведения ОРГАНИЗАТОРОМ АУКЦИОНА маркетинговых акций
          в компании.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          7.10. Срок действия «MinoTowerCoin» в личном кабинете УЧАСТНИКА и
          ПОБЕДИТЕЛЯ АУКЦИОНА составляет 90 (девяносто) дней с даты их
          начисления. По истечению указанного срока имеющиеся «MinoTowerCoin» в
          личном кабинете УЧАСТНИКА и ПОБЕДИТЕЛЯ АУКЦИОНА сгорают.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          7.11. УЧАСТНИКУ и ПОБЕДИТЕЛЮ АУКЦИОНА запрещено переводить имеющиеся
          «MinoTowerCoin» на другой аккаунт и/или передавать их третьим лицам.
        </DocumentSection.Paragraph>
      </DocumentSection>
      <DocumentSection>
        <DocumentSection.Title>
          8. ГАРАНТИИ И ЗАВЕРЕНИЯ ОБ ОБСТОЯТЕЛЬСТВАХ
        </DocumentSection.Title>
        <DocumentSection.Paragraph>
          8.1. Заключая ДОГОВОР, УЧАСТНИК подтверждает, что он ознакомился и
          согласен с ПРАВИЛАМИ РАССМОТРЕНИЯ ПРЕТЕНЗИЙ, ПОЛИТИКОЙ
          КОНФИДЕНЦИАЛЬНОСТИ, и иными документами, регламентирующими порядок и
          условия проведения АУКЦИОНА на ЭЛЕКТРОННОЙ ПЛОЩАДКЕ, их содержание
          УЧАСТНИКУ понятно.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          8.2. УЧАСТНИК, ПРИНИМАЯ УЧАСТИЕ В АУКЦИОНЕ СОГЛАШАЕТСЯ С ТЕМ, ЧТО
          ПРОДАВАЕМЫЕ НА АУКЦИОНЕ ЛОТЫ НЕ ВСЕГДА ПРОДАЮТСЯ НАПРЯМУЮ ОТ
          ЗАСТРОЙЩИКА, ВОЗМОЖНО БЫЛИ ПЕРЕПРОДАЖИ, МОГУТ СОДЕРЖАТЬ ВНЕШНИЕ И
          СКРЫТЫЕ ДЕФЕКТЫ, А ТАКЖЕ ИМЕТЬ ПРОБЛЕМЫ В ЭКСПЛУАТАЦИИ. ПРЕТЕНЗИИ
          ПОБЕДИТЕЛЯ АУКЦИОНА К ОРГАНИЗАТОРУ, СВЯЗАННЫЕ С ВНЕШНИМ ВИДОМ И
          ЭКСПЛУАТАЦИЕЙ ИСКЛЮЧАЮТСЯ ПО ПРИЧИНЕ ПОЛНОЙ ОСВЕДОМЛЕННОСТИ ПОБЕДИТЕЛЯ
          АУКЦИОНА О СОСТОЯНИИ ЛОТА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          8.3. Претензии относительно состояния и возможностей эксплуатации
          приобретенных на АУКЦИОНЕ ЛОТАХ заявляются ПОБЕДИТЕЛЕМ АУКЦИОНА и
          рассматриваются ОРГАНИЗАТОРОМ в сроки и в порядке, установленном
          ПРАВИЛАМИ РАССМОТРЕНИЯ ПРЕТЕНЗИЙ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          8.4. ОРГАНИЗАТОР обязуется оказывать УЧАСТНИКУ необходимую
          консультационную, информационную, техническую и иную помощь в процессе
          проведения АУКЦИОНА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          8.5. УЧАСТНИК и ОРГАНИЗАТОР взаимно обязуются обеспечить
          конфиденциальность любых предоставляемых сведений в процессе
          проведения АУКЦИОНА. УЧАСТНИК обязуется не передавать третьим лицам и
          не разглашать пароли и иные сведения, предоставляющие доступ для
          участия в АУКЦИОНЕ. Помимо прочего, УЧАСТНИК обязуется не
          предпринимать меры по установлению связи как самостоятельно, так и
          через знакомых ему лиц с Продавцом ЛОТА как во время проведения
          АУКЦИОНА, так и по результатам его окончания вне зависимости от того,
          был такой ЛОТ приобретен УЧАСТНИКОМ по договору купли-продажи или он
          стал ПОБЕДИТЕЛЕМ АУКЦИОНА, или не был приобретен УЧАСТНИКОМ по
          договору купли-продажи/не стал ПОБЕДИТЕЛЕМ АУКЦИОНА. Вся коммуникация
          по любым вопросам с лицами, желающими реализовать ЛОТ и/или его
          реализовавшими по итогам проведения АУКЦИОНА c использованием
          ресурсов/платформы ОРГАНИЗАТОРА, производится исключительно через
          работников ОРГАНИЗАТОРА и/или личного менеджера ОРГАНИЗАТОРА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          8.6. УЧАСТНИК несет ответственность во всех случаях без исключения за
          ценовые предложения, сделанные на ЭЛЕКТРОННОЙ ПЛОЩАДКЕ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          8.7. УЧАСТНИК не имеет права разглашать любые сведения, касающиеся
          работы ЭЛЕКТРОННОЙ ПЛОЩАДКИ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          8.8. УЧАСТНИК заверяет, что не участвует в действиях мошеннического
          характера, осуществляет деятельность в строгом соответствии с
          Федеральным законом от 7 августа 2001 г. № 115-ФЗ «О противодействии
          легализации (отмыванию) доходов, полученных преступным путем, и
          финансированию терроризма».
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          8.8.1. Участник заверяет и гарантирует, что не будет делать ставки на
          ЭЛЕКТРОННОЙ ПЛОЩАДКЕ АУКЦИОНА на принадлежащие ему объекты
          недвижимости на праве собственности и с целью получения максимально
          возможной стоимости за такой ЛОТ с целью обогащения за счет
          Организатора АУКЦИОНА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          8.8.2. Организатор АУКЦИОНА имеет право аннулировать результаты
          проведенного АУКЦИОНА, в случае если будет установлено, что
          максимальная ставка за такой ЛОТ значительно выше, чем имеющиеся
          предложения на аналогичный вид ЛОТА на рынке недвижимости, а также в
          случае если будет установлено, что в АУКЦИОНЕ принимало участие и
          делало ставки лицо, которому принадлежит данный ЛОТ и по своему
          усмотрению применить санкции к Участнику, предусмотренные п.11.4
          настоящей ОФЕРТЫ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          8.9. Принимая участие в АУКЦИОНЕ, УЧАСТНИК предоставляет ОРГАНИЗАТОРУ
          свое безусловное согласие на использование внесенного ДЕПОЗИТА,
          совершенные с использованием зарегистрированного АККАУНТА УЧАСТНИКА на
          ЭЛЕКТРОННОЙ ПЛОЩАДКЕ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          8.10. УЧАСТНИК несет ответственность за все действия, совершенные с
          использованием зарегистрированного АККАУНТА УЧАСТНИКА. Дальнейшие
          претензии УЧАСТНИКА к ОРГАНИЗАТОРУ, связанные с возложением
          ответственности за действия третьих лиц, совершенные с использованием
          АККАУНТА УЧАСТНИКА, исключаются и считаются недопустимыми.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          8.11. УЧАСТНИК обязуется предоставлять ОРГАНИЗАТОРУ информацию об
          изменении сведений, предоставленных ОРГАНИЗАТОРУ при заключении
          ДОГОВОРА или при регистрации на ЭЛЕКТРОННОЙ ПЛОЩАДКЕ ОРГАНИЗАТОРА, в
          срок не более 3 (трех) календарных дней с момента изменения таких
          сведений. При неисполнении УЧАСТНИКОМ этой обязанности он принимает на
          себя неблагоприятные последствия такого неисполнения и возмещает
          ОРГАНИЗАТОРУ, понесенные в связи с этим убытки.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          8.12. Настоящим УЧАСТНИК подтверждает, что заключение ДОГОВОРА не
          является для него кабальной сделкой, совершенной под влиянием обмана,
          насилия, угрозы, а также подтверждает, что не ограничен и не лишен
          дееспособности, не состоит под опекой и попечительством, не страдает
          заболеваниями, препятствующими осознать суть ДОГОВОРА, а также
          отсутствуют обстоятельства, вынуждающие заключить данный ДОГОВОР.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          8.13. УЧАСТНИКУ известно, что за предоставление недостоверной
          информации, ее сокрытие или искажение предусмотрена гражданская,
          административная, а также уголовная ответственность в соответствие с
          действующим законодательством Российской Федерации.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          8.14. УЧАСТНИК обязуется не использовать товарный знак (логотип)
          ОРГАНИЗАТОРА без разрешения последнего. В случае вышеуказанного
          нарушения УЧАСТНИК обязуется уплатить ОРГАНИЗАТОРУ штраф в размере
          100% ДЕПОЗИТА УЧАСТНИКА, а ОРГАНИЗАТОР оставляет за собой право
          удержать штраф из ДЕПОЗИТА УЧАСТНИКА в полном объеме в случае
          неустранения нарушения УЧАСТНИКОМ в течение 3 (трех) дней с момента
          обращения ОРГАНИЗАТОРА. ОРГАНИЗАТОР фиксирует нарушения путем
          составления протокола осмотра интернет страниц и/или заверения
          интернет страниц или иным способом, позволяющим зафиксировать факт
          нарушения (копии фотоснимков экрана ПК (принт-скрин).
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          8.15 УЧАСТНИК гарантирует ОРГАНИЗАТОРУ, что не будет использовать
          информацию, полученную в процессе проведения АУКЦИОНА, в каких-либо
          иных целях, не связанных с заключением договора купли-продажи ЛОТА на
          ЭЛЕКТРОННОЙ ПЛОЩАДКЕ, включая, но не ограничиваясь, парсингом,
          копированием и использованием в коммерческих целях УЧАСТНИКОМ
          информации ОРГАНИЗАТОРА. В случае выявления ОРГАНИЗАТОРОМ фактов
          нарушения условий настоящего пункта, ОРГАНИЗАТОР оставляет за собой
          право в одностороннем порядке удержать ДЕПОЗИТ УЧАСТНИКА в полном
          размере и / или расторгнуть ДОГОВОР с УЧАСТНИКОМ.
        </DocumentSection.Paragraph>
      </DocumentSection>
      <DocumentSection>
        <DocumentSection.Title>
          9. ОТВЕТСТВЕННОСТЬ И ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ
        </DocumentSection.Title>
        <DocumentSection.Paragraph>
          9.1. За нарушение условий ДОГОВОРА Стороны несут ответственность,
          установленную действующим законодательством РФ в пределах,
          ограниченных ДОГОВОРОМ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          9.1. За нарушение условий ДОГОВОРА Стороны несут ответственность,
          установленную действующим законодательством РФ в пределах,
          ограниченных ДОГОВОРОМ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          9.2. ОРГАНИЗАТОР ни при каких обстоятельствах не несет никакой
          ответственности по ДОГОВОРУ за:
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          а) какие-либо действия/бездействие, являющиеся прямым или косвенным
          результатом действий/бездействия УЧАСТНИКА и/или третьих лиц;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          б) какие-либо косвенные убытки и/или упущенную выгоду УЧАСТНИКА и/или
          третьих сторон вне зависимости от того, явились ли такие убытки
          следствием действий/бездействия ОРГАНИЗАТОРА и/или мог ли ОРГАНИЗАТОР
          предвидеть возможность таких убытков или нет;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          в) использование (невозможность использования) и какие бы то ни было
          последствия использования (невозможности использования) УЧАСТНИКОМ
          выбранной им формы оплаты по ДОГОВОРУ, а равно
          использование/невозможность использования УЧАСТНИКОМ и/или третьими
          лицами любых средств и/или способов передачи/получения информации.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          9.3. Совокупный размер ответственности ОРГАНИЗАТОРА, включая размер
          штрафных санкций (пеней, неустоек) и/или возмещаемых убытков, по
          любому иску или претензии вытекающих из ДОГОВОРА или его исполнения,
          ограничивается 1% от стоимости соответствующего ЛОТА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          9.4. Стороны освобождаются от ответственности за частичное или полное
          неисполнение обязательств по ДОГОВОРУ, если это неисполнение явилось
          следствием обстоятельств непреодолимой силы, которые возникли после
          заключения ДОГОВОРА, либо если неисполнение обязательств Сторонами по
          ДОГОВОРУ явилось следствием событий чрезвычайного характера, которые
          Стороны не могли ни предвидеть, ни предотвратить разумными мерами. К
          обстоятельствам непреодолимой силы относятся события, на которые
          Сторона не может оказывать влияния и за возникновение которых она не
          несет ответственности, в том числе: война, восстание, забастовка,
          землетрясение, наводнение, иные стихийные бедствия, пожар, сбои
          энергоснабжения, произошедшие не по вине Сторон, действия и акты
          органов власти, принятые после заключения ДОГОВОРА и делающие
          невозможным исполнение обязательств, установленных ДОГОВОРОМ, и другие
          непредвиденные обстоятельства и неподконтрольные сторонам события и
          явления, но не ограничиваясь указанным.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          9.5. УЧАСТНИК несет ответственность в полном объеме за достоверность
          сведений, указанных им при регистрации в качестве УЧАСТНИКА, и
          достоверность гарантий и заверений УЧАСТНИКА, содержащихся в разделе 8
          ОФЕРТЫ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          9.6. УЧАСТНИК обязуется самостоятельно урегулировать все возможные
          претензии/иски, возникшие в связи с неисполнением УЧАСТНИКОМ п.9.5
          ДОГОВОРА, а также обязуется компенсировать ОРГАНИЗАТОРУ возможные
          убытки, которые могут возникнуть у последнего.
        </DocumentSection.Paragraph>
      </DocumentSection>
      <DocumentSection>
        <DocumentSection.Title>
          10. СРОК ДЕЙСТВИЯ И ИЗМЕНЕНИЕ УСЛОВИЙ ОФЕРТЫ
        </DocumentSection.Title>
        <DocumentSection.Paragraph>
          10.1. ОФЕРТА вступает в силу с момента размещения в сети Интернет по
          адресу https://minotower.ru/ и действует до момента отзыва ОФЕРТЫ
          ОРГАНИЗАТОРОМ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          10.2. ОРГАНИЗАТОР оставляет за собой право внести изменения в условия
          ОФЕРТЫ в любой момент по своему усмотрению. В случае внесения
          ОРГАНИЗАТОРОМ изменений в ОФЕРТУ, такие изменения вступают в силу с
          момента размещения измененного текста ОФЕРТЫ в сети Интернет по
          указанным в п.10.1 ОФЕРТЫ адресу, если иной срок вступления изменений
          в силу не определен дополнительно при таком размещении.
        </DocumentSection.Paragraph>
      </DocumentSection>
      <DocumentSection>
        <DocumentSection.Title>11. РАСТОРЖЕНИЕ ДОГОВОРА</DocumentSection.Title>
        <DocumentSection.Paragraph>
          11.1. ДОГОВОР может быть расторгнут по соглашению ОРГАНИЗАТОРА и
          УЧАСТНИКА. Также ДОГОВОР может быть расторгнут любой Стороной в
          одностороннем порядке, при условии информирования об этом другой
          Стороны не позднее, чем за 5 (пять) рабочих дней до предполагаемой
          даты расторжения ДОГОВОРА. ОРГАНИЗАТОР обязуется вернуть УЧАСТНИКУ
          внесенный Депозит в случае расторжения настоящего Договора в течение
          10 (десяти) банковских дней с даты расторжения Договора, кроме случаев
          расторжения, указанных в п. 11.4 ДОГОВОРА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          11.2. ОРГАНИЗАТОР также имеет право в одностороннем порядке
          расторгнуть ДОГОВОР, закрыть доступ УЧАСТНИКУ к АУКЦИОНУ и
          деактивировать АККАУНТ УЧАСТНИКА на ЭЛЕКТРОННОЙ ПЛОЩАДКЕ, в случаях,
          если:
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - УЧАСТНИК неоднократно (более двух раз) не выполняет / ненадлежащим
          образом выполняет свои обязательства перед ОРГАНИЗАТОРОМ;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - УЧАСТНИК более 90 (девяноста) дней подряд не участвует в АУКЦИОНЕ, а
          именно не делает СТАВОК с использованием АККАУНТА УЧАСТНИКА;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - УЧАСТНИК более 180 (ста восьмидесяти) дней подряд на АУКЦИОНЕ не
          выиграл ни одного ЛОТА, а именно не выиграла СТАВКА, сделанная с
          использованием АККАУНТА УЧАСТНИКА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - ОРГАНИЗАТОРОМ установлено, что при регистрации УЧАСТНИКА на
          ЭЛЕКТРОННОЙ ПЛОЩАДКЕ им были предоставлены недостоверные или
          неактуальные сведения о себе, либо УЧАСТНИК не выполнил требования
          пункта 8.12 ОФЕРТЫ;
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          - в иных случаях, прямо предусмотренных ДОГОВОРОМ, ПРАВИЛАМИ
          РАССМОТРЕНИЯ ПРЕТЕНЗИЙ, ПОЛИТИКОЙ КОНФИДЕНЦИАЛЬНОСТИ, и иными
          документами, регламентирующими порядок и условия проведения АУКЦИОНА.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          11.3. Расторжение ДОГОВОРА не освобождает ни одну из Сторон от полного
          возмещения произведенных другой Стороной убытков с ограничениями,
          предусмотренными положениями пункта 9.3 ОФЕРТЫ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          11.4. В случае расторжения ОФЕРТЫ, по причине нарушения УЧАСТНИКОМ
          условий ОФЕРТЫ, ПРАВИЛ РАССМОТРЕНИЯ ПРЕТЕНЗИЙ, ПОЛИТИКИ
          КОНФИДЕНЦИАЛЬНОСТИ, и иных документов, регламентирующих порядок и
          условия проведения АУКЦИОНА, на усмотрение ОРГАНИЗАТОРА АУКЦИОНА
          вправе заблокировать аккаунт УЧАСТНИКА, а УЧАСТНИК безусловно
          соглашается и обязуется уплатить ОРГАНИЗАТОРУ штраф в размере 100%
          ДЕПОЗИТА УЧАСТНИКА путем его удержания ОРГАНИЗАТОРОМ в качестве
          штрафных санкций в полном объеме ДЕПОЗИТА УЧАСТНИКА, перечисленный
          УЧАСТНИКОМ согласно п.4.10 ОФЕРТЫ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          11.5. В случае если по итогам применения ОРГАНИЗАТОРОМ штрафных
          санкций к УЧАСТНИКУ и его блокировке, в дальнейшем был повторно
          получен запрос от такого УЧАСТНИКА с просьбой о
          восстановлении/регистрации аккаунта и ОРГАНИЗАТОРОМ АУКЦИОНА было
          принято положительное решение о его допуске, УЧАСТНИК может
          восстановить свой допуск к платформе при условии внесения
          лицензионного платежа и депозита в 2 (двукратном) размере от
          установленного в настоящей Оферте как лицо, ранее совершившее
          нарушение.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          11.6. В случае восстановления ОРГАНИЗАТОРОМ АУКЦИОНА предоставления
          доступа УЧАСТНИКУ в порядке, предусмотренном п.11.5 настоящей Оферты,
          УЧАСТНИК безусловно соглашается на запрет вывода им с его аккаунта
          такого депозита в 2 (двукратном) размере или уменьшения его размера в
          срок ранее 185 (сто восемьдесят пять) дней с даты такого пополнения, и
          в будущем не будет оспаривать такой запрет УЧАСТНИКА в судебном или
          внесудебном порядке.
        </DocumentSection.Paragraph>
      </DocumentSection>
      <DocumentSection>
        <DocumentSection.Title>
          12. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ
        </DocumentSection.Title>
        <DocumentSection.Paragraph>
          12.1. Обмен информацией и документами, передаваемыми Сторонами друг
          другу в связи с заключением и исполнением ДОГОВОРА, осуществляется
          посредством электронной почты или технических средств ЭЛЕКТРОННОЙ
          ПЛОЩАДКИ, если иное не предусмотрено ДОГОВОРОМ. Стороны пришли к
          соглашению, что переданная информация или уведомление, сделанное одной
          Стороной другой Стороне на ЭЛЕКТРОННОЙ ПЛОЩАДКЕ через АККАУНТ
          УЧАСТНИКА, либо размещенное на ЭЛЕКТРОННОЙ ПЛОЩАДКЕ, либо размещённая
          на ЭЛЕКТРОННОЙ ПЛОЩАДКЕ, в личном кабинете УЧАСТНИКА, имеют такую же
          юридическую силу, как и информация/уведомление, предоставленные другой
          Стороне в письменном виде и заверенное подписью и печатью Стороны или
          ее уполномоченного лица.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          12.2. Не вступая в противоречие с условиями ОФЕРТЫ, ОРГАНИЗАТОР и
          УЧАСТНИК вправе в любое время оформить ДОГОВОР в форме письменного
          двухстороннего документа. В случае если одно или более положений
          ОФЕРТЫ являются по какой-либо причине недействительными, не имеющими
          юридической силы, такая недействительность не оказывает влияния на
          действительность любого другого положения ОФЕРТЫ (ДОГОВОРА), которые
          остаются в силе.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          12.3. Стороны договорились решать возникающие при выполнении ДОГОВОРА
          разногласия путем переговоров и согласно ПРАВИЛ РАССМОТРЕНИЯ
          ПРЕТЕНЗИЙ.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          12.4. Споры по данному Договору, по которым Стороны не пришли к
          соглашению, передаются на рассмотрение в Арбитражный суд г. Сочи или в
          суд общей юрисдикции, в зависимости от типа участника спора.
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          РЕКВИЗИТЫ ОРГАНИЗАТОРА:
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          Общество с ограниченной ответственностью «ЮГрупп» (ООО «ЮГрупп»)
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          Юридический адрес:
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          354037, Краснодарский край, г. Сочи, Хостинский р-н, тер. СНТ
          Сутугинское, дом 67, помещение 14,
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ИНН/КПП 2320115543/236701001
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ОГРН 11142366013910
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          Фактический адрес:
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          354037, Краснодарский край, г. Сочи, ул. Навагинская 9Д, офис 427
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>Реквизиты банка</DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          Получатель: ООО "ЮГРУПП"
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          ИНН клиента: 2320115543
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          КПП клиента: 236701001
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          Расчетный счет: 40702810110001608737
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          Название Банка: АО "ТБанк"
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          Кор.счет: 30101810145250000974
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          БИК банка: 044525974
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          Адрес размещения в сети Интернет: https://minotower.ru/
        </DocumentSection.Paragraph>
        <DocumentSection.Paragraph>
          Дата «12» ноября 2024 года
        </DocumentSection.Paragraph>
      </DocumentSection>
    </div>
  );
};
