import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { CommonFooter, CommonHeader, CommonLoader } from '@/common/components';
import { paths } from '@/common/constants';
import { useUser } from '@/common/hooks/use-user';
import { UserStoreContext } from '@/common/stores';

import styles from './navigation-layout.module.css';

export const NavigationLayout = observer(() => {
  const navigate = useNavigate();
  const userStore = useContext(UserStoreContext);
  const { isLoading: isUserLoading } = useUser();

  useEffect(() => {
    // If not authenticated => sign in
    if (!userStore.isAuthenticated) {
      navigate(paths.getSignInPath());
      return;
    }

    // If user loaded but not onboarded => onboarding
    if (userStore.user && !userStore.isOnboardingCompleted) {
      navigate(paths.getOnboardingPath());
    }
  }, [
    userStore.isAuthenticated,
    userStore.user,
    userStore.isOnboardingCompleted,
    navigate,
    userStore,
  ]);

  return isUserLoading ? (
    <div className={styles.loaderWrapper}>
      <CommonLoader />
    </div>
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <CommonHeader />
      </div>
      <div className={styles.content}>
        <Outlet />
        <CommonFooter />
      </div>
    </div>
  );
});
