import { Facility, PaginationMeta } from '@/common/entities';

import {
  PaginationMetaResponse,
  mapPaginationMetaResponseToPaginationMeta,
} from '../../mappers';
import { mapFacilityResponseToFacility } from '../../mappers/facilities.mapper';
import { request } from '../../request';
import { FacilityResponse } from '../../responses';

export const getFacilities = async (
  page = 1,
): Promise<[Facility[], PaginationMeta]> => {
  const responce = await request.get<{
    meta: PaginationMetaResponse;
    facilities: FacilityResponse[];
  }>('/v1/facilities', {
    params: {
      page,
    },
  });

  const paginationMeta = mapPaginationMetaResponseToPaginationMeta(
    responce.data?.meta,
  );

  const facilities = responce.data?.facilities.map((facility) =>
    mapFacilityResponseToFacility(facility),
  );

  return [facilities, paginationMeta];
};
