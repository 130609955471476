import { useContext } from 'react';

import { CommonIcon, CommonIconType } from '@/common/components';
import { UserStoreContext } from '@/common/stores';
import { getFormattedCurrency } from '@/common/utils';

import { useCommonHeaderRefillRequestModal } from '../common-header-refill-request-modal';
import { CommonHeaderRefillRequestModal } from '../common-header-refill-request-modal/common-header-refill-request-modal';
import styles from './common-header-balance.module.css';
import { CommonHeaderBalanceProps } from './common-header-balance.types';

export const CommonHeaderBalance = ({
  balance = 0,
}: CommonHeaderBalanceProps) => {
  const userStore = useContext(UserStoreContext);

  const {
    isCommonHeaderRefillRequestModalOpen,
    handleCommonHeaderRefillRequestModalClose,
    handleCommonHeaderRefillRequestModalOpen,
    commonHeaderRefillRequestModalContent,
    changeCommonHeaderRefillRequestModalContentToForm,
    changeCommonHeaderRefillRequestModalContentToSuccess,
  } = useCommonHeaderRefillRequestModal();

  const handleRefillRequest = async (values: { amount: number }) => {
    if (userStore.user?.id) {
      await userStore.requestRefill(
        {
          amount: values.amount,
        },
        changeCommonHeaderRefillRequestModalContentToSuccess,
      );
    }
  };

  return (
    <>
      <button
        className={styles.balanceWrapper}
        onClick={handleCommonHeaderRefillRequestModalOpen}
      >
        <div className={styles.iconWrapper}>
          <CommonIcon type={CommonIconType.Plus} className={styles.icon} />
        </div>
        <span>{getFormattedCurrency(balance)}</span>
      </button>
      <CommonHeaderRefillRequestModal
        isOpen={isCommonHeaderRefillRequestModalOpen}
        onClose={handleCommonHeaderRefillRequestModalClose}
        onSubmit={handleRefillRequest}
        modalContent={commonHeaderRefillRequestModalContent}
        onRequestForm={changeCommonHeaderRefillRequestModalContentToForm}
      />
    </>
  );
};
