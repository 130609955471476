import { observer } from 'mobx-react-lite';
import Skeleton from 'react-loading-skeleton';
import { v4 as uuidv4 } from 'uuid';

import {
  CommonEmptyPlaceholder,
  CommonErrorPlaceholder,
} from '@/common/components';
import { Breadcrumbs } from '@/common/components/breadcrumbs';

import { FacilityCard } from '../../components';
import { useRoundFacilitiesListView } from './round-facilities-view.domain';
import styles from './round-facilities-view.module.css';

export const RoundFacilitiesView = observer(() => {
  const {
    facilities,
    facilitiesAreEmpty,
    facilitiesHaveError,
    facilitiesAreLoading,
    title,
    breadcrumbs,
  } = useRoundFacilitiesListView();
  return (
    <div className={styles.wrapper}>
      {facilitiesAreLoading ? (
        <>
          <Skeleton className={styles.breadcrumbsSkeleton} />
          <Skeleton className={styles.titleSkeleton} />
          <div className={styles.loadingWrapper}>
            <div className={styles.facilitiesList}>
              {Array.from({ length: 6 }).map(() => (
                <FacilityCard.Skeleton key={uuidv4()} />
              ))}
            </div>
          </div>
        </>
      ) : null}
      {facilitiesAreEmpty ? (
        <div className={styles.placeholderWrapper}>
          <CommonEmptyPlaceholder />
        </div>
      ) : null}
      {facilitiesHaveError ? (
        <div className={styles.placeholderWrapper}>
          <CommonErrorPlaceholder />
        </div>
      ) : null}
      <div>
        <Breadcrumbs breadcrumbs={breadcrumbs} className={styles.breadcrumbs} />
        <div className={styles.title}>{title}</div>
        <div className={styles.facilitiesList}>
          {facilities.map((facility) => (
            <FacilityCard facility={facility} key={facility.id} />
          ))}
        </div>
      </div>
    </div>
  );
});
