import classNames from 'classnames';

import styles from './partner-program-section-wrapper.module.css';
import { PartnerProgramSectionWrapperProps } from './partner-program-section-wrapper.types';

export const PartnerProgramSectionWrapper = ({
  children,
  className,
}: PartnerProgramSectionWrapperProps) => {
  return (
    <div className={classNames(styles.partnerProgramSectionWrapper, className)}>
      <div className={styles.partnerProgramSectionWrapperContent}>
        {children}
      </div>
    </div>
  );
};
