import { Link } from 'react-router-dom';

import { CommonIcon } from '@/common/components/common-icon';

import styles from './common-header-menu-modal-link.module.css';
import { CommonHeaderMenuModalLinkProps } from './common-header-menu-modal-link.types';

export const CommonHeaderMenuModalLink = ({
  to,
  iconType,
  children,
  onClick,
}: CommonHeaderMenuModalLinkProps) => {
  const content = (
    <>
      <CommonIcon type={iconType} className={styles.menuLinkIcon} />
      <span className={styles.menuLinkText}>{children}</span>
    </>
  );

  if (to) {
    return (
      <Link to={to} className={styles.menuLink} onClick={onClick}>
        {content}
      </Link>
    );
  }

  return (
    <button className={styles.menuLink} onClick={onClick}>
      {content}
    </button>
  );
};
