import { FC } from 'react';
import { Link } from 'react-router-dom';

import { paths } from '@/common/constants';

import styles from './main-section.module.css';

interface LandingMainSectionProps {
  readonly isReferralCodeExists: boolean;
}

export const Main: FC<LandingMainSectionProps> = ({
  isReferralCodeExists = false,
}) => (
  <section className={styles.hero}>
    <div className={styles.heroText}>
      <div className={styles.mainText}>
        Аукцион недвижимости{' '}
        <span className={styles.heroSpanText}>от застройщиков</span>
      </div>
      <div className={styles.descriptionText}>
        Возможность купить недвижимость до 40% ниже рыночной стоимости
      </div>
      <Link
        to={
          isReferralCodeExists ? paths.getSignUpPath() : paths.getSignInPath()
        }
      >
        <button className={styles.heroButton}>Начать инвестировать</button>
      </Link>
    </div>
    <img
      className={styles.heroImage}
      src="/images/landing/landing-main-section-image.png"
    />
  </section>
);
