import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/zoom';
import { Navigation, Zoom } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CommonIcon, CommonIconType, CommonModal } from '@/common/components';

import styles from './facility-schemas-modal.module.css';
import { FacilitySchemasModalProps } from './facility-schemas-modal.types';

export const FacilitySchemasModal = ({
  isOpen = false,
  onClose,
  schemas,
  slideIndex = 0,
}: FacilitySchemasModalProps) => {
  return (
    <CommonModal
      title="Планировка"
      isOpen={isOpen}
      onClose={onClose}
      modalClassName={styles.modal}
    >
      <div className={styles.content}>
        <Swiper
          initialSlide={slideIndex}
          spaceBetween={10}
          slidesPerView={1}
          zoom
          loop
          navigation={{
            nextEl: `.${styles.nextButton}`,
            prevEl: `.${styles.prevButton}`,
          }}
          modules={[Zoom, Navigation]}
        >
          {schemas?.map((schema, index) => (
            <SwiperSlide key={schema.id} className={styles.schemeContainer}>
              <div className="swiper-zoom-container">
                <img
                  src={schema.photo.path}
                  alt={`Facility Schema ${index + 1}`}
                />
              </div>
            </SwiperSlide>
          ))}

          <div className={styles.prevButton}>
            <CommonIcon
              className={styles.prevButtonIcon}
              type={CommonIconType.ChevronLeft}
            />
          </div>
          <div className={styles.nextButton}>
            <CommonIcon
              className={styles.nextButtonIcon}
              type={CommonIconType.ChevronRight}
            />
          </div>
        </Swiper>
      </div>
    </CommonModal>
  );
};
