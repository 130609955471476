import { CommonButton } from '../common-button';
import { CommonIcon, CommonIconType } from '../common-icon';
import styles from './common-success-modal-content.module.css';
import { CommonSuccessModalContentProps } from './common-success-modal-content.types';

export const CommonSuccessModalContent = ({
  title,
  text,
  onClose,
}: CommonSuccessModalContentProps) => {
  return (
    <div className={styles.content}>
      <div className={styles.iconWrapper}>
        <CommonIcon type={CommonIconType.Check} className={styles.checkIcon} />
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.text}>{text}</div>
      <CommonButton className={styles.button} onClick={onClose}>
        Перейти к лоту
      </CommonButton>
    </div>
  );
};
