import { Dropdown, MenuProps } from 'antd';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { CommonIcon, CommonIconType } from '@/common/components';
import { paths } from '@/common/constants';
import { UserStoreContext } from '@/common/stores';

import styles from './common-header-profile.module.css';

export const CommonHeaderProfile = observer(() => {
  const navigate = useNavigate();
  const userStore = useContext(UserStoreContext);

  const handleLogout = () => {
    userStore.logout();
    navigate(paths.getSignInPath());
  };

  const items: MenuProps['items'] = [
    {
      key: 'profile',
      label: (
        <Link to={paths.getProfilePath()} className={styles.menuButton}>
          <CommonIcon type={CommonIconType.User} className={styles.menuIcon} />
          Профиль
        </Link>
      ),
    },
    {
      key: 'logout',
      label: (
        <div className={styles.menuButton} onClick={handleLogout}>
          <CommonIcon
            type={CommonIconType.LogoutCircle}
            className={styles.menuIcon}
          />
          Выйти
        </div>
      ),
    },
  ];

  return (
    <Dropdown menu={{ items }} placement="bottomLeft">
      <Link to={paths.getProfilePath()} className={styles.profileLink}>
        <div className={styles.iconWrapper}>
          <CommonIcon type={CommonIconType.User} className={styles.icon} />
        </div>
        <span>Профиль</span>
      </Link>
    </Dropdown>
  );
});
