import { useMemo } from 'react';

import { useApi } from '@/common/api';

import { FacilitiesStoreContext } from './facilites.context';
import { FacilitiesStore } from './facilities-store';

export const FacilitiesStoreInjector = ({
  children,
}: {
  readonly children: React.ReactNode;
}) => {
  const api = useApi();

  const facilitiesStore = useMemo(() => new FacilitiesStore(api), [api]);

  return (
    <FacilitiesStoreContext.Provider value={facilitiesStore}>
      {children}
    </FacilitiesStoreContext.Provider>
  );
};
