import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { UserGeneralInfoProps } from '@/common/api/requests';
import { CommonIcon, CommonIconType } from '@/common/components';
import { UserStoreContext } from '@/common/stores';
import { formatPhoneNumber } from '@/common/utils';

import { ProfileSectionContainer } from '../profile-section-container';
import {
  CommonUserEditModal,
  useCommonUserEditModal,
} from './components/common-user-edit-modal';
import styles from './general-info-section.module.css';
import { GeneralInfoSectionProps } from './general-info-section.types';

export const GeneralInfoSection = observer(
  ({ user }: GeneralInfoSectionProps) => {
    const userStore = useContext(UserStoreContext);

    const fullName = [user?.firstName, user?.lastName]
      .filter(Boolean)
      .join(' ');

    const {
      isCommonUserEditModalOpen,
      handleCommonUserEditModalClose,
      handleCommonUserEditModalOpen,
    } = useCommonUserEditModal();

    const handleUserEdit = async (values: UserGeneralInfoProps) => {
      if (user?.id) {
        await userStore.updateUser({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
        });
        handleCommonUserEditModalClose();
      }
    };

    return (
      <ProfileSectionContainer>
        <CommonUserEditModal
          isOpen={isCommonUserEditModalOpen}
          onClose={handleCommonUserEditModalClose}
          onSubmit={handleUserEdit}
          isSubmitLoading={userStore.isUserUpdateLoading}
          user={user}
        />
        <div className={styles.generalInfoWrapper}>
          <div className={styles.avatar}>
            <CommonIcon
              type={CommonIconType.User}
              className={styles.avatarIcon}
            />
          </div>
          <div className={styles.userInfoWrapper}>
            <div className={styles.userInfo}>
              <span className={styles.name}>{fullName || 'Нет данных'}</span>
              <span className={styles.phoneNumber}>
                {user?.phoneNumber
                  ? formatPhoneNumber(user?.phoneNumber)
                  : 'Нет данных'}
              </span>
            </div>
            <div
              className={styles.editButton}
              onClick={handleCommonUserEditModalOpen}
            >
              <CommonIcon
                type={CommonIconType.Edit}
                className={styles.editIcon}
              />
            </div>
          </div>
        </div>
      </ProfileSectionContainer>
    );
  },
);
