import { Form } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useEffect, useState } from 'react';

import { CommonButton } from '@/common/components/common-button';
import { CommonIcon, CommonIconType } from '@/common/components/common-icon';
import { CommonInput } from '@/common/components/common-input';
import { CommonModal } from '@/common/components/common-modal';
import { CommonSuccessModalContent } from '@/common/components/common-success-modal-content/common-success-modal-content';
import { SupportContact } from '@/common/constants';

import { ModalContent } from './common-header-refill-request-modal.domain';
import styles from './common-header-refill-request-modal.module.css';
import { CommonHeaderRefillRequestModalProps } from './common-header-refill-request-modal.types';

export const CommonHeaderRefillRequestModal = ({
  isOpen = false,
  onClose,
  onSubmit,
  isSubmitLoading = false,
  modalContent = ModalContent.Explanation,
  onRequestForm,
}: CommonHeaderRefillRequestModalProps) => {
  const [modalTitle, setModalTitle] = useState<string>('Пополнение депозита');

  useEffect(() => {
    if (modalContent === ModalContent.Explanation) {
      setModalTitle('Пополнение депозита');
    } else if (modalContent === ModalContent.Form) {
      setModalTitle('Заявка на пополнение');
    } else if (modalContent === ModalContent.Success) {
      setModalTitle('Заявка');
    }
  }, [modalContent]);

  const handleSubmit = (values: { amount: string }) => {
    const rawAmount = Number(values.amount.replace(/\s/gu, ''));

    if (onSubmit) {
      onSubmit({
        amount: rawAmount,
      });
    }
  };
  return (
    <CommonModal title={modalTitle} isOpen={isOpen} onClose={onClose}>
      {modalContent === ModalContent.Explanation && (
        <div className={styles.content}>
          <div className={styles.container}>
            <div className={styles.titleText}>
              <CommonIcon
                type={CommonIconType.CoinWhite}
                className={styles.coinIcon}
              />
              <span>Минимальный возвратный депозит</span>
            </div>
            <div className={styles.amount}>1 000 ₽</div>
            <div className={styles.text}>
              После пополнения, менеджер зачислит на ваш депозит эту сумму и
              можно делать ставки
            </div>
            <div className={styles.text}>
              Если вам нужна помощь, свяжитесь с{' '}
              <a
                href={SupportContact.Telegram}
                target="_blank"
                rel="noreferrer"
                className={styles.supportLink}
              >
                менеджером
              </a>
            </div>
          </div>
          <CommonButton className={styles.submitButton} onClick={onRequestForm}>
            Заявка на пополнение
          </CommonButton>
        </div>
      )}
      {modalContent === ModalContent.Form && (
        <div className={styles.content}>
          <Form
            layout="vertical"
            className={styles.form}
            onFinish={handleSubmit}
          >
            <FormItem
              label="Сумма пополнения"
              name="amount"
              rules={[
                {
                  required: true,
                  message: 'Введите сумму пополнения',
                },
              ]}
            >
              <CommonInput
                className={styles.input}
                type="text"
                placeholder="Введите сумму пополнения"
                min={1}
                mask={Number}
                radix=","
                mapToRadix={['.']}
                thousandsSeparator=" "
                scale={0}
                inputMode="numeric"
              />
            </FormItem>
            <CommonButton
              type="submit"
              isLoading={isSubmitLoading}
              className={styles.submitButton}
            >
              Подтвердить
            </CommonButton>
          </Form>
        </div>
      )}

      {modalContent === ModalContent.Success && (
        <CommonSuccessModalContent
          title="Заявка на пополнение отправлена!"
          text="Персональный менеджер Татьяна свяжется с вами в течении нескольких минут и предоставит инструкцию"
          onClose={onClose}
        />
      )}
    </CommonModal>
  );
};
