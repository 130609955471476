export enum FacilityType {
  Flat = 1,
  Apartment = 2,
  Residential = 3,
  NonResidential = 4,
  House = 5,
  Townhouse = 6,
  Plot = 7,
  Hotel = 8,
  Office = 9,
  CateringPremises = 10,
  GeneralPurpose = 11,
  IndustrialPremises = 12,
  Warehouse = 13,
  RetailPremises = 14,
  CarService = 15,
  Building = 16,
  Parking = 17,
}
