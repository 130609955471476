import { RouteObject } from 'react-router-dom';

import { NavigationLayout, UserLayout } from '@/common/layouts';
import {
  AuthLayout,
  OnboardingView,
  SignInView,
  SignUpView,
} from '@/modules/auth';
import { DocumentsNavigationLayout } from '@/modules/documents/layouts';
import { UserAgreementView } from '@/modules/documents/views';
import {
  DetailFacilityView,
  FacilitiesListView,
  RoundFacilitiesView,
} from '@/modules/facilities';
import { LandingView } from '@/modules/landing/views/landing-view/landing-view';
import { ProfileView } from '@/modules/profile';
import { PartnerEventsView } from '@/modules/referral-system/views/partner-events-view/partner-events-view';
import { PartnerProgramView } from '@/modules/referral-system/views/partner-program-view/partner-program-view';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <LandingView />,
  },
  {
    path: 'documents',
    element: <DocumentsNavigationLayout />,
    children: [
      {
        path: 'user-agreement',
        element: <UserAgreementView />,
      },
    ],
  },
  {
    path: '/',
    element: <UserLayout />,
    children: [
      {
        path: '/auth',
        element: <AuthLayout />,
        children: [
          {
            path: 'sign-in',
            element: <SignInView />,
          },
          {
            path: 'sign-up',
            element: <SignUpView />,
          },
          {
            path: 'onboarding',
            element: <OnboardingView />,
          },
        ],
      },
      {
        path: '/',
        element: <NavigationLayout />,
        children: [
          {
            path: '/home',
            element: <FacilitiesListView />,
          },
          {
            path: '/profile',
            element: <ProfileView />,
          },
          {
            path: '/facilities/:facilityId',
            element: <DetailFacilityView />,
          },
          {
            path: '/rounds/:roundId',
            element: <RoundFacilitiesView />,
          },
          {
            path: '/partner-program',
            element: <PartnerProgramView />,
          },
          {
            path: '/partner-events',
            element: <PartnerEventsView />,
          },
        ],
      },
    ],
  },
];
