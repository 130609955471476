import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { UserStoreContext } from '@/common/stores';

import styles from './common-header.module.css';
import {
  CommonHeaderBalance,
  CommonHeaderGuide,
  CommonHeaderLogo,
  CommonHeaderMenuButton,
  CommonHeaderProfile,
} from './components';

/**
 * Component of universal header
 * @returns JSX.Element
 */
export const CommonHeader = observer(() => {
  const userStore = useContext(UserStoreContext);

  return (
    <header className={styles.wrapper}>
      <div className={styles.content}>
        <CommonHeaderLogo />
        <div className={styles.row}>
          <div className={styles.guideWrapper}>
            <CommonHeaderGuide />
          </div>
          <div className={styles.balanceWrapper}>
            <CommonHeaderBalance balance={userStore.user?.balance ?? 0} />
          </div>
          <div className={styles.profileWrapper}>
            <CommonHeaderProfile />
          </div>
          <div className={styles.menuButtonWrapper}>
            <CommonHeaderMenuButton />
          </div>
        </div>
      </div>
    </header>
  );
});
