import { observer } from 'mobx-react-lite';

import { useReferralCode } from '@/common/hooks';
import { Main } from '@/modules/landing/components/main-section/main-section';

import {
  ContactUsSection,
  LandingFooter,
  LandingHeader,
} from '../../components';
import { Faq } from '../../components/faq-section/faq-section';
import { Steps } from '../../components/steps/steps';
import styles from './landing-view.module.css';

export const LandingView = observer(() => {
  const { isReferralCodeExists, isLoading } = useReferralCode();

  return (
    <div className={styles.wrapper}>
      <LandingHeader
        isReferralCodeExists={isReferralCodeExists}
        isLoading={isLoading}
      />
      <Main isReferralCodeExists={isReferralCodeExists} />
      <Steps />
      <Faq />
      <ContactUsSection isReferralCodeExists={isReferralCodeExists} />
      <LandingFooter />
    </div>
  );
});
