/* eslint-disable camelcase */
import { UserRole } from '@/common/constants';
import { User } from '@/common/entities';

import { mapUserResponseToUser } from '../../mappers';
import { request } from '../../request';
import { UserResponse } from '../../responses';

export interface OnboardUserProps {
  firstName: string;
  lastName: string;
  email: string | null;
  role: UserRole;
}

export const onboardUser = async (payload: OnboardUserProps): Promise<User> => {
  const response = await request.post<{
    user: UserResponse;
  }>(`/v1/users/me/onboard`, {
    first_name: payload.firstName,
    last_name: payload.lastName,
    email: payload.email,
    role: payload.role,
  });

  const userResponce = response.data.user;

  return mapUserResponseToUser(userResponce);
};
