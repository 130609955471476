import {
  CommonFooterContacts,
  CommonFooterCopyright,
  CommonFooterLogo,
} from '@/common/components/common-footer/components';

import { LandingFooterLinks } from './components';
import styles from './landing-footer.module.css';

export const LandingFooter = () => (
  <footer className={styles.wrapper}>
    <div className={styles.content}>
      <div className={styles.row}>
        <CommonFooterLogo />
        <LandingFooterLinks />
        <CommonFooterContacts />
      </div>
      <CommonFooterCopyright />
    </div>
  </footer>
);
