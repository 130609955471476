import { useLocation, useNavigate } from 'react-router-dom';

import { Breadcrumb } from './breadcrumbs';

export const useBreadcrumbs = (breadcrumbs: Breadcrumb[]) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    const previousBreadcrumb = breadcrumbs[breadcrumbs.length - 2];
    navigate(previousBreadcrumb.path);
  };

  const checkIfBreadcrumbActive = (path: string) => location.pathname === path;

  const handleBreadcrumbClick = (path: string) => {
    if (path === location.pathname) return;
    navigate(path);
  };

  return {
    handleBackButtonClick,
    checkIfBreadcrumbActive,
    handleBreadcrumbClick,
  };
};
