import { CommonIconType } from '@/common/components/common-icon';
import { CommonModal } from '@/common/components/common-modal';
import {
  CommonSupportCard,
  CommonSupportCardType,
} from '@/common/components/common-support-card';
import { paths } from '@/common/constants';

import styles from './common-header-menu-modal.module.css';
import { CommonHeaderMenuModalProps } from './common-header-menu-modal.types';
import { CommonHeaderMenuModalLink } from './components';

export const CommonHeaderMenuModal = ({
  isOpen,
  onClose,
  onLogout,
  onGuideModalOpen,
}: CommonHeaderMenuModalProps) => {
  return (
    <CommonModal title="Меню" isOpen={isOpen} onClose={onClose}>
      <div className={styles.content}>
        <CommonHeaderMenuModalLink
          to={paths.getProfilePath()}
          iconType={CommonIconType.User}
          onClick={onClose}
        >
          Личный кабинет
        </CommonHeaderMenuModalLink>
        <CommonHeaderMenuModalLink
          iconType={CommonIconType.InfoCirlce}
          onClick={onGuideModalOpen}
        >
          Инструкция
        </CommonHeaderMenuModalLink>
        <CommonHeaderMenuModalLink
          iconType={CommonIconType.LogoutCircle}
          onClick={onLogout}
        >
          Выйти
        </CommonHeaderMenuModalLink>
        <div className={styles.supportCards}>
          <CommonSupportCard
            type={CommonSupportCardType.WhatsApp}
            onClick={onClose}
          />
          <CommonSupportCard
            type={CommonSupportCardType.Telegram}
            onClick={onClose}
          />
        </div>
      </div>
    </CommonModal>
  );
};
