import classNames from 'classnames';
import { useState } from 'react';

import { CommonButton, CommonIcon, CommonIconType } from '@/common/components';

import { PartnerProgramSectionWrapper } from '../partner-program-section-wrapper';
import styles from './partner-program-news-channel.module.css';

export const PartnerProgramNewsChannel = () => {
  const [isCopied, setIsCopied] = useState(false);

  const link = 'https://t.me/+YUswjl-ZuMY3M2Zi';

  const copyLink = () => {
    navigator.clipboard.writeText(link).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  };

  const referToLink = () => {
    window.open(link, '_blank');
  };

  return (
    <PartnerProgramSectionWrapper>
      <div className={styles.title}>Партнерский канал новостей</div>
      <p className={styles.text}>
        Этот канал только для партнеров, <br className={styles.textBreaker} />{' '}
        делиться им с участниками не нужно
      </p>
      <div className={styles.linkWrapper}>
        <input
          className={classNames(
            styles.linkInput,
            isCopied && styles.linkInputCopied,
          )}
          type="text"
          value={link}
          readOnly
        />
        <span onClick={copyLink}>
          <CommonIcon type={CommonIconType.Copy} className={styles.copyIcon} />
        </span>
        <CommonIcon
          type={CommonIconType.CheckCircle}
          className={classNames(
            styles.checkIcon,
            isCopied && styles.checkIconVisible,
          )}
        />
      </div>
      <CommonButton className={styles.linkButton} onClick={referToLink}>
        <div className={styles.telegramIconWrapper}>
          <CommonIcon type={CommonIconType.Telegram} />
        </div>
        <span className={styles.linkButtonText}>Перейти</span>
      </CommonButton>
    </PartnerProgramSectionWrapper>
  );
};
