import { Form } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { CommonButton, CommonInput } from '@/common/components';
import { SupportContact, paths } from '@/common/constants';

import styles from './auth-code-form.module.css';
import { AuthCodeFormProps } from './auth-code-form.types';

export const AuthCodeForm = ({
  isSubmitLoading = false,
  requestedPhoneNumber,
  submitButtonText,
  onSubmit,
}: AuthCodeFormProps) => {
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const handleSubmit = (values: { code: string }) => {
    if (onSubmit) {
      onSubmit?.(values.code);
    }
  };

  const handleSubmitFailed = () => {
    if (!hasSubmitted) {
      setHasSubmitted(true);
    }
  };

  const message = 'Здравствуйте, подскажите пожалуйста код для входа';
  const encodedMessage = encodeURIComponent(message);

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit}
      onFinishFailed={handleSubmitFailed}
      className={styles.authCodeForm}
    >
      <div className={styles.authCodeFormContent}>
        <h2 className={styles.authCodeFormTitle}>Код подтверждения</h2>
        <p className={styles.authCodeFormCaption}>
          Мы отправили код подтерждения на номер {requestedPhoneNumber}
        </p>
        <FormItem
          name="code"
          validateTrigger={hasSubmitted ? 'onChange' : 'onSubmit'}
          rules={[
            {
              required: true,
              message: 'Введите код подтверждения',
            },
            {
              min: 4,
              message: 'Код подтверждения должен содержать 4 цифры',
            },
          ]}
        >
          <CommonInput
            type="number"
            mask="0000"
            placeholder="Код подтверждения"
            className={styles.authCodeFormInput}
          />
        </FormItem>
        <CommonButton
          type="submit"
          className={styles.authCodeFormButton}
          isLoading={isSubmitLoading}
        >
          {submitButtonText}
        </CommonButton>
        <p className={styles.authCodeFormPolicy}>
          Продолжая, вы соглашаетесь с{' '}
          <Link to={paths.getUserAgreementPath()} className={styles.policyLink}>
            пользовательским соглашением
          </Link>
        </p>

        <p className={styles.authCodeFormPolicy}>
          Не пришел код?{' '}
          <Link
            to={`${SupportContact.WhatsApp}?text=${encodedMessage}`}
            target="_blank"
            rel="noreferrer"
            className={styles.policyLink}
          >
            Запросить у менеджера
          </Link>
        </p>
      </div>
    </Form>
  );
};
