import { useQuery } from '@tanstack/react-query';
import { useLocation, useParams } from 'react-router-dom';

import { getOneRound } from '@/common/api/requests/rounds/get-one-round';
import { Breadcrumb } from '@/common/components/breadcrumbs';
import { paths } from '@/common/constants';
import { Facility, Round } from '@/common/entities';

export const useRoundFacilitiesListView = () => {
  const location = useLocation();
  const params = useParams<{ roundId: string }>();

  const { data, isPending, isError, isFetching } = useQuery<Round>({
    queryKey: ['round', params.roundId],
    queryFn: () => getOneRound(params.roundId || ''),
    enabled: Boolean(params.roundId),
  }) as {
    data?: Round;
    isPending: boolean;
    isError: boolean;
    isFetching: boolean;
  };

  const facilities: Facility[] = data?.facilities || [];

  const facilitiesAreEmpty = !isPending && !isError && facilities.length === 0;
  const facilitiesHaveError = isError && !isPending;
  const facilitiesAreLoading = isPending;
  const facilitiesAreFetching = isFetching;

  const title = data?.name || '';

  const breadcrumbs: Breadcrumb[] = [
    { label: 'Главная', path: paths.getHomePath() },
    {
      label: title,
      path: location.pathname,
    },
  ];

  return {
    facilities,
    facilitiesAreEmpty,
    facilitiesHaveError,
    facilitiesAreLoading,
    facilitiesAreFetching,
    title,
    breadcrumbs,
  };
};
