import { ReferrerResponse } from '../responses/referrer.response';

export const mapReferrerResponseToReferrer = (referrer: ReferrerResponse) => {
  return {
    id: referrer.id,
    name: referrer.name,
    description: referrer.description,
    referralCode: referrer.referral_code,
  };
};
