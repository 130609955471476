import { useState } from 'react';

export const useCommonHeaderMenuModal = () => {
  const [isCommonHeaderMenuModalOpen, setIsCommonHeaderMenuModalOpen] =
    useState(false);

  const handleCommonHeaderMenuModalClose = () => {
    setIsCommonHeaderMenuModalOpen(false);
  };

  const handleCommonHeaderMenuModalOpen = () => {
    setIsCommonHeaderMenuModalOpen(true);
  };

  return {
    isCommonHeaderMenuModalOpen,
    handleCommonHeaderMenuModalClose,
    handleCommonHeaderMenuModalOpen,
  };
};
