import { useMemo, useState } from 'react';

import { FacilityType } from '@/common/constants';
import { Facility } from '@/common/entities';
import { getFormattedCurrency } from '@/common/utils';

export const useFacilityMainInfoSection = (facility: Facility) => {
  const showFacilityType = useMemo(() => {
    const facilityTypeNames = {
      [FacilityType.Flat]: 'Квартира',
      [FacilityType.Apartment]: 'Апартамент',
      [FacilityType.Residential]: 'Жилое помещение',
      [FacilityType.NonResidential]: 'Нежилое помещение',
      [FacilityType.House]: 'Дом',
      [FacilityType.Townhouse]: 'Таунхаус',
      [FacilityType.Plot]: 'Участок',
      [FacilityType.Hotel]: 'Гостиница',
      [FacilityType.Office]: 'Офисное помещение',
      [FacilityType.CateringPremises]: 'Помещение общественного питания',
      [FacilityType.GeneralPurpose]: 'Помещение свободного назначения',
      [FacilityType.IndustrialPremises]: 'Производственное помещение',
      [FacilityType.Warehouse]: 'Складское помещение',
      [FacilityType.RetailPremises]: 'Торговое помещение',
      [FacilityType.CarService]: 'Автосервис',
      [FacilityType.Building]: 'Здание',
      [FacilityType.Parking]: 'Парковка',
    };

    if (facility && facility.type !== null) {
      const facilityTypeName = facilityTypeNames[facility.type];
      return facilityTypeName || 'Неизвестный тип';
    }

    return 'Неизвестный тип';
  }, [facility]);

  const locationPropertyContent = useMemo(() => {
    return [facility.city?.name, facility.name].filter(Boolean).join(', ');
  }, [facility]);

  const areaM2PropertyContent = useMemo(
    () => `${facility.areaM2} м²`,
    [facility],
  );

  const showMaxBetPropertyContent = useMemo(() => {
    const bet =
      facility?.bet !== undefined && facility.bet !== 0
        ? getFormattedCurrency(facility.bet, true)
        : getFormattedCurrency(facility.minBet ?? 0, true);

    return bet;
  }, [facility]);

  const showMinBetStep = useMemo(() => {
    const minBetStep =
      facility.betStep !== undefined && facility.betStep !== 0
        ? getFormattedCurrency(facility.betStep, true)
        : null;
    return minBetStep;
  }, [facility]);

  const showPricePerMeter = useMemo(() => {
    if (!facility.minBet || !facility.areaM2) {
      return null;
    }

    const price = facility.bet || facility.minBet;

    const pricePerMeter = Math.round(price / facility.areaM2 / 1000) * 1000;

    const formattedPricePerMeter = `${getFormattedCurrency(pricePerMeter)}/м²`;

    return formattedPricePerMeter;
  }, [facility]);

  const title = useMemo(() => {
    return [facility.city?.name, facility.name, areaM2PropertyContent]
      .filter(Boolean)
      .join(', ');
  }, [areaM2PropertyContent, facility.city?.name, facility.name]);

  const [showMap, setShowMap] = useState(false);

  const handleShowMap = () => {
    setShowMap(true);
  };

  const handleHideMap = () => {
    setShowMap(false);
  };

  return {
    title,
    locationPropertyContent,
    showFacilityType,
    areaM2PropertyContent,
    showMaxBetPropertyContent,
    showMinBetStep,
    showPricePerMeter,
    showMap,
    handleShowMap,
    handleHideMap,
  };
};
