import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { paths } from '@/common/constants';
import { UserStoreContext } from '@/common/stores';

import { AuthHeader } from '../../components';

export const AuthLayout = observer(() => {
  const navigate = useNavigate();
  const userStore = useContext(UserStoreContext);

  useEffect(() => {
    if (
      userStore.isAuthenticated &&
      userStore.user &&
      userStore.isOnboardingCompleted
    ) {
      navigate(paths.getHomePath());
      return;
    }

    if (
      userStore.isAuthenticated &&
      userStore.user &&
      !userStore.isOnboardingCompleted
    ) {
      navigate(paths.getOnboardingPath());
    }
  }, [
    navigate,
    userStore.isAuthenticated,
    userStore.user,
    userStore.isOnboardingCompleted,
  ]);

  return (
    <div>
      <AuthHeader />
      <Outlet />
    </div>
  );
});
