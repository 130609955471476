import { Tag } from 'antd';
import { useMemo } from 'react';

import { FacilityClass, FacilityContractType } from '@/common/constants';

import { RoundTagsProps } from './round-tags.types';

export const CommonRoundTags = ({ round }: RoundTagsProps) => {
  const comissionDate = useMemo(() => {
    if (round.isCommissioned) {
      return 'Сдан';
    }

    const comissionDate = `Срок сдачи ${round.commissioningQuarter} / ${round.commissioningYear}`;

    if (round.commissioningQuarter && round.commissioningYear) {
      return comissionDate;
    }

    return null;
  }, [round]);

  const contractType = useMemo(() => {
    const contractTypeNames = {
      [FacilityContractType.PurchaseAndSaleAgreement]: 'ДКП',
      [FacilityContractType.SharedParticipationAgreement]: 'ДДУ',
      [FacilityContractType.Miscellaneous]: 'Другое',
    };

    if (round && round.contractType !== null) {
      const contractTypeName = contractTypeNames[round.contractType];
      return contractTypeName || null;
    }

    return null;
  }, [round]);

  const roundClass = useMemo(() => {
    const roundClassNames = {
      [FacilityClass.Economy]: 'Эконом-класс',
      [FacilityClass.Business]: 'Бизнес-класс',
      [FacilityClass.Elite]: 'Элит-класс',
      [FacilityClass.Comfort]: 'Комфорт-класс',
    };

    if (round && round.class !== null) {
      const roundClassName = roundClassNames[round.class];
      return roundClassName || 'Неизвестно';
    }

    return 'Неизвестно';
  }, [round]);

  return (
    <div>
      {roundClass ? (
        <Tag color="cyan" style={{ marginBottom: '5px' }}>
          {roundClass}
        </Tag>
      ) : null}
      {comissionDate ? (
        <Tag
          color={round.isCommissioned ? 'green' : 'orange'}
          style={{ marginBottom: '5px' }}
        >
          {comissionDate}
        </Tag>
      ) : null}
      {contractType ? (
        <Tag color="purple" style={{ marginBottom: '5px' }}>
          {contractType}
        </Tag>
      ) : null}
    </div>
  );
};
