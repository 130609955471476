import { Empty } from 'antd';
import React from 'react';

export const CustomEmpty: React.FC = () => (
  <div style={{ padding: '120px 0' }}>
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description="Ваши пользователи еще не делали ставок"
    />
  </div>
);
