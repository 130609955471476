import { message } from 'antd';
import copy from 'copy-to-clipboard';

import whatsappIcon from '@/assets/icons/whatsapp-fill.svg';
import { CommonIcon, CommonIconType } from '@/common/components/common-icon';
import { SupportContact } from '@/common/constants';
import { formatPhoneNumber } from '@/common/utils';

import styles from './common-footer-contacts.module.css';

export const CommonFooterContacts = () => {
  const handleEmailClick = () => {
    copy(SupportContact.Email);
    message.success('Скопировано!');
  };

  return (
    <div className={styles.wrapper}>
      {/* TODO(crepiks): Implement universal helper method to build contact URLs */}
      <h4 className={styles.title}>Контакты</h4>
      <div className={styles.footerContacts}>
        <a
          href={SupportContact.WhatsApp}
          target="_blank"
          rel="noreferrer"
          className={styles.footerContactSection}
        >
          <img src={whatsappIcon} className={styles.whatsappIcon} />
          <span className={styles.footerContactValue}>WhatsApp</span>
        </a>
        <a
          href={`tel:${SupportContact.Phone}`}
          rel="noreferrer"
          className={styles.footerContactSection}
        >
          <CommonIcon
            type={CommonIconType.PhoneFilled}
            className={styles.icon}
          />
          <span className={styles.footerContactValue}>
            {formatPhoneNumber(SupportContact.Phone)}
          </span>
        </a>
        <a
          href={SupportContact.Telegram}
          target="_blank"
          rel="noreferrer"
          className={styles.footerContactSection}
        >
          <CommonIcon type={CommonIconType.Telegram} className={styles.icon} />
          <span className={styles.footerContactValue}>Telegram</span>
        </a>
        <div className={styles.footerContactSection} onClick={handleEmailClick}>
          <CommonIcon type={CommonIconType.Envelope} className={styles.icon} />
          <span className={styles.footerContactValue}>
            {SupportContact.Email}
          </span>
        </div>
      </div>
    </div>
  );
};
