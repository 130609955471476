import { useState } from 'react';

export enum BetOnFacilityModalContent {
  Form = 'form',
  Success = 'success',
}

export const useBetOnFacilityModal = () => {
  const [isBetOnFacilityModalOpen, setIsBetOnFacilityModalOpen] =
    useState(false);

  const [betOnFacilityModalContent, setBetOnFacilityModalContent] =
    useState<BetOnFacilityModalContent>(BetOnFacilityModalContent.Form);

  const openBetOnFacilityModal = () => {
    setBetOnFacilityModalContent(BetOnFacilityModalContent.Form);
    setIsBetOnFacilityModalOpen(true);
  };

  const changeBetOnFacilityModalContentToSuccess = () => {
    setBetOnFacilityModalContent(BetOnFacilityModalContent.Success);
  };

  return {
    isBetOnFacilityModalOpen,
    openBetOnFacilityModal,
    closeBetOnFacilityModal: () => setIsBetOnFacilityModalOpen(false),
    betOnFacilityModalContent,
    changeBetOnFacilityModalContentToSuccess,
  };
};
