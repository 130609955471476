import classNames from 'classnames';

import { CommonIcon, CommonIconType } from '@/common/components';
import { FacilityStatus } from '@/common/constants';

import { useFacilityMyBetSection } from './facility-my-bet-section.domain';
import styles from './facility-my-bet-section.module.css';
import { FacilityMyBetSectionProps } from './facility-my-bet-section.types';

export const FacilityMyBetSection = ({
  facility,
}: FacilityMyBetSectionProps) => {
  const { showMyBet, isMyBetTheHighest, showPricePerMeter } =
    useFacilityMyBetSection(facility);

  if (!showMyBet || facility.status !== FacilityStatus.Active) {
    return null;
  }

  return (
    <div className={styles.priceInfo}>
      <div className={styles.priceSection}>
        <div className={styles.priceLabel}>
          <CommonIcon
            type={CommonIconType.CoinWhite}
            className={styles.coinIcon}
          />
          <span>Моя ставка</span>
        </div>
        <p
          className={classNames(
            styles.myBetValue,
            isMyBetTheHighest && styles.myBetValueHighest,
          )}
        >
          {showMyBet}
        </p>
        <p className={styles.pricePerMeter}>За метр -- {showPricePerMeter}</p>
      </div>
    </div>
  );
};
