import { Facility } from '@/common/entities';

import { FacilityResponse } from '../responses';
import { mapBetResponseToBet } from './bet.mapper';
import { mapExtraPaymentResponseToExtraPayment } from './extra-payment.mapper';
import { mapFacilityLinkResponseToFacilityLink } from './facility-link.mapper';
import { mapFacilityPhotoResponseToFacilityPhoto } from './facility-photo.mapper';

export const mapFacilityResponseToFacility = (
  facilityResponse: FacilityResponse,
): Facility => ({
  id: facilityResponse.id,
  cityId: facilityResponse.city_id,
  districtId: facilityResponse.district_id,
  videoLink: facilityResponse.video_link,
  currencyId: facilityResponse.currency_id,
  roundId: facilityResponse.round_id,
  address: facilityResponse.address,
  name: facilityResponse.name,
  description: facilityResponse.description,
  status: facilityResponse.status,
  type: facilityResponse.type,
  publishedAt: facilityResponse.published_at,
  betStep: facilityResponse.bet_step,
  minBet: facilityResponse.min_bet,
  deposit: facilityResponse.deposit,
  startsAt: facilityResponse.starts_at,
  endsAt: facilityResponse.ends_at,
  class: facilityResponse.class,
  isCommissioned: facilityResponse.is_commissioned,
  decorationType: facilityResponse.decoration_type,
  areaM2: facilityResponse.area_m2,
  floor: facilityResponse.floor,
  totalFloors: facilityResponse.total_floors,
  latitude: facilityResponse.latitude,
  longitude: facilityResponse.longitude,
  hasBalcony: facilityResponse.has_balcony,
  hasGas: facilityResponse.has_gas,
  hasElectricity: facilityResponse.has_electricity,
  electricityPower: facilityResponse.electricity_power,
  hasSauna: facilityResponse.has_sauna,
  hasSwimmingPool: facilityResponse.has_swimming_pool,
  hasParking: facilityResponse.has_parking,
  isOnLease: facilityResponse.is_on_lease,
  heatingType: facilityResponse.heating_type,
  acreage: facilityResponse.acreage,
  territoryType: facilityResponse.territory_type,
  sewerageType: facilityResponse.sewerage_type,
  materialType: facilityResponse.material_type,
  commissioningYear: facilityResponse.commissioning_year,
  commissioningQuarter: facilityResponse.commissioning_quarter,
  waterSupplyType: facilityResponse.water_supply_type,
  agreementSum: facilityResponse.agreement_sum,
  isInOperation: facilityResponse.is_in_operation,
  layoutType: facilityResponse.layout_type,
  view: facilityResponse.view,
  entrance: facilityResponse.entrance,
  createdAt: facilityResponse.created_at,
  updatedAt: facilityResponse.updated_at,
  deletedAt: facilityResponse.deleted_at,
  city: facilityResponse.city,
  bet: facilityResponse.bet,
  contractType: facilityResponse.contract_type,
  bets: facilityResponse.bets?.map((bet) => mapBetResponseToBet(bet)),
  extraPayments: facilityResponse.extra_payments?.map((extraPayment) =>
    mapExtraPaymentResponseToExtraPayment(extraPayment),
  ),
  paymentMethods: facilityResponse.payment_methods,
  photos: facilityResponse.photos.map((photo) =>
    mapFacilityPhotoResponseToFacilityPhoto(photo),
  ),
  viewsCount: facilityResponse.views_count,
  links: facilityResponse.links
    ? facilityResponse.links.map((link) =>
        mapFacilityLinkResponseToFacilityLink(link),
      )
    : [],
  onboardedUsersCount: facilityResponse.onboarded_users_count,
});
