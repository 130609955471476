import { Bet } from '@/common/entities/bet';

import { BetResponse, mapBetResponseToBet } from '../../mappers';
import { request } from '../../request';

export const betOnFacility = async (payload: {
  facilityId: string;
  amount: number;
}): Promise<Bet> => {
  const responce = await request.post<{ bet: BetResponse }>(
    `/v1/facilities/${payload.facilityId}/bet`,
    {
      amount: payload.amount,
    },
  );

  const betResponce = responce.data.bet;

  return mapBetResponseToBet(betResponce);
};
