import { useMemo } from 'react';

import { CommonPaginationProps } from './common-pagination.types';

const MAX_VISIBLE_PAGES = 6;
const FACILITIES_PER_PAGE = 36;

export const usePagination = ({
  currentPage,
  total,
  perPage = FACILITIES_PER_PAGE,
  onChange,
}: CommonPaginationProps) => {
  const totalPages = useMemo(
    () => Math.ceil(total / perPage),
    [total, perPage],
  );

  const previousButtonActive = useMemo(() => currentPage > 1, [currentPage]);

  const nextButtonActive = useMemo(
    () => currentPage < totalPages,
    [currentPage, totalPages],
  );

  const generateArray = (start: number, end: number) =>
    Array.from({ length: end - start + 1 }, (__, index) => start + index);

  const centerPages = useMemo(() => {
    if (totalPages < MAX_VISIBLE_PAGES) {
      return generateArray(2, totalPages);
    }

    if (currentPage === 1) {
      return generateArray(2, 6);
    }

    const pagesBeforeLast = generateArray(totalPages - 5, totalPages - 1);

    if (currentPage === totalPages || pagesBeforeLast.includes(currentPage)) {
      return pagesBeforeLast;
    }

    return generateArray(currentPage, currentPage + 4);
  }, [currentPage, totalPages]);

  const handlePreviousButtonClick = () => {
    if (previousButtonActive) {
      onChange(currentPage - 1);
    }
  };

  const handleNextButtonClick = () => {
    if (nextButtonActive) {
      onChange(currentPage + 1);
    }
  };

  const handlePaginationPageButtonClick = (page: number) => {
    if (page !== currentPage) {
      onChange(page);
    }
  };

  return {
    totalPages,
    previousButtonActive,
    nextButtonActive,
    centerPages,
    handlePreviousButtonClick,
    handleNextButtonClick,
    handlePaginationPageButtonClick,
  };
};
