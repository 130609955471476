import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { CommonIcon, CommonIconType } from '@/common/components/common-icon';
import { paths } from '@/common/constants';
import { UserStoreContext } from '@/common/stores';

import {
  CommonHeaderGuideModal,
  useCommonHeaderGuideModal,
} from '../common-header-guide-modal';
import {
  CommonHeaderMenuModal,
  useCommonHeaderMenuModal,
} from '../common-header-menu-modal';
import styles from './common-header-menu-button.module.css';

export const CommonHeaderMenuButton = observer(() => {
  const navigate = useNavigate();
  const userStore = useContext(UserStoreContext);

  const {
    isCommonHeaderMenuModalOpen,
    handleCommonHeaderMenuModalOpen,
    handleCommonHeaderMenuModalClose,
  } = useCommonHeaderMenuModal();

  const handleLogout = () => {
    userStore.logout();
    handleCommonHeaderMenuModalClose();
    navigate(paths.getSignInPath());
  };

  const {
    isCommonHeaderGuideModalOpen,
    handleCommonHeaderGuideModalOpen,
    handleCommonHeaderGuideModalClose,
  } = useCommonHeaderGuideModal();

  const handleGuideModalOpen = () => {
    handleCommonHeaderMenuModalClose();
    handleCommonHeaderGuideModalOpen();
  };

  return (
    <>
      <button
        className={styles.menuButtonWrapper}
        onClick={handleCommonHeaderMenuModalOpen}
      >
        <CommonIcon type={CommonIconType.Menu} className={styles.menuIcon} />
      </button>
      <CommonHeaderMenuModal
        isOpen={isCommonHeaderMenuModalOpen}
        onClose={handleCommonHeaderMenuModalClose}
        onLogout={handleLogout}
        onGuideModalOpen={handleGuideModalOpen}
      />
      <CommonHeaderGuideModal
        isOpen={isCommonHeaderGuideModalOpen}
        onClose={handleCommonHeaderGuideModalClose}
      />
    </>
  );
});
