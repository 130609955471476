import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { UserStoreContext } from '@/common/stores';

import {
  BalanceSection,
  GeneralInfoSection,
  PartnerProgramSection,
  SupportSection,
  UserFacilitiesSection,
} from '../../components';
import styles from './profile-view.module.css';

export const ProfileView = observer(() => {
  const userStore = useContext(UserStoreContext);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <GeneralInfoSection user={userStore.user} />
        <BalanceSection balance={userStore.user?.balance} />
        {userStore.isReferrer ? <PartnerProgramSection /> : null}
        <SupportSection />
        <UserFacilitiesSection />
      </div>
    </div>
  );
});
