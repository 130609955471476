import {
  CommonEmptyPlaceholder,
  CommonErrorPlaceholder,
  CommonPagination,
} from '@/common/components';

import {
  ActiveFacilitiesListView,
  CompletedFacilitiesListView,
  DraftFacilitiesListView,
} from '../../components';
import styles from './facilities-list-view.module.css';
import { useFacilitiesListView } from './use-facilities-list-view';

export const FacilitiesListView = () => {
  const {
    facilities,
    paginationMeta,
    facilitiesAreEmpty,
    facilitiesAreLoading,
    facilitiesHaveError,
    facilitiesAreFetching,
    handlePaginationChange,
  } = useFacilitiesListView();

  return (
    <div className={styles.wrapper}>
      {facilitiesAreEmpty ? (
        <div className={styles.placeholderWrapper}>
          <CommonEmptyPlaceholder />
        </div>
      ) : null}
      {facilitiesHaveError ? (
        <div className={styles.placeholderWrapper}>
          <CommonErrorPlaceholder />
        </div>
      ) : null}
      <div>
        <ActiveFacilitiesListView
          facilities={facilities}
          facilitiesAreLoading={facilitiesAreLoading}
          facilitiesHaveError={facilitiesHaveError}
          facilitiesAreFetching={facilitiesAreFetching}
        />
        <DraftFacilitiesListView
          facilities={facilities}
          facilitiesAreLoading={facilitiesAreLoading}
          facilitiesHaveError={facilitiesHaveError}
        />
        <CompletedFacilitiesListView
          facilities={facilities}
          facilitiesAreLoading={facilitiesAreLoading}
          facilitiesHaveError={facilitiesHaveError}
        />
      </div>
      {!facilitiesAreEmpty && paginationMeta ? (
        <div className={styles.paginationWrapper}>
          <CommonPagination
            currentPage={paginationMeta.page}
            total={paginationMeta.total}
            perPage={paginationMeta.perPage}
            onChange={handlePaginationChange}
          />
        </div>
      ) : null}
    </div>
  );
};
