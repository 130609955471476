import { ReferredUserBet } from '@/common/entities/referred-user-bet';

export interface ReferredUserBetResponse {
  id: string;
  amount: number;
  created_at: string;
  facility: {
    id: string;
    name: string;
  };
  user: {
    id: string;
    first_name: string;
    last_name: string;
  };
}

export const mapReferredUserBetResponseToReferredUserBet = (
  response: ReferredUserBetResponse,
): ReferredUserBet => ({
  id: response.id,
  amount: response.amount,
  createdAt: response.created_at,
  facility: {
    id: response.facility.id,
    name: response.facility.name,
  },
  user: {
    id: response.user.id,
    firstName: response.user.first_name,
    lastName: response.user.last_name,
  },
});
