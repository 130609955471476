import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { paths } from '@/common/constants';
import { UserStoreContext } from '@/common/stores';

import styles from './landing-footer-links.module.css';

export const LandingFooterLinks = () => {
  const location = useLocation();
  const userStore = useContext(UserStoreContext);

  return (
    <div>
      <h4 className={styles.title}>Информация</h4>
      <div className={styles.footerLinks}>
        <Link to={paths.getLandingPath()} className={styles.footerLink}>
          Главная
        </Link>
        {userStore.isReferrer && userStore.isAuthenticated ? (
          <Link
            to={paths.getPatnerProgramPath()}
            state={{ from: location.pathname }}
            className={styles.footerLink}
          >
            Партнерская программа
          </Link>
        ) : null}
        <Link to={paths.getUserAgreementPath()} className={styles.footerLink}>
          Пользовательское соглашение
        </Link>
      </div>
    </div>
  );
};
