import { PaginationMeta, ReferredUserBet } from '@/common/entities';

import {
  PaginationMetaResponse,
  mapPaginationMetaResponseToPaginationMeta,
} from '../../mappers';
import {
  ReferredUserBetResponse,
  mapReferredUserBetResponseToReferredUserBet,
} from '../../mappers/referred-user-bet.mapper';
import { request } from '../../request';

export const getReferredUsersBets = async (
  referrerId: string,
  page = 1,
): Promise<[ReferredUserBet[], PaginationMeta]> => {
  const response = await request.get<{
    meta: PaginationMetaResponse;
    bets: ReferredUserBetResponse[];
  }>(`/v1/referrers/${referrerId}/bets`, {
    params: {
      page,
    },
  });

  const paginationMeta = mapPaginationMetaResponseToPaginationMeta(
    response.data?.meta,
  );

  const referredUsersBets = response.data?.bets.map((bet) =>
    mapReferredUserBetResponseToReferredUserBet(bet),
  );

  return [referredUsersBets, paginationMeta];
};
