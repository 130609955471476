import classNames from 'classnames';
import { useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CommonIcon, CommonIconType } from '@/common/components';

import { FacilityParamCard } from '../facility-param-card';
import {
  FacilitySchemasModal,
  useFacilitySchemasModal,
} from '../facility-schemas-modal';
import { FacilitySectionWrapper } from '../facility-section-wrapper';
import { useFacilityParamsSection } from './facility-params-section.domain';
import styles from './facility-params-section.module.css';
import { FacilityParamsSectionProps } from './facility-params-section.types';

export const FacilityParamsSection = ({
  facility,
}: FacilityParamsSectionProps) => {
  const { facilityParams, complexParams, schemas } =
    useFacilityParamsSection(facility);

  const {
    isFacilitySchemasModalOpen,
    openFacilitySchemasModal,
    closeFacilitySchemasModal,
  } = useFacilitySchemasModal();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  let schemasPerView = 4;

  if (windowWidth < 600) {
    schemasPerView = 1;
  } else if (windowWidth < 800) {
    schemasPerView = 2;
  } else if (windowWidth < 1000) {
    schemasPerView = 3;
  }

  const [slideIndex, setSlideIndex] = useState(0);

  const handleSchemeClick = (index: number) => {
    setSlideIndex(index);
    openFacilitySchemasModal();
  };

  const numberOfSchemes = schemas?.length || 0;

  return (
    <FacilitySectionWrapper>
      <FacilitySchemasModal
        isOpen={isFacilitySchemasModalOpen}
        onClose={closeFacilitySchemasModal}
        schemas={schemas}
        slideIndex={slideIndex}
      />
      <div className={styles.content}>
        <h3 className={styles.paramsTitle}>Информация</h3>
        {schemas ? (
          <div
            className={classNames(styles.scheme, {
              [styles.fewSchemes]: numberOfSchemes <= schemasPerView,
            })}
          >
            <Swiper
              spaceBetween={10}
              slidesPerView={schemasPerView}
              loop
              navigation={{
                nextEl: `.${styles.nextButton}`,
                prevEl: `.${styles.prevButton}`,
              }}
              modules={[Navigation]}
            >
              {schemas.map((schema, index) => (
                <SwiperSlide
                  onClick={() => handleSchemeClick(index)}
                  key={schema.id}
                  className={styles.schemeContainer}
                >
                  <img
                    src={schema.photo.path}
                    alt={`Facility Schema ${index + 1}`}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            {numberOfSchemes > schemasPerView ? (
              <>
                <div className={styles.prevButton}>
                  <CommonIcon
                    className={styles.prevButtonIcon}
                    type={CommonIconType.ChevronLeft}
                  />
                </div>
                <div className={styles.nextButton}>
                  <CommonIcon
                    className={styles.nextButtonIcon}
                    type={CommonIconType.ChevronRight}
                  />
                </div>
              </>
            ) : null}
          </div>
        ) : null}

        <div className={styles.paramContainer}>
          <div>
            <h3 className={styles.paramsTitle}>Характеристики лота</h3>
            <div className={styles.facilityParams}>
              {facilityParams.map((param) => (
                <FacilityParamCard
                  key={param.label}
                  label={param.label}
                  value={param.value}
                />
              ))}
            </div>
          </div>
          <div>
            <h3 className={styles.paramsTitle}>Характеристики комплекса</h3>
            <div className={styles.facilityParams}>
              {complexParams.map((param) => (
                <FacilityParamCard
                  key={param.label}
                  label={param.label}
                  value={param.value}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </FacilitySectionWrapper>
  );
};
