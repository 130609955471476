export enum PaymentMethodType {
  Mortgage = 1,
  Installment = 2,
  MilitaryMortgage = 3,
  MaternityCapital = 4,
  MortgageWithoutDownPayment = 5,
  FamilyMortgage = 6,
  PaymentWithVAT = 7,
  ITMortgage = 8,
  TrenchMortgage = 9,
  PreferentialMortgage = 10,
  SubsidizedMortgage = 11,
  MortgageWithStateSupport = 12,
}
