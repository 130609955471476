import classNames from 'classnames';
import { useState } from 'react';

import { CommonButton, CommonIcon, CommonIconType } from '@/common/components';

import { PartnerProgramSectionWrapper } from '../partner-program-section-wrapper';
import styles from './partner-program-referral-link-section.module.css';
import { PartnerProgramDescriptionSectionProps } from './partner-program-referral-link-section.types';
import { createReferralLink } from './utils';

export const PartnerProgramReferralLinkSection = ({
  user,
}: PartnerProgramDescriptionSectionProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const link = user?.referrerUser
    ? createReferralLink(user.referrerUser.referralCode)
    : '';

  const copyReferralLink = () => {
    navigator.clipboard.writeText(link).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  };

  return (
    <PartnerProgramSectionWrapper>
      <div className={styles.title}>Ваша реферальная ссылка</div>
      <p className={styles.text}>Делитесь ссылкой с друзьями или клиентами</p>
      <div className={styles.linkWrapper}>
        <input
          className={classNames(
            styles.linkInput,
            isCopied && styles.linkInputCopied,
          )}
          type="text"
          value={link}
          readOnly
        />
        <CommonIcon type={CommonIconType.Copy} className={styles.copyIcon} />
        <CommonIcon
          type={CommonIconType.CheckCircle}
          className={classNames(
            styles.checkIcon,
            isCopied && styles.checkIconVisible,
          )}
        />
      </div>
      <CommonButton className={styles.copyButton} onClick={copyReferralLink}>
        {isCopied ? 'Скопирован!' : 'Скопировать'}
      </CommonButton>
    </PartnerProgramSectionWrapper>
  );
};
