import { FC, useState } from 'react';

import { CommonIcon, CommonIconType } from '@/common/components';

import styles from './faq-card.module.css';

interface FaqCardProps {
  readonly question: string;
  readonly answer: string;
}

export const FaqCard: FC<FaqCardProps> = ({ question, answer }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleClick = () => {
    setIsVisible((prev) => !prev);
  };

  return (
    <div className={styles.faqСard}>
      <div className={styles.header}>
        <p>Вопрос</p>
        <div className={styles.iconContainer} onClick={handleClick}>
          {isVisible ? (
            <CommonIcon type={CommonIconType.Minus} className={styles.icon} />
          ) : (
            <CommonIcon type={CommonIconType.Plus} className={styles.icon} />
          )}
        </div>
      </div>

      <div className={styles.textContaier}>
        <h1 className={styles.questionText}>{question}</h1>
        <div
          className={`${styles.answer} ${isVisible ? styles.visible : styles.hidden}`}
        >
          <h1 className={styles.answerText}>{answer}</h1>
        </div>
      </div>
    </div>
  );
};
