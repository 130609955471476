import { useState } from 'react';

export const useFacilitySchemasModal = () => {
  const [isFacilitySchemasModalOpen, setIsFacilitySchemasModalOpen] =
    useState(false);

  const openFacilitySchemasModal = () => {
    setIsFacilitySchemasModalOpen(true);
  };

  return {
    isFacilitySchemasModalOpen,
    openFacilitySchemasModal,
    closeFacilitySchemasModal: () => setIsFacilitySchemasModalOpen(false),
  };
};
