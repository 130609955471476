export interface RefillRequestResponce {
  id: string;
  userId: string;
  amount: number;
}

export const mapRefillRequestResponceToRefilRequest = (
  responce: RefillRequestResponce,
): RefillRequestResponce => ({
  id: responce.id,
  userId: responce.userId,
  amount: responce.amount,
});
