import { useState } from 'react';

export const useCommonHeaderGuideModal = () => {
  const [isCommonHeaderGuideModalOpen, setIsCommonHeaderGuideModalOpen] =
    useState(false);

  const handleCommonHeaderGuideModalClose = () => {
    setIsCommonHeaderGuideModalOpen(false);
  };

  const handleCommonHeaderGuideModalOpen = () => {
    setIsCommonHeaderGuideModalOpen(true);
  };

  return {
    isCommonHeaderGuideModalOpen,
    handleCommonHeaderGuideModalClose,
    handleCommonHeaderGuideModalOpen,
  };
};
