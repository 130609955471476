import { FaqCard } from '../faq-card/faq-card';
import styles from './faq-section.module.css';

export const Faq = () => (
  <div id="faq" className={styles.wrapper}>
    <h1 className={styles.faqText}>
      Частые вопросы <br /> <span>пользователей</span>{' '}
    </h1>
    <div className={styles.faqWrapper}>
      <FaqCard
        question="Почему застройщики готовы отдавать по таким низким ценам?"
        answer="При продаже нескольких лотов разом они делают скидку за объем. А так же, у  застройщиков есть обязательства перед банком, им необходимо придерживаться плана продаж, наполнять Эскроу Счета и нести расходы по ходу строительства. Аукцион позволяет им продать пул Лотов за 4 дня. Что бы избежать демпинга им требуется закрытый канал реализации - этим каналом является MinoTower закрытый сервис."
      />
      <FaqCard
        question="В чем преимущество приобретения объекта на аукционе?"
        answer="Возможность приобрести Лот ниже рыночной стоимости до 50%. Приобрести эксклюзивные предложения которых нет на открытом рынке. Получить юридическое сопровождение сделки."
      />
      <FaqCard
        question="Что продается на Аукционе?"
        answer="В Аукционе участвуют Жилые и Апартаментные комплексы от крупных застройщиков. Возможно приобрести Квартиры, Гостиничные номера, Апартаменты."
      />
      <FaqCard
        question="Что нужно для участия в аукционе?"
        answer="Для участия в аукционе необходимо: 1. Зарегистрироваться в сервисе 2. Внести депозит - 1000₽ (он возвратный) 3. Сделать ставку"
      />
      <FaqCard
        question="Как я могу отплатить недвижимость после выигрыша?"
        answer="Вы можете приобрести объект за 100% оплату, в ипотеку или в рассрочку. *Рассрочка и ипотека применима не во всех проектах, более подробные условия уточняйте у менеджера."
      />
      <FaqCard
        question="Как долго длится аукцион?"
        answer="Аукцион проходит каждую неделю на один Жилой комплекс и длится 4 суток или 96 часов. Расписание вы всегда можете увидеть в сервисе или уточнить у менеджера."
      />
      <FaqCard
        question="Что будет если я выиграю в Аукционе и не выкуплю Лот?"
        answer="В этом случае сгорает депозит, и участник сделавший ставку до вас получает возможность приобрести Лот."
      />
      <FaqCard
        question="Что делать после победы в Аукционе?"
        answer="После победы в Аукционе с вами связывается ваш личный менеджер, сообщает и инструктирует по всем этапам проведению сделки. Наша компания сопровождает весь процесс и обеспечивает юридическое сопровождение."
      />
      <FaqCard
        question="Какой депозит необходим для участия?"
        answer="Депозит составляет 1000 руб. для участия в аукционе на один лот (Если вы хотите побороться за все 5 лотов, необходимо внести 5000 руб.)"
      />
      <FaqCard
        question="Возвращается ли депозит?"
        answer="Да, депозит 100% возвратный в случае если вы не выиграли аукцион."
      />
      <FaqCard
        question="Почему всем необходимо учавствовать в торгах?"
        answer="Мы заинтересованы в прозрачности, системности и открытости и предлагаем решения, которые универсальны для любого клиента: как покупающего впервые, так и опытного инвестора. Аукционы демонстрируют интерес рынка, показывают специфику объектов. Это позволяет сделать правильный выбор и уравнивает шансы всех участников."
      />
      <FaqCard
        question="У меня ограниченный бюджет - аукцион не для меня?"
        answer="Низкая начальная стоимость дает возможность приобрести недвижимость с ограниченным бюджетом. В большинстве объектах действует ипотека и рассрочка (условия указаны в карточке объекта в сервисе, а так же можете уточнить у личного менеджера) .Мы поможем одобрить ипотеку и подобрать комфортные условия."
      />
      <FaqCard
        question="Что делать, если объект интересен, но аукционные торги уже увели цену за пределы бюджета?"
        answer="Мы уверены в привлекательности наших лотов и в том, что итоговые цены аукционов позволяют нашим покупателям зарабатывать то, что является рыночной доходностью. Если вам немного не хватит средств, так же можно воспользоваться рассрочкой или ипотекой."
      />
      <FaqCard
        question="У меня нет времени следить за торгами, я занят - что делать?"
        answer="У вас есть возможность заранее сделать ставку, а потом следить за изменениями и итогами аукциона с помощью оповещений от личного менеджера."
      />
      <FaqCard
        question="Сколько у меня есть времени, чтобы выкупить объект по итогам аукциона?"
        answer="После победы в аукционе у вас будет 3 календарных дня,что бы внести бронь (размер брони уточнить у личного менеджера) и в течении 14 календарных дней выйти на сделку. Если этот срок истек, а вы не подписали договор — бронирование аннулируется. Вам придется принять участие в новом аукционе."
      />
      <FaqCard
        question="Я не специалист и для меня этот инструмент неизвестен, могу ли я учавствовать в торгах?"
        answer="Сервис интуитивно понятен, есть вся информация по объектам и конкретным лотам. Вы всегда можете написать нашему менеджеру и он поможет подобрать объект под ваши цели и задачи."
      />
      <FaqCard
        question="Как приобрести объект в ипотеку?"
        answer="Наши специалисты помогут вам при подаче заявки на ипотеку, а также предоставят подробную информацию по программам и перечню документов."
      />
      <FaqCard
        question="Почему сервис закрыт от прямого доступа?"
        answer="Мы скрываем данные по фактическим продажам от общего рынка не допуская демпинг. Что бы застройщики могли продавать определенный пул номеров/квартир."
      />
      <FaqCard
        question="Как получить доступ в сервис?"
        answer="Доступ в сервис возможно получить только у наших партнеров, которые владеют доверительной ссылкой."
      />
      <FaqCard
        question="Чем отличается процесс сделки через Аукцион, от простой покупки Квартиры у застройщика?"
        answer="Ничем."
      />
      <FaqCard
        question="Какой средний % скидки от застройщика по выкупленным лотам?"
        answer="Процент варьируется от 13,3-44,7%. Средний % скидки по выкупленным объектам 28,9%"
      />
      <FaqCard
        question="Чем отличается от торгов по банкротству?"
        answer="В Аукционе участвуют Комплексы от крупных застройщиков строящиеся по ФЗ-214, что гарантирует юридическую чистоту документов. Документы по каждому комплексу представлены в карточке объекта и вы так же можете с ними ознакомиться. Простой, прозрачный процесс покупки ничем не отличающийся от обычной сделки."
      />
    </div>
  </div>
);
