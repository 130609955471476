/* eslint-disable id-length */
import classNames from 'classnames';
import { AnimatePresence, Variants, motion } from 'framer-motion';
import { MouseEventHandler, useEffect, useRef } from 'react';

import { useModalScroll } from '@/common/hooks/use-modal-scroll';
import { Platform, getPlatform } from '@/common/utils';

import { CommonIcon, CommonIconType } from '../common-icon';
import { useCommonModal } from './common-modal.domain';
import styles from './common-modal.module.css';
import { CommonModalProps } from './common-modal.types';

export const CommonModal = ({
  modalClassName,
  children,
  title,
  footer,
  isOpen,
  onClose,
}: CommonModalProps) => {
  const { isVisible, handleClose } = useCommonModal(isOpen, onClose);
  useModalScroll(isVisible);

  const modalRef = useRef(null);

  const platform = getPlatform();

  const animationDuration = 0.1;

  const backdropVariants: Variants = {
    visible: {
      opacity: 1,
      transition: {
        duration: animationDuration,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        duration: animationDuration,
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: animationDuration,
      },
    },
  };

  const modalVariants: Variants = {
    hidden: {
      opacity: 0,
      scale: 0.5,
      transition: {
        duration: animationDuration,
      },
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: animationDuration,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.5,
      transition: {
        duration: animationDuration,
      },
    },
  };

  const mobileModalVariants = {
    hidden: {
      opacity: 0,
      y: '100vh',
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        stiffness: 100,
        damping: 15,
      },
    },
    exit: {
      opacity: 0,
      y: '100vh',
      transition: {
        type: 'tween',
        ease: 'easeInOut',
        duration: 0.4,
      },
    },
  };

  useEffect(() => {
    const handleEscapePress = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && onClose) {
        onClose();
      }
    };

    const addEventListeners = () => {
      document.addEventListener('keydown', handleEscapePress);
    };

    const removeEventListeners = () => {
      document.removeEventListener('keydown', handleEscapePress);
    };

    if (isVisible) {
      addEventListeners();
    } else {
      removeEventListeners();
    }

    return () => {
      removeEventListeners();
    };
  }, [isVisible, onClose]);

  const handleWrapperClick: MouseEventHandler = (event) => {
    if (event.currentTarget === event.target && onClose) {
      onClose();
    }
  };

  return (
    <AnimatePresence>
      {isVisible ? (
        <motion.div
          initial="hidden"
          exit="exit"
          animate={isVisible ? 'visible' : 'hidden'}
          variants={backdropVariants}
          className={classNames(styles.wrapper, {
            [styles.open]: isVisible,
          })}
          onClick={handleWrapperClick}
        >
          <motion.div
            className={classNames(styles.modal, modalClassName)}
            variants={
              platform === Platform.Mobile ? mobileModalVariants : modalVariants
            }
            ref={modalRef}
          >
            <header className={styles.header}>
              <h2 className={styles.title}>{title}</h2>
              <button className={styles.closeButton} onClick={handleClose}>
                <CommonIcon
                  type={CommonIconType.Cross}
                  className={styles.crossIcon}
                />
              </button>
            </header>
            <div className={styles.content}>{children}</div>
            {footer ? (
              <footer className={styles.footer}>{footer}</footer>
            ) : null}
          </motion.div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};
