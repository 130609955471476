import { ExtraPayment } from '@/common/entities';

import { ExtraPaymentResponse } from '../responses';

export const mapExtraPaymentResponseToExtraPayment = (
  response: ExtraPaymentResponse,
): ExtraPayment => {
  return {
    id: response.id,
    extraPaymentName: response.extra_payment_name,
    extraPaymentPrice: response.extra_payment_price,
  };
};
