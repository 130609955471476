import { CommonIcon, CommonIconType } from '@/common/components';

import { useFacilityLinkCard } from './facility-link-card.domain';
import styles from './facility-link-card.module.css';
import { FacilityLinkCardProps } from './facility-link-card.types';

export const FacilityLinkCard = ({ link }: FacilityLinkCardProps) => {
  const { showIconType } = useFacilityLinkCard(link);

  return (
    <div className={styles.card}>
      <div className={styles.linkInformation}>
        <CommonIcon type={showIconType} className={styles.cardIcon} />
        <div>
          <span className={styles.linkLabel}>{link.label}</span>
          <p className={styles.linkDescription}>{link.description}</p>
        </div>
      </div>
      <div className={styles.linkButton}>
        <a
          href={link.url}
          className={styles.linkButtonLink}
          target="_blank"
          rel="noreferrer"
          download="file"
        >
          <CommonIcon
            type={CommonIconType.ArrowTop}
            className={styles.arrowIcon}
          />
        </a>
      </div>
    </div>
  );
};
