import { Form, Radio } from 'antd';
import { Link } from 'react-router-dom';

import { OnboardUserProps } from '@/common/api/requests';
import { CommonButton, CommonInput } from '@/common/components';
import { UserRole, paths } from '@/common/constants';

import styles from './onboarding-form.module.css';
import { OnboardingFormProps } from './onboarding-form.types';

export const OnboardingForm = ({
  isSubmitLoading = false,
  onSubmit,
}: OnboardingFormProps) => {
  const handleSubmit = (values: OnboardUserProps) => {
    if (onSubmit) {
      const trimmedValues = {
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        email: values.email ? values.email?.trim() : null,
        role: values.role,
      };
      onSubmit(trimmedValues);
    }
  };

  return (
    <Form
      className={styles.onboardingForm}
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        role: UserRole.Client,
      }}
      onFinish={handleSubmit}
      colon={false}
    >
      <div className={styles.onboardingFormContent}>
        <h2 className={styles.onboardingFormTitle}>Введите данные</h2>
        <p className={styles.onboardingFormCaption}>
          Чтобы продолжить необходимы некоторые данные
        </p>
        <div className={styles.inputContainer}>
          <Form.Item
            className={styles.formItem}
            name="firstName"
            rules={[
              {
                required: true,
                message: 'Введите имя',
              },
            ]}
            normalize={(value) => (value ? value.trim() : '')}
          >
            <CommonInput
              placeholder="Введите имя"
              className={styles.onboardingFormInput}
            />
          </Form.Item>
          <Form.Item
            className={styles.formItem}
            name="lastName"
            rules={[
              {
                required: true,
                message: 'Введите фамилию',
              },
            ]}
            normalize={(value) => (value ? value.trim() : '')}
          >
            <CommonInput
              placeholder="Введите фамилию"
              className={styles.onboardingFormInput}
            />
          </Form.Item>
          <Form.Item
            className={styles.formItem}
            name="email"
            validateFirst
            rules={[
              {
                type: 'email',
                message: 'Введите корректную почту',
              },
              {
                max: 50,
                message: 'Максимальная длина 50 символов',
              },
            ]}
            normalize={(value) => (value ? value.trim() : '')}
          >
            <CommonInput
              placeholder="Введите почту"
              className={styles.onboardingFormInput}
            />
          </Form.Item>
          <h3 className={styles.roleTitle}>Выберите роль</h3>
          <Form.Item
            className={styles.formItem}
            name="role"
            validateFirst
            rules={[
              {
                required: true,
              },
            ]}
            normalize={(value) => (value ? value.trim() : '')}
          >
            <Radio.Group
              className={styles.onboardingFormRadio}
              options={[
                {
                  value: UserRole.Client,
                  label: (
                    <div className={styles.radioOption}>
                      <h4 className={styles.radioTitle}>Участник аукциона</h4>
                      <p className={styles.radioDescription}>
                        Я буду выбирать объекты, делать ставки и побеждать
                      </p>
                    </div>
                  ),
                },
                {
                  value: UserRole.Partner,
                  label: (
                    <div className={styles.radioOption}>
                      <h4 className={styles.radioTitle}>Партнер или Агент</h4>
                      <p className={styles.radioDescription}>
                        Помогаю клиентам подбирать объекты и делать ставки
                      </p>
                    </div>
                  ),
                },
              ]}
            />
          </Form.Item>
        </div>
        <CommonButton
          type="submit"
          className={styles.onboardingFormButton}
          isLoading={isSubmitLoading}
        >
          Отправить
        </CommonButton>
        <p className={styles.onboardingFormPolicy}>
          Продолжая, вы соглашаетесь с{' '}
          <Link to={paths.getUserAgreementPath()} className={styles.policyLink}>
            пользовательским соглашением
          </Link>
        </p>
      </div>
    </Form>
  );
};
