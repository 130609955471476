import { Form } from 'antd';
import FormItem from 'antd/es/form/FormItem';

import { UserGeneralInfoProps } from '@/common/api/requests';
import { CommonButton, CommonInput, CommonModal } from '@/common/components';

import { GeneralInfoSectionProps } from '../../general-info-section.types';
import styles from './common-user-edit-modal.module.css';
import { CommonUserEditModalProps } from './common-user-edit-modal.types';

export const CommonUserEditModal = ({
  isSubmitLoading = false,
  isOpen = false,
  onClose,
  onSubmit,
  user,
}: CommonUserEditModalProps & GeneralInfoSectionProps) => {
  const handleSubmit = (values: UserGeneralInfoProps) => {
    if (onSubmit) {
      onSubmit(values);
    }
  };

  return (
    <CommonModal
      title="Редактировать профиль"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={styles.content}>
        <Form
          layout="vertical"
          className={styles.form}
          onFinish={handleSubmit}
          initialValues={{
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            email: user?.email || '',
          }}
        >
          <FormItem
            label="Имя"
            name="firstName"
            rules={[
              {
                validator: (__, value) =>
                  value?.trim()
                    ? Promise.resolve()
                    : Promise.reject(new Error('Введите имя')),
              },
              {
                max: 50,
                message: 'Максимальная длина 50 символов',
              },
              {
                min: 2,
                message: 'Минимальная длина 2 символа',
              },
            ]}
          >
            <CommonInput className={styles.input} placeholder="Введите имя" />
          </FormItem>
          <FormItem
            label="Фамилия"
            name="lastName"
            rules={[
              {
                validator: (__, value) =>
                  value?.trim()
                    ? Promise.resolve()
                    : Promise.reject(new Error('Введите фамилию')),
              },
              {
                max: 50,
                message: 'Максимальная длина 50 символов',
              },
              {
                min: 2,
                message: 'Минимальная длина 2 символа',
              },
            ]}
          >
            <CommonInput
              className={styles.input}
              placeholder="Введите фамилию"
            />
          </FormItem>
          <FormItem
            label="Email"
            name="email"
            rules={[
              {
                validator: (__, value) =>
                  value?.trim()
                    ? Promise.resolve()
                    : Promise.reject(new Error('Введите почту')),
              },
              {
                type: 'email',
                message: 'Введите корректную почту',
              },
              {
                max: 255,
                message: 'Максимальная длина 50 символов',
              },
              {
                min: 2,
                message: 'Минимальная длина 2 символа',
              },
            ]}
          >
            <CommonInput className={styles.input} placeholder="Введите почту" />
          </FormItem>
          <CommonButton
            type="submit"
            isLoading={isSubmitLoading}
            className={styles.submitButton}
          >
            Подтвердить
          </CommonButton>
        </Form>
      </div>
    </CommonModal>
  );
};
