import { useMessageContextHolder } from '@/common/hooks';

const MessageLayout = ({
  children,
}: {
  readonly children: React.ReactNode;
}) => {
  const contextHolder = useMessageContextHolder();

  return (
    <>
      {contextHolder}
      {children}
    </>
  );
};

export default MessageLayout;
