/* eslint-disable camelcase */
import { User } from '@/common/entities';

import { mapUserResponseToUser } from '../../mappers';
import { request } from '../../request';
import { UserResponse } from '../../responses';

export const applySignUpCode = async (
  phoneNumber: string,
  code: string,
  referralCode: string,
): Promise<[string, User]> => {
  const response = await request.post<{
    access_token: {
      token: string;
    };
    user: UserResponse;
  }>('/v1/users/me/sign-up/apply-code', {
    phone_number: phoneNumber,
    code,
    referral_code: referralCode.toLowerCase(),
  });

  const { access_token, user } = response.data;

  return [access_token.token, mapUserResponseToUser(user)];
};
