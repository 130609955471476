import axios from 'axios';

import { apiConfig } from '@/config';

export const request = axios.create({
  baseURL: apiConfig.baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});
