import { observer } from 'mobx-react-lite';
import { v4 as uuidv4 } from 'uuid';

import { FacilityStatus } from '@/common/constants';

import { FacilityCard } from '..';
import styles from './active-facilities-list-view.module.css';
import { ActiveFacilityListViewProps } from './active-facilities-list-view.types';

export const ActiveFacilitiesListView = observer(
  ({
    facilities,
    facilitiesAreLoading,
    facilitiesHaveError,
    facilitiesAreFetching,
  }: ActiveFacilityListViewProps) => {
    const activeFacilities = facilities.filter(
      (facility) => facility.status === FacilityStatus.Active,
    );

    const fasilitiesAreEmpty =
      activeFacilities.length === 0 &&
      !facilitiesAreLoading &&
      !facilitiesHaveError;

    if (fasilitiesAreEmpty) {
      return null;
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.header}>
            <h1 className={styles.title}>Идут торги</h1>
          </div>
          <div>
            {facilitiesAreLoading ? (
              <div className={styles.placeholderWrapper}>
                <div className={styles.facilitiesList}>
                  {Array.from({ length: 6 }).map(() => (
                    <FacilityCard.Skeleton key={uuidv4()} />
                  ))}
                </div>
              </div>
            ) : null}
            {!facilitiesAreLoading && !facilitiesHaveError && (
              <div className={styles.facilitiesList}>
                {activeFacilities.map((facility) => (
                  <FacilityCard
                    facility={facility}
                    key={facility.id}
                    facilitiesAreFetching={facilitiesAreFetching}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
);
