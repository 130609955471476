import { observer } from 'mobx-react-lite';
import { v4 as uuidv4 } from 'uuid';

import { CommonErrorPlaceholder } from '@/common/components';
import { FacilityStatus } from '@/common/constants';
import { FacilityCard } from '@/modules/facilities/components';

import styles from './completed-facilities-list-view.module.css';
import { CompletedFacilityListViewProps } from './completed-facility-list-view.types';

export const CompletedFacilitiesListView = observer(
  ({
    facilities,
    facilitiesAreLoading,
    facilitiesHaveError,
  }: CompletedFacilityListViewProps) => {
    const completedFacilities = facilities.filter(
      (facility) =>
        facility.status === FacilityStatus.Completed ||
        facility.status === FacilityStatus.Cancelled,
    );

    const fasilitiesAreEmpty =
      completedFacilities.length === 0 &&
      !facilitiesAreLoading &&
      !facilitiesHaveError;

    if (fasilitiesAreEmpty) {
      return null;
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.header}>
            <h1 className={styles.title}>
              ({completedFacilities.length}) Ваши завершенные лоты
            </h1>
          </div>
          <div className={styles.facilitiesListWrapper}>
            {facilitiesAreLoading ? (
              <div className={styles.placeholderWrapper}>
                <div className={styles.facilitiesList}>
                  {Array.from({ length: 6 }).map(() => (
                    <FacilityCard.Skeleton key={uuidv4()} />
                  ))}
                </div>
              </div>
            ) : null}
            {facilitiesHaveError ? (
              <div className={styles.placeholderWrapper}>
                <CommonErrorPlaceholder />
              </div>
            ) : null}
            {!facilitiesAreLoading && !facilitiesHaveError && (
              <div className={styles.facilitiesList}>
                {completedFacilities.map((facility) => (
                  <FacilityCard facility={facility} key={facility.id} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
);
