export enum AuthPhoneFormType {
  SignUp = 'sign-up',
  SignIn = 'sign-in',
}

export interface AuthPhoneFormProps {
  title: string;
  formType: AuthPhoneFormType;
  isSubmitLoading?: boolean;
  onSubmit?: (phoneNumber: string) => void;
}
