import classNames from 'classnames';

import styles from './profile-section-container.module.css';
import { ProfileSectionContainerProps } from './profile-section-container.types';

export const ProfileSectionContainer = ({
  children,
  className,
}: ProfileSectionContainerProps) => {
  return (
    <div className={classNames(styles.profileSectionContainer, className)}>
      <div className={styles.profileSectionContent}>{children}</div>
    </div>
  );
};
