import { Breadcrumb } from '@/common/components/breadcrumbs';
import { paths } from '@/common/constants';

export const usePartnerEventsView = () => {
  const breadcrumbs: Breadcrumb[] = [
    { label: 'Партнерская программа', path: paths.getPatnerProgramPath() },
    { label: 'События', path: paths.getPartnerEventsPath() },
  ];

  return { breadcrumbs };
};
