import { request } from '@/common/api/request';
import { Round } from '@/common/entities';

import { mapRoundResponseToRound } from '../../mappers';
import { RoundResponse } from '../../responses';

export const getRounds = async (): Promise<Round[]> => {
  const response = await request.get<{ rounds: RoundResponse[] }>('/v1/rounds');

  const rounds = response.data?.rounds.map((round) =>
    mapRoundResponseToRound(round),
  );

  return rounds;
};
