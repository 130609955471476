import styles from './guide-card.module.css';
import { GuideCardProps } from './guide-card.types';

export const GuideCard = ({ image, title, content }: GuideCardProps) => {
  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <div className={styles.cardIcon}>
          <img src={image} alt="guide-card-icon" />
        </div>
        <div className={styles.cardTitle}>{title}</div>
      </div>
      <div className={styles.cardContent}>{content}</div>
    </div>
  );
};
