import { CommonSupportCard, CommonSupportCardType } from '@/common/components';

import { ProfileSectionContainer } from '../profile-section-container';
import styles from './support-section.module.css';

export const SupportSection = () => {
  return (
    <ProfileSectionContainer>
      <div className={styles.supportWrapper}>
        <h3 className={styles.supportTitle}>Поддержка</h3>
        <p className={styles.supportCaption}>
          Ответим на все вопросы и поможем решить возникшие проблемы
        </p>
        <div className={styles.supportCards}>
          <CommonSupportCard type={CommonSupportCardType.WhatsApp} />
          <CommonSupportCard type={CommonSupportCardType.Telegram} />
        </div>
      </div>
    </ProfileSectionContainer>
  );
};
