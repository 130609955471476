import { useQuery } from '@tanstack/react-query';

import { getRounds } from '@/common/api/requests';
import { FacilityStatus } from '@/common/constants';
import { Round } from '@/common/entities';

import { DraftFacilityListViewProps } from './draft-facility-list-view.types';

export const useDraftFacilityListView = ({
  facilities,
  facilitiesAreLoading,
  facilitiesHaveError,
}: DraftFacilityListViewProps) => {
  const { data, isPending, isError } = useQuery<Round[]>({
    queryKey: ['rounds'],
    queryFn: () => getRounds(),
    staleTime: 5 * 60 * 1000,
  }) as {
    data?: Round[];
    isPending: boolean;
    isError: boolean;
  };

  const draftFacilities = facilities.filter(
    (facility) => facility.status === FacilityStatus.Draft,
  );

  const roundsHaveError = (isError && !isPending) || facilitiesHaveError;
  const roundsAreLoading = isPending || facilitiesAreLoading;

  const rounds =
    data?.reduce<(Round & { facilities: typeof draftFacilities })[]>(
      (acc, round) => {
        const roundFacilities = draftFacilities.filter(
          (facility) => facility.roundId === round.id,
        );
        if (roundFacilities.length > 0) {
          acc.push({ ...round, facilities: roundFacilities });
        }
        return acc;
      },
      [],
    ) || [];

  const roundsAreEmpty =
    rounds.length === 0 &&
    !facilitiesAreLoading &&
    !facilitiesHaveError &&
    !isPending &&
    !isError;

  return {
    rounds,
    roundsAreEmpty,
    roundsHaveError,
    roundsAreLoading,
  };
};
