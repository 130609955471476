export enum Platform {
  Desktop = 'desktop',
  Mobile = 'mobile',
}

export const getPlatform = (): Platform => {
  if (typeof window === 'undefined') {
    return Platform.Desktop;
  }

  const { userAgent } = window.navigator;
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/iu.test(
      userAgent,
    );

  return isMobile ? Platform.Mobile : Platform.Desktop;
};
