import classNames from 'classnames';

import styles from './common-pagination-button.module.css';
import { CommonPaginationButtonProps } from './common-pagination-button.types';

export const CommonPaginationButton = ({
  text,
  icon,
  active,
  disabled,
  onClick,
}: CommonPaginationButtonProps) => (
  <button
    className={classNames(
      styles.paginationButton,
      active && styles.active,
      disabled && styles.disabled,
    )}
    onClick={onClick}
    disabled={disabled}
  >
    {icon ? <i className={icon} /> : text}
  </button>
);
