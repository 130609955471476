import { Round } from '@/common/entities';

import { mapRoundResponseToRound } from '../../mappers';
import { request } from '../../request';
import { RoundResponse } from '../../responses';

export const getOneRound = async (roundId: string): Promise<Round> => {
  const response = await request.get<{ round: RoundResponse }>(
    `/v1/rounds/${roundId}`,
  );

  return mapRoundResponseToRound(response.data.round);
};
