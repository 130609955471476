import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';

import { ScrollToTop } from '@/common/utils';

export const UserLayout = observer(() => {
  return (
    <>
      <ScrollToTop />
      <Outlet />
    </>
  );
});
