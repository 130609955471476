import { useMemo } from 'react';

import { useApi } from '@/common/api';

import { RoundsStore } from './rounds-store';
import { RoundsStoreContext } from './rounds.context';

export const RoundsStoreInjector = ({
  children,
}: {
  readonly children: React.ReactNode;
}) => {
  const api = useApi();

  const roundsStore = useMemo(() => new RoundsStore(api), [api]);

  return (
    <RoundsStoreContext.Provider value={roundsStore}>
      {children}
    </RoundsStoreContext.Provider>
  );
};
