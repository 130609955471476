import { useEffect, useState } from 'react';

import { CommonIconType } from '../common-icon';
import { usePagination } from './common-pagination.domain';
import styles from './common-pagination.module.css';
import { CommonPaginationProps } from './common-pagination.types';
import { CommonPaginationButton } from './components/common-pagination-button';

const MAX_VISIBLE_PAGES = 5;
const MOBILE_MAX_PAGES = 2;
const FACILITIES_PER_PAGE = 36;

export const CommonPagination = ({
  currentPage,
  total,
  perPage = FACILITIES_PER_PAGE,
  onChange,
}: CommonPaginationProps) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 650);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const {
    totalPages,
    previousButtonActive,
    nextButtonActive,
    centerPages,
    handlePreviousButtonClick,
    handleNextButtonClick,
    handlePaginationPageButtonClick,
  } = usePagination({ currentPage, total, perPage, onChange });

  const visiblePages = isMobile ? MOBILE_MAX_PAGES : MAX_VISIBLE_PAGES;

  if (total < perPage) {
    return null;
  }

  return (
    <div className={styles.pagination}>
      <CommonPaginationButton
        icon={CommonIconType.ChevronLeft}
        disabled={!previousButtonActive}
        onClick={handlePreviousButtonClick}
      />
      <CommonPaginationButton
        text="1"
        active={currentPage === 1}
        onClick={() => onChange(1)}
      />
      {centerPages.slice(0, visiblePages).map((page) => (
        <CommonPaginationButton
          key={page}
          text={page.toString()}
          onClick={() => handlePaginationPageButtonClick(page)}
          active={page === currentPage}
        />
      ))}
      {totalPages > visiblePages && (
        <CommonPaginationButton
          text={totalPages.toString()}
          active={currentPage === totalPages}
          onClick={() => onChange(totalPages)}
        />
      )}
      <CommonPaginationButton
        icon={CommonIconType.ChevronRight}
        disabled={!nextButtonActive}
        onClick={handleNextButtonClick}
      />
    </div>
  );
};
