import { useState } from 'react';

export const useCommonUserEditModal = () => {
  const [isCommonUserEditModalOpen, setIsCommonUserEditModalOpen] =
    useState(false);

  const handleCommonUserEditModalClose = () => {
    setIsCommonUserEditModalOpen(false);
  };

  const handleCommonUserEditModalOpen = () => {
    setIsCommonUserEditModalOpen(true);
  };

  return {
    isCommonUserEditModalOpen,
    handleCommonUserEditModalClose,
    handleCommonUserEditModalOpen,
  };
};
