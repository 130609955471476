import { Form } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { CommonButton, CommonInput } from '@/common/components';
import { paths } from '@/common/constants';
import { useReferralCode } from '@/common/hooks';

import styles from './auth-phone-form.module.css';
import { AuthPhoneFormProps, AuthPhoneFormType } from './auth-phone-form.types';

export const AuthPhoneForm = ({
  title,
  formType,
  isSubmitLoading = false,
  onSubmit,
}: AuthPhoneFormProps) => {
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const { isReferralCodeExists } = useReferralCode();

  const handleSubmit = (values: { phoneNumber: string }) => {
    const formatedPhomeNumber = `+${values.phoneNumber.replace(/\D/gu, '')}`;

    if (onSubmit) {
      onSubmit?.(formatedPhomeNumber);
    }
  };

  const handleSubmitFailed = () => {
    if (!hasSubmitted) {
      setHasSubmitted(true);
    }
  };

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit}
      onFinishFailed={handleSubmitFailed}
      className={styles.authPhoneForm}
    >
      <div className={styles.authPhoneFormContent}>
        <h2 className={styles.authPhoneFormTitle}>{title}</h2>
        <p className={styles.authPhoneFormCaption}>
          Мы отправим код подтверждения на ваш номер телефона
        </p>
        <FormItem
          name="phoneNumber"
          validateTrigger={hasSubmitted ? 'onChange' : 'onSubmit'}
          rules={[
            {
              required: true,
              message: 'Введите номер телефона',
            },
            {
              min: 18,
              message: 'Введите номер телефона полностью',
            },
          ]}
        >
          <CommonInput
            mask="+7 (000) 000-00-00"
            placeholder="+7 (000) 000-00-00"
            className={styles.authPhoneFormInput}
          />
        </FormItem>
        <CommonButton
          type="submit"
          className={styles.authPhoneFormButton}
          isLoading={isSubmitLoading}
        >
          Получить код
        </CommonButton>
        <p className={styles.authPhoneFormPolicy}>
          Продолжая, вы соглашаетесь с{' '}
          <Link to={paths.getUserAgreementPath()} className={styles.policyLink}>
            пользовательским соглашением
          </Link>
        </p>

        {formType === AuthPhoneFormType.SignUp ? (
          <p className={styles.authPhoneFormPolicy}>
            Уже есть аккаунт?{' '}
            <Link to={paths.getSignInPath()} className={styles.policyLink}>
              Войти
            </Link>
          </p>
        ) : null}

        {formType === AuthPhoneFormType.SignIn && isReferralCodeExists ? (
          <p className={styles.authPhoneFormPolicy}>
            У вас нет аккаунта?{' '}
            <Link to={paths.getSignUpPath()} className={styles.policyLink}>
              Регистрация
            </Link>
          </p>
        ) : null}
      </div>
    </Form>
  );
};
