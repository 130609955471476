import { makeAutoObservable, runInAction } from 'mobx';

import { Api } from '@/common/api';
import { Facility, PaginationMeta } from '@/common/entities';

export class FacilitiesStore {
  api: Api | null = null;

  paginationMeta: PaginationMeta | null = null;
  facilities: Facility[] = [];
  isLoading = false;
  hasError = false;

  constructor(api: Api | null) {
    makeAutoObservable(this);

    this.api = api;
  }

  changePage(page: number) {
    if (this.paginationMeta) {
      this.paginationMeta = {
        ...this.paginationMeta,
        page,
      };
    }
  }

  async loadFacilities(page = 1, showLoader = true) {
    if (!this.api) {
      return;
    }

    runInAction(() => {
      if (showLoader) {
        this.isLoading = true;
      }
      this.hasError = false;
    });

    try {
      const [facilities, paginationMeta] = await this.api.getFacilities(page);

      runInAction(() => {
        this.facilities = facilities;
        this.paginationMeta = paginationMeta;
      });
    } catch {
      runInAction(() => {
        this.hasError = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }
}
