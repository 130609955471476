import { useContext } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  CommonButton,
  CommonIcon,
  CommonIconType,
  CommonTimer,
} from '@/common/components';

import { FacilityStoreContext } from '../../stores';
import { FacilityImagesSectionProps } from './facility-image-section.types';
import styles from './facility-images-section.module.css';

export const FacilityImagesSection = ({
  facility,
}: FacilityImagesSectionProps) => {
  const facilityStore = useContext(FacilityStoreContext);

  const images = facility.photos?.filter((photo) => !photo.isSchema) || [];
  const hasVideoLink = Boolean(facility.videoLink);

  return (
    <div className={styles.container}>
      <CommonTimer
        updateFacilityStatus={() => facilityStore.loadFacility(facility.id)}
        facility={facility}
      />
      <div className={styles.detailViewContainer}>
        <Swiper
          spaceBetween={10}
          slidesPerView={1}
          loop
          navigation={{
            nextEl: `.${styles.nextButton}`,
            prevEl: `.${styles.prevButton}`,
          }}
          pagination={{
            clickable: true,
            bulletActiveClass: styles.bulletActive,
          }}
          modules={[Navigation, Pagination]}
        >
          {images.map((photo, index) => (
            <SwiperSlide key={photo.id} className={styles.imageContainer}>
              <img src={photo.photo.path} alt={`Facility Image ${index + 1}`} />
            </SwiperSlide>
          ))}
          <div className={styles.prevButton}>
            <CommonIcon
              className={styles.prevButtonIcon}
              type={CommonIconType.ChevronLeft}
            />
          </div>
          <div className={styles.nextButton}>
            <CommonIcon
              className={styles.nextButtonIcon}
              type={CommonIconType.ChevronRight}
            />
          </div>
        </Swiper>
        {hasVideoLink ? (
          <CommonButton
            type="button"
            className={styles.videoButton}
            onClick={() =>
              facility.videoLink && window.open(facility.videoLink, '_blank')
            }
          >
            <CommonIcon type={CommonIconType.Videos} />
            <span className={styles.videoButtonText}>Посмотреть видео</span>
          </CommonButton>
        ) : null}
      </div>
    </div>
  );
};
