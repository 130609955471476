import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { paths } from '@/common/constants';
import { UserStoreContext } from '@/common/stores';

import {
  AuthCodeForm,
  AuthPhoneForm,
  AuthPhoneFormType,
} from '../../components';
import styles from './sign-in-view.module.css';

export const SignInView = observer(() => {
  const navigate = useNavigate();
  const userStore = useContext(UserStoreContext);

  useEffect(() => {
    userStore.resetAuthFlags();
  }, [userStore]);

  const handleRequestCodeFormSubmit = (phoneNumber: string) => {
    userStore.requestSignInCode(phoneNumber);
  };

  const handleApplyCodeFormSubmit = async (code: string) => {
    await userStore.applySignInCode(code);

    if (userStore.accessToken && userStore.isOnboardingCompleted) {
      navigate(paths.getHomePath());
      userStore.loadMe();
    }
  };

  return (
    <div className={styles.signInView}>
      {!userStore.isSignInCodeRequested ? (
        <AuthPhoneForm
          title="Войти"
          formType={AuthPhoneFormType.SignIn}
          isSubmitLoading={userStore.isAuthPending}
          onSubmit={handleRequestCodeFormSubmit}
        />
      ) : (
        <AuthCodeForm
          requestedPhoneNumber={userStore.requestedPhoneNumber}
          isSubmitLoading={userStore.isAuthPending}
          submitButtonText="Войти"
          onSubmit={handleApplyCodeFormSubmit}
        />
      )}
    </div>
  );
});
